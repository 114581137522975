/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

// screen 10

import React, { useContext, useState } from "react";
import "./Promotion.css";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AuthContext from "../Authentication/AuthContext/AuthContext";
import CreatePromotion from "./CreatePromotion/CreatePromotion";
import ViewPromotion from "./ViewPromotion/ViewPromotion";

const Promotion = (props) => {
  const p=props;
  console.log(p.selectedCompany);
  const [createPromotion, setCreatePromotion] = useState(true);
  const [viewPromotion, setViewPromotion] = useState(false);
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="promotion-container ">
          <div className="promotion-header">
            <div
              className={`promotion-tab ${
                createPromotion ? "selected-tab selected-tab-line" : ""
              } `}
              onClick={() => {
                setCreatePromotion(true);
                setViewPromotion(false);
              }}
            >
              Create Promotion
            </div>
            <div
              className={`promotion-tab ${
                viewPromotion ? "selected-tab selected-tab-line" : ""
              }`}
              onClick={() => {
                setViewPromotion(true);
                setCreatePromotion(false);
              }}
            >
              View Promotion
            </div>
          </div>
          <hr className="line" />
          {createPromotion && !viewPromotion ? (
            <CreatePromotion selectedCompany={p.selectedCompany}/>
          ) : (
            <ViewPromotion selectedCompany={p.selectedCompany}/>
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default Promotion;
