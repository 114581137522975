/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import formValidation from "utlis/formValidation";

import { useNavigate } from "react-router-dom";

import "./SignIn.css";
import AuthContext from "../AuthContext/AuthContext";

const SignIn = () => {
  const formControl = "form-control";
  const [currentPasswordType, setCurrentPasswordType] = useState("password");
  // const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const { loginUser } = useContext(AuthContext);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    // console.log(formValues, "formValues");
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(formValidation.signUpFormValidate(formValues));
    setIsSubmit(true);
    if (Object.keys(formValues).length > 0) {
      loginUser(formValues);
    }
  };

  useEffect(() => {
    // console.log(formErrors, "formErrors");
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const navigate = useNavigate();

  const handleForgot = () => {
    navigate("/forgot-password");
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .form-group {
                width:100%;
            }`
        }}
      />
      <div className="sign-in-container boom">
        <div className="sign-in-box">
          <div className="sign-in-branding-logo">
            <div className="brand-logo">
              <img src="./img/fadoki-logo.svg" alt="" />
            </div>
          </div>
          <div className="sign-in-form">
            <div className="sign-in-content">
              <div className="sign-in-header">Sign in</div>

              <form
                className="sign-in-form-structure"
                id="signInDetails"
                noValidate
                autoComplete="off"
                autofill="off"
                onSubmit={handleSubmit}
              >
                <div className="sign-in-form-section">
                  <div className="form-group">
                    <label>User Id</label>
                    <div className="customer-input-area">
                      <input
                        type="text"
                        className={formControl}
                        name="username"
                        placeholder="Please enter your username"
                        onChange={e => handleChange(e)}
                        value={formValues.username}
                        style={{ paddingLeft: "0.75rem" }}
                        autoComplete="off"
                      />
                    </div>
                    {/* {formErrors.email && formErrors.email.length > 0 && (
                      <p className="form-validation-error">
                        {formErrors.email}
                      </p>
                    )} */}
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="customer-input-area">
                      <input
                        type={currentPasswordType}
                        className={formControl}
                        name="password"
                        placeholder="Please enter your password"
                        onChange={e => handleChange(e)}
                        value={formValues.password}
                        style={{ paddingLeft: "0.75rem" }}
                        autoComplete="new-password"
                      />

                      {currentPasswordType === "text" ? (
                        <span
                          className="sign-in-eye-position material-icons"
                          onClick={() => setCurrentPasswordType("password")}
                        >
                          visibility
                        </span>
                      ) : (
                        <span
                          className="sign-in-eye-position material-icons"
                          onClick={() => setCurrentPasswordType("text")}
                        >
                          visibility_off
                        </span>
                      )}
                    </div>
                    {formErrors.password && formErrors.password.length > 0 && (
                      <p className="form-validation-error">
                        {formErrors.password}
                      </p>
                    )}
                    <div
                      className="sign-in-forgot-password"
                      onClick={() => handleForgot()}
                    >
                      Forgot Password
                    </div>
                  </div>
                  <div className="sign-in-button">
                    <button type="submit" className="btn btn-booking-confirm">
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
              <div className="new-account">
                <h1>
                  Did not have account?{" "}
                  <span className="sign-up"> Sign up</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
