/* eslint-disable import/no-duplicates */
/* eslint-disable import/named */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Switch,
  Routes
} from "react-router-dom";

import PrivateRoutes from "./PrivateRoutes";
import SignIn from "components/customCoponents/Authentication/SignIn/SignIn";
import ForgotPassword from "components/customCoponents/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "components/customCoponents/Authentication/ResetPassword/ResetPassword";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";

const PublicRoutes = () => {
  const { authTokens } = useContext(AuthContext);
  const pathArray = [
    {
      path: "/sign-in",
      component: <SignIn />
    },
    {
      path: "/forgot-password",
      component: <ForgotPassword />
    },
    {
      path: "/reset-password",
      component: <ResetPassword />
    }
  ];

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (authTokens && authTokens.access && authTokens.access.length) {
      setIsLoggedIn(true);
    }
  }, [authTokens]);

  return (
    <>
      {!isLoggedIn ? (
        <>
          <Routes>
            {pathArray.map((_route, i) => (
              <Route exact path={_route.path} element={_route.component} />
            ))}
            <Route path="*" element={<Navigate to="/sign-in" />} />
          </Routes>
        </>
      ) : (
        <>
          <PrivateRoutes />
        </>
      )}
    </>
  );
};

export default PublicRoutes;
