/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import CustomTable from "components/CustomTable/CustomTable";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import axios from "axios";

import "./ItemDetails.css";

const ItemDetails = (props) => {
  const p=props;
  const formControl = "form-control";
  const [enteredSearchOrderId, setEnteredSearchOrderId] = useState("");
  const [tableR, setTableR] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows]=useState([]);
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  useEffect(() => {
    (async () => {
      let response='';
      if(authTokens.data.role===4){
        response=await AuthAxios.get(`/products?company=${p.selectedCompany}`)
      }
      else{
        response = await AuthAxios.get("/products");
      }
      setData(response.data);
      setRows(response.data.data);
    })();
  }, [p.selectedCompany]);

  const handleChange = searchedItem => {
    setEnteredSearchOrderId(searchedItem);
    // if (enteredSearchOrderId !== "") {
    //   const filteredData = ExistingBrandRow.filter(item => {
    //     return Object.values(item)
    //       .join("")
    //       .toLowerCase()
    //       .includes(enteredSearchOrderId.toLowerCase());
    //   });
    //   setSearchedOrderedId(filteredData);
    // } else {
    //   setSearchedOrderedId(apiData);
    // }
  };
  // Dummy data
  const ExistingBrandCol = [
    { field: "sku", header: "SKU" },
    { field: "dateOfCreation", header: "Creation Date" },
    { field: "productName", header: "Product Name" },
    { field: "brand", header: "Brand" },
    { field: "mrp", header: "MRP" },
    { field: "sellingPrice", header: "Selling Price" },
    { field: "size", header: "Size" },
    { field: "color", header: "Color" }
  ];

  const ExistingBrandRow = [
    {
      sku: "8475",
      dateOfCreation: "01:50PM",
      productName: "Sinful Matte Lipcolor",
      brand: "Nivea",
      mrp: "₹999.00",
      sellingPrice: "₹899.00",
      size: "",
      color: ""
    }
  ];

  useEffect(() => {
    console.log(rows);
    setTableR(
      rows.map(item => ({
        sku: item.sku,
        dateOfCreation: "",
        productName: item.name,
        brand: item.brand,
        mrp: item.mrp,
        sellingPrice: item.sp,
        size: item.sizes.join(","),
        color: item.shade.join(",")
      }))
    );
  }, [rows]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
            margin-bottom: 0;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="items-detail-container">
        <div className="pending-order-search-box">
          <div className="form-group">
            <div className="pending-order-search">
              <input
                type="text"
                onChange={e => handleChange(e.target.value)}
                className={formControl}
                name="orderId"
                id="orderId"
                placeholder="Search item"
                value={enteredSearchOrderId}
                required
                style={{ paddingLeft: "0.75rem", border: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="pending-order-table-section">
          <CustomTable
            data={tableR}
            columns={ExistingBrandCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default ItemDetails;
