/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

// screen 5

import React, { useContext, useState } from "react";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import "./StoreList.css";
import StoreTable from "./StoreTable/StoreTable";
import StoreCreate from "./StoreCreate/StoreCreate";

const StoreList = (props) => {
  const p=props;
  const [selectedStoreHeader, setSelectedStoreHeader] = useState(true);
  const [selectedCreateStoreHeader, setSelectedCreateStoreHeader] =
    useState(false);
  const { authTokens } = useContext(AuthContext);
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="store-container">
          <div className="store-list-header">
            <div
              className={`store-table-header ${
                selectedStoreHeader ? "selected-tab selected-tab-line" : ""
              }`}
              onClick={() => {
                setSelectedStoreHeader(true);
                setSelectedCreateStoreHeader(false);
              }}
            >
              Stores
            </div>
            <div
              className={`store-table-header ${
                selectedCreateStoreHeader ? "selected-tab selected-tab-line" : ""
              }`}
              onClick={() => {
                setSelectedCreateStoreHeader(true);
                setSelectedStoreHeader(false);
              }}
            >
              Create Store
            </div>
          </div>
          <hr className="line" />
          {selectedStoreHeader && !selectedCreateStoreHeader ? (
            <StoreTable selectedCompany={p.selectedCompany}/>
          ) : (
            <StoreCreate selectedCompany={p.selectedCompany}/>
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default StoreList;
