/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import formValidation from "utlis/formValidation";
import CustomToast from "utlis/Toast/CustomToast";
import { ToastConstant } from "utlis/Toast/toastConstant";

import "./NewBrand.css";

const NewBrand = () => {
  const initialValues = {};
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedSegments, setSelectedSegments]=useState(new Set());
  // toast state
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  const formControl = "form-control";

  const [selectedStore, setSelectedStore] = useState("");

  const options = [
    { value: "beauty", label: "Beauty" },
    { value: "personalCare", label: "Personal Care" },
    { value: "footwear", label: "Footwear" },
    { value: "accessories", label: "Accessories" },
    { value: "apparel", label: "Apparel" }
  ];

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/fadoki";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  const handleSelect = e => {
    setSelectedSegments(e)
    // setFormValues({
    //   ...formValues,
    //   categories: x
    // });
    console.log(selectedSegments, "Selected Segments");
  };
  const handleSubmit = e => {
    e.preventDefault();
    // setFormErrors(formValidation.registerNewBrand(formValues));
    // setIsSubmit(true);
    console.log(selectedSegments);
    let seg='';
    selectedSegments.map((item)=>{
      const y= item.value.concat(', ')
      seg=seg.concat(y);
      console.log(item.value);
      return true;
    })
    seg.slice(0, -2);
    console.log(seg);
    // API HIT
    AuthAxios.post("/company", {
      registered_name: formValues.registeredName,
      name: formValues.brandName,
      gstin: formValues.gstin,
      segment: seg,
      cin: formValues.cin,
      address: formValues.companyAddress,
      poc: formValues.pocName,
      contact_number: formValues.pocNumber,
      poc_email: formValues.pocEmail,
      pocDesignation: formValues.pocDesignation,
      account_number: formValues.accountNumber,
      account_holder_name: formValues.accountHolderName,
      ifsc: formValues.ifscCode,
      bank_name: formValues.brandName
    })
      .then(response => {
        if (response.status === 200) {
          setOpen(true);
          setOpen(true);
          setToastData(
            ToastConstant.DYNAMIC_SUCCESS(
              response.data.message,
              3000,
              "success"
            )
          );
        }
      })
      .catch(error => {
        setOpen(true);
        setOpen(true);
        setToastData(ToastConstant.DYNAMIC_FAIL(error.response.data.message, 3000, "error"));
      });
  };

  const handleReset = () => {
    document.getElementById('newBrandRegistrationDetails').reset();
    setFormValues(initialValues);
    setFormErrors({});
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    console.log(formValues, "formValues");
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .form-group {
                width:30%;
                margin-bottom: 0;
            }
            .css-1s2u09g-control, .css-1pahdxg-control {
              min-height: 0;
              max-height: 40px;
            }
            .css-319lph-ValueContainer {
              padding: 0 8px;
            }
            .css-6j8wv5-Input {
              margin: 0;
            }`
        }}
      />
      <div className="new-brand-container">
        <form
          className="new-brand-registration-form"
          id="newBrandRegistrationDetails"
          noValidate
          autoComplete="off"
          autofill="off"
          onSubmit={handleSubmit}
        >
          <div className="new-brand-header">Brand Details</div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>Registered Name</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="registeredName"
                  placeholder="Enter registered name"
                  onChange={e => handleChange(e)}
                  value={formValues.registeredName}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.registeredName &&
                  formErrors.registeredName.length > 0 && (
                    <p className="brand-registration-form-validation-error">
                      {formErrors.registeredName}
                    </p>
                  )}
              </div>
            </div>
            <div className="form-group">
              <label>Company Name</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="brandName"
                  placeholder="Enter brand name"
                  onChange={e => handleChange(e)}
                  value={formValues.brandName}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.brandName && formErrors.brandName.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.brandName}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>GSTIN</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="gstin"
                  placeholder="Enter GSTIN number"
                  onChange={e => handleChange(e)}
                  value={formValues.gstin}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.gstin && formErrors.gstin.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.gstin}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Segment</label>
              {/* <Select options={options} /> */}
              <CustomMultiSelect
                multi='true'
                options={options}
                value={selectedSegments}
                handleChange={handleSelect}
              />
              {/* <div className="select-store-dropdown">
              </div> */}
            </div>
          </div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>CIN</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="cin"
                  placeholder="Enter CIN"
                  onChange={e => handleChange(e)}
                  value={formValues.cin}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.cin && formErrors.cin.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.cin}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Company Address</label>
              <div className="company-address-map">
                <input
                  type="text"
                  className={formControl}
                  name="companyAddress"
                  placeholder="Enter company address"
                  onChange={e => handleChange(e)}
                  value={formValues.companyAddress}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />{" "}
                <span className="open-map">Open Map</span>
              </div>

              {formErrors.companyAddress &&
                formErrors.companyAddress.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.companyAddress}
                  </p>
                )}
            </div>
          </div>

          <div className="new-brand-header">POC Details</div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>POC Name</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="pocName"
                  placeholder="Enter POC name"
                  onChange={e => handleChange(e)}
                  value={formValues.pocName}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.pocName && formErrors.pocName.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.pocName}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>POC Number</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="pocNumber"
                  placeholder="Enter POC number"
                  onChange={e => handleChange(e)}
                  value={formValues.pocNumber}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.pocNumber && formErrors.pocNumber.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.pocNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>POC Email</label>
              <div className="brand-input-area">
                <input
                  type="email"
                  className={formControl}
                  name="pocEmail"
                  placeholder="Enter POC email-id"
                  onChange={e => handleChange(e)}
                  value={formValues.pocEmail}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.pocEmail && formErrors.pocEmail.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.pocEmail}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>POC Designation</label>
              <div className="brand-input-area">
                <input
                  type="email"
                  className={formControl}
                  name="pocDesignation"
                  placeholder="Enter POC designation"
                  onChange={e => handleChange(e)}
                  value={formValues.pocDesignation}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.pocDesignation &&
                  formErrors.pocDesignation.length > 0 && (
                    <p className="brand-registration-form-validation-error">
                      {formErrors.pocDesignation}
                    </p>
                  )}
              </div>
            </div>
          </div>

          <div className="new-brand-header">Account Details</div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>Account Number</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="accountNumber"
                  placeholder="Enter account number"
                  onChange={e => handleChange(e)}
                  value={formValues.accountNumber}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.accountNumber &&
                  formErrors.accountNumber.length > 0 && (
                    <p className="brand-registration-form-validation-error">
                      {formErrors.accountNumber}
                    </p>
                  )}
              </div>
            </div>
            <div className="form-group">
              <label>Confirm Account Number</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="confirmAccountNumber"
                  placeholder="Re-enter account number"
                  onChange={e => handleChange(e)}
                  value={formValues.confirmAccountNumber}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.confirmAccountNumber &&
                  formErrors.confirmAccountNumber.length > 0 && (
                    <p className="brand-registration-form-validation-error">
                      {formErrors.confirmAccountNumber}
                    </p>
                  )}
              </div>
            </div>
          </div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>Account Holder Name</label>
              <div className="brand-input-area">
                <input
                  type="email"
                  className={formControl}
                  name="accountHolderName"
                  placeholder="Enter account holder name"
                  onChange={e => handleChange(e)}
                  value={formValues.accountHolderName}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.accountHolderName &&
                  formErrors.accountHolderName.length > 0 && (
                    <p className="brand-registration-form-validation-error">
                      {formErrors.accountHolderName}
                    </p>
                  )}
              </div>
            </div>
            <div className="form-group">
              <label>IFSC Code</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="ifscCode"
                  placeholder="Enter IFSC code"
                  onChange={e => handleChange(e)}
                  value={formValues.ifscCode}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.ifscCode && formErrors.ifscCode.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.ifscCode}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="brand-registration-section">
            <div className="form-group">
              <label>Bank Name</label>
              <div className="brand-input-area">
                <input
                  type="email"
                  className={formControl}
                  name="bankName"
                  placeholder="Enter bank name"
                  onChange={e => handleChange(e)}
                  value={formValues.bankName}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.bankName && formErrors.bankName.length > 0 && (
                  <p className="brand-registration-form-validation-error">
                    {formErrors.bankName}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="new-brand-registration-button">
            <button type="submit" className="btn btn-register-brand">
              Register
            </button>
            <button
              type="button"
              className="btn btn-reset"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default NewBrand;
