/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useState } from "react";
import AuthContext from "../Authentication/AuthContext/AuthContext";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SalesReport from "./SalesReport/SalesReport";
import ReturnReports from "./ReturnReports/ReturnReports";

import "./Reports.css";

const Reports = () => {
  const [salesReport, setSalesReport] = useState(true);
  const [returnReport, setReturnReports] = useState(false);
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="reports-container">
          <div className="reports-header">
            <div
              className={`reports-tab ${
                salesReport ? "selected-tab selected-tab-line" : ""
              } `}
              onClick={() => {
                setSalesReport(true);
                setReturnReports(false);
              }}
            >
              Sales Reports
            </div>
            <div
              className={`reports-tab ${
                returnReport ? "selected-tab selected-tab-line" : ""
              }`}
              onClick={() => {
                setReturnReports(true);
                setSalesReport(false);
              }}
            >
              Return
            </div>
          </div>
          <hr className="line" />
          {salesReport && !returnReport ? <SalesReport /> : <ReturnReports />}
        </div>
      </DashboardLayout>
    </>
  );
};

export default Reports;
