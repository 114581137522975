/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";

import "./DeliveredOrder.css";

const DeliveredOrder = (props) => { 
  const p=props;
  const formControl = "form-control";
  const [enteredSearchOrderId, setEnteredSearchOrderId] = useState("");
  const [apiData, setApiData] = useState("");
  const [searchedOrderId, setSearchedOrderedId] = useState([]);
  const [deliveredOrderData, setDeliveredOrderData] = useState([]);
  const [deliveredOrderRow, setDeliveredOrderRow] = useState([]);
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  useEffect(() => {
    (async () => {
      const response = await AuthAxios.get("/orders?type=DELIVERED");
      if (response.status === 200) {
        setDeliveredOrderData(response.data.orders);
      }
    })();
  }, []);

  useEffect(()=>{
    setDeliveredOrderRow(
      deliveredOrderData.map((item)=>({
        orderId: item.order_id,
        status: item.status,
        trackingURL: item.trackingURL,
        assignedLogistics: item.assignedLogistics,
        pickupTime: item.pickupTime,
        expectedDelivery: item.expectedDelivery,
        actualDeliveryTime: item.actualDeliveryTime,
        delay: item.delay
      }))
    )
  }, [deliveredOrderData]);

  const ExistingBrandCol = [
    { field: "orderId", header: "Order Id" },
    { field: "status", header: "Status" },
    { field: "trackingURL", header: "Tracking URL" },
    { field: "assignedLogistics", header: "Assigned Logistics" },
    { field: "pickupTime", header: "Pickup Time" },
    { field: "expectedDelivery", header: "Expected Delivery" },
    { field: "actualDeliveryTime", header: "Actual Delivery Time" },
    { field: "delay", header: "Delay" }
  ];

  // const ExistingBrandRow = [
  //   {
  //     orderId: "123456",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123457",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123458",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123459",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123460",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123461",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123462",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123463",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123464",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123465",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123466",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123467",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123468",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   },
  //   {
  //     orderId: "123469",
  //     status: "Picked Up",
  //     trackingURL: "bitly.url/34756396",
  //     assignedLogistics: "Dunzo",
  //     pickupTime: "01:50PM 9 Sep 2022",
  //     expectedDelivery: "01:50PM 9 Sep 2022",
  //     actualDeliveryTime: "01:50PM 9 Sep 2022",
  //     delay: "+2 Minute"
  //   }
  // ];

  useEffect(() => {
    setApiData(deliveredOrderRow);
  }, []);

  const handleChange = searchedItem => {
    setEnteredSearchOrderId(searchedItem);
    if (enteredSearchOrderId !== "") {
      const filteredData = deliveredOrderRow.filter(item => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(enteredSearchOrderId.toLowerCase());
      });
      setSearchedOrderedId(filteredData);
    } else {
      setSearchedOrderedId(apiData);
    }
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
            margin-bottom: 0;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="pending-order-container">
        <div className="pending-order-header">
          <div className="pending-order-search-box">
            <div className="form-group">
              <div className="pending-order-search">
                <input
                  type="text"
                  onChange={e => handleChange(e.target.value)}
                  className={formControl}
                  name="orderId"
                  id="orderId"
                  placeholder="Search order id"
                  value={enteredSearchOrderId}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="pending-order-filter">
            <div className="pending-order-header-filter">
              <div className="pending-order-filters-list">
                <div className="pending-order-filter-tab">
                  <span className="pending-order-individual-filter">
                    Sort By &#9660;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pending-order-table-section">
          <CustomTable
            data={
              Object.keys(searchedOrderId).length > 0
                ? searchedOrderId
                : deliveredOrderRow
            }
            columns={ExistingBrandCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default DeliveredOrder;
