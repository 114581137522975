/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomSelectValue from "components/CustomSelect/CustomSelectValue";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import React, { useContext, useEffect, useState } from "react";
import formValidation from "utlis/formValidation";
import CustomToast from "utlis/Toast/CustomToast";
import { ToastConstant } from "utlis/Toast/toastConstant";
import Icon from "@mui/material/Icon";

import "../SingleStaff/SingleStaffCreation.css";

const EditStaff = ({ setShowEditScreen, induvidualUserData, selectedCompany }) => {
  const initialValues = {};
  const formControl = "form-control";
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectedRoleType, setSelectedRoleType] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [selectedStores, setSelectedStores]=useState(new Set());
  const [selectedStore, setSelectedStore] = useState("");
  const [options, setOptions]=useState([]);
  const [selectedStoreType, setSelectedStoreType]=useState('');
  const [storeCode, setStoreCode] = useState("");

  // toast state
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  const roleOptions = authTokens.data.role===4 ? [
    { name: "Staff", id: 2 },
    { name: "Merchant", id: 3 },
    {name: "Fadoki", id:4}
  ]
  :[
    { name: "Staff", id: 2 },
    { name: "Merchant", id: 3 },
  ];

  console.log(induvidualUserData, "induvidualUserData");
  // console.log(selectedRoleType, "selectedRoleType");

  //  Store List api
  useEffect(() => {
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response=await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${selectedCompany}/store`)
      }
      else{
        response = await AuthAxios.get(`/stores`);
      }
      if (response.status === 200) {
        setStoreList(response.data.data);
        // setStockOverviewLoader(false);
      }
    })();
  }, [selectedCompany]);

  useEffect(()=>{
    console.log(storeList);
    const arr=[];
    storeList.forEach((item)=>{
      arr.push({name: item.name, value: item.code})
    });
    console.log(arr);
    setOptions(
      arr.map((item)=>({
        label:item.name,
        value:item.value,
        name: item.name
      }))
    )
    console.log(options);
  }, [storeList])
  // console.log(storeCode.code, "This will go in the api body");
  const handleSelect = e => {
    setSelectedStores(e)
    console.log(selectedStores, "Selected Stores");
  };

  // selected Store information
  // useEffect(() => {
  //   if (
  //     storeList &&
  //     storeList.length &&
  //     selectedStore &&
  //     selectedStore.length
  //   ) {
  //     setStoreCode(storeList.find(item => item.name === selectedStore));
  //   }
  // }, [storeList, selectedStore]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    // console.log(formValues, "formValues");
  };

  const handleSubmit = e => {
    e.preventDefault();
    // setFormErrors(formValidation.createStaffAccount(formValues));
    const arr=[]
    console.log(selectedStores);
    console.log(selectedStoreType);
    if(selectedRoleType===3){
      selectedStores.forEach((item)=>{
          arr.push(item.value);
        }
      )
    }else if(selectedRoleType===2){
      console.log(selectedStoreType);
      arr.push((options.find( item => item.name===selectedStoreType)).value);
    }
    console.log(formValues, "sdasdas");
    console.log(arr);
    // API HIT
    AuthAxios.put(`/user/${induvidualUserData.contantNumber}`, {
      name: formValues.fullName,
      email: formValues.emailId,
      password: formValues.password,
      mobile: formValues.contactNumber,
      gender: "M",
      country_code: "+91",
      role: selectedRoleType,
      store_list: [storeCode.code],
      company_id: selectedCompany,
      is_active: true
    })
      .then(response => {
        if (response.status === 200) {
          setOpen(true);
          setOpen(true);
          setToastData(
            ToastConstant.DYNAMIC_SUCCESS(
              response.data.message,
              3000,
              "success"
            )
          );
          setTimeout(() => {
            setShowEditScreen(prevState => !prevState);
          }, 3000);
        }
      })
      .catch(error => {
        setOpen(true);
        setOpen(true);
        setToastData(ToastConstant.DYNAMIC_FAIL(error.response.data.message, 3000, "error"));
      });
  };
  const handleDeactivate = () => {
    AuthAxios.put(`/user/${induvidualUserData.contantNumber}`, {
      name: formValues.fullName,
      email: formValues.emailId,
      password: formValues.password,
      mobile: formValues.contactNumber,
      gender: "M",
      country_code: "+91",
      role: selectedRoleType,
      store_list: [storeCode.code],
      company_id: selectedCompany,
      is_active: false
    })
      .then(response => {
        if (response.status === 200) {
          setOpen(true);
          setOpen(true);
          setToastData(
            ToastConstant.DYNAMIC_SUCCESS(
              response.data.message,
              3000,
              "success"
            )
          );
          setTimeout(() => {
            setShowEditScreen(prevState => !prevState);
          }, 3000);
        }
      })
      .catch(error => {
        setOpen(true);
        setOpen(true);
        setToastData(ToastConstant.DYNAMIC_FAIL(error.response.data.message, 3000, "error"));
      });
  };

  useEffect(() => {
    if (Object.keys(induvidualUserData).length > 0) {
      formValues.fullName = induvidualUserData?.staffName;
      formValues.emailId = induvidualUserData?.emailId;
      formValues.contactNumber = induvidualUserData?.contantNumber;
      setSelectedRoleType(induvidualUserData?.role)
      setSelectedStoreType(induvidualUserData?.store);
    }
  }, [induvidualUserData]);

  useEffect(()=>{
    console.log(selectedRoleType);
  },[selectedRoleType]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .form-group {
                width:30%;
                margin-bottom: 0;
            }
            .css-1s2u09g-control, .css-1pahdxg-control {
              min-height: 0;
              max-height: 40px;
            }
            .css-319lph-ValueContainer {
              padding: 0 8px;
            }
            .css-6j8wv5-Input {
              margin: 0;
            }
            .css-b62m3t-container{
            width: 100%;
          }
           `
        }}
      />
      <div className="staff-credential-container">
        <div
          className="details-table-header"
          onClick={() => setShowEditScreen(prevState => !prevState)}
        >
          <div className="back-button">
            <Icon fontSize="small" color="inherit">
              arrow_back_ios
            </Icon>
          </div>
          Edit Staff
        </div>
        <hr className="line" />
        <div className="single-staff-creation-header">Personal Information</div>
        <div className="single-staff-creation-sub-heading">
          Fill all basic details
        </div>
        <form
          className="staff-creation-form-structure"
          id="staffCreation"
          noValidate
          autoComplete="off"
          autofill="off"
          onSubmit={handleSubmit}
        >
          <div className="single-staff-registration-section">
            <div className="form-group">
              <label>Full Name</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="fullName"
                  placeholder="Enter full name"
                  onChange={e => handleChange(e)}
                  value={formValues.fullName}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.fullName && formErrors.fullName.length > 0 && (
                  <p className="single-staff-registration-form-validation-error">
                    {formErrors.fullName}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Email Id</label>
              <div className="brand-input-area">
                <input
                  type="email"
                  className={formControl}
                  name="emailId"
                  placeholder="Enter email id"
                  onChange={e => handleChange(e)}
                  value={formValues.emailId}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.emailId && formErrors.emailId.length > 0 && (
                  <p className="create-store-registration-form-validation-error">
                    {formErrors.emailId}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="single-staff-registration-section">
            <div className="form-group">
              <label>Contact Number</label>
              <div className="brand-input-area">
                <input
                  type="text"
                  className={formControl}
                  name="contactNumber"
                  placeholder="Enter full name"
                  onChange={e => handleChange(e)}
                  value={formValues.contactNumber}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.contactNumber &&
                  formErrors.contactNumber.length > 0 && (
                    <p className="single-staff-registration-form-validation-error">
                      {formErrors.contactNumber}
                    </p>
                  )}
              </div>
            </div>
            <div className="form-group">
              <label>Password</label>
              <div className="brand-input-area">
                <input
                  type="password"
                  className={formControl}
                  name="password"
                  placeholder="Enter password"
                  onChange={e => handleChange(e)}
                  value={formValues.password}
                  style={{ paddingLeft: "0.75rem" }}
                  autoComplete="off"
                />
                {formErrors.password && formErrors.password.length > 0 && (
                  <p className="create-store-registration-form-validation-error">
                    {formErrors.password}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="single-staff-registration-section">
            <div className="form-group">
              <label>Staff Role</label>
              <CustomSelectValue
                list={roleOptions}
                selected={roleOptions.find(
                  item => item.name === selectedRoleType
                )}
                update={newValue => setSelectedRoleType(newValue)}
              />
            </div>
          </div>
          {selectedRoleType===3 ? (
          <div className="single-staff-location-access">
            <div className="single-staff-location-access-header">
              Location access
            </div>
            <div className="single-staff-location-access-sub-header">
              Select the stores that this user can access
            </div>
            <div className="select-store-dropdown">
              <CustomMultiSelect
                multi='true'
                options={options}
                value={selectedStores}
                handleChange={handleSelect}
              />
            </div>
          </div>
        ):(<></>)}
        {selectedRoleType===2 ? (
          <div className="single-staff-location-access">
            <div className="single-staff-location-access-header">
              Location access
            </div>
            <div className="single-staff-location-access-sub-header">
              Select the stores that this user can access
            </div>
            <div className="select-store-dropdown">
              <CustomSelect
                list={options}
                selected={options.find(
                  item => item.name === selectedStoreType
                )}
                update={newValue => setSelectedStoreType(newValue)}
              />
            </div>
          </div>
        ):(<></>)}
          <div className="create-store-buttons">
            <button type="submit" className="btn btn-create-store">
              Save
            </button>
            <button
              type="button"
              className="btn btn-create-store-reset"
              onClick={() => handleDeactivate()}
            >
              De-Activate
            </button>
          </div>
        </form>
      </div>

      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default EditStaff;
