/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react";
import Icon from "@mui/material/Icon";
import dayjs from "dayjs";

import "./CustomerDetails.css";
import { useNavigate } from "react-router-dom";

const CustomerDetails = ({ userData }) => {
  const navigate=useNavigate();
  const formControl = "form-control";
  return (
    <>
      {userData &&
        userData.customer &&
        userData.customer.length > 0 &&
        userData.customer.map(customer => (
          <div className="customer-details-container">
            <div className="details-table-header">
            <div className="back-button"
            onClick={()=> navigate(`/dashboard`)}>
              <Icon fontSize="small" color="inherit">
                arrow_back_ios
              </Icon>
            </div>
              Customer Details
            </div>
            <hr className="line" />
            <form
              className="customer-details-form"
              id="customerDetails"
              noValidate
              autoComplete="off"
              autofill="off"
            >
              <div className="form-information-section">
                <div className="form-group">
                  <label>Mobile Number</label>
                  <div className="customer-input-area">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="number"
                      id="number"
                      value={customer.mobile}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Customer Name</label>
                  <div className="customer-input-area">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="name"
                      id="name"
                      value={customer.name}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-information-section">
                <div className="form-group">
                  <label>Email ID</label>
                  <div className="customer-input-area">
                    <input
                      type="email"
                      // onChange={handleChange}
                      className={formControl}
                      name="email"
                      id="email"
                      value={customer.email}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Gender</label>
                  <div className="customer-input-area">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="gender"
                      id="gender"
                      value={customer.gender}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-information-section">
                <div className="form-group">
                  <label>DOB</label>
                  <div className="customer-input-area">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="dob"
                      id="dob"
                      value={dayjs(customer.dob).format("DD/MM/YYYY")}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Number of Purchases</label>
                  <div className="customer-input-area">
                    <input
                      type="number"
                      // onChange={handleChange}
                      className={formControl}
                      name="purchase-qty"
                      id="purchase-qty"
                      value={userData.orders.length}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
              </div>

              <div className="form-information-section">
                <div className="form-group">
                  <label>Lifetime purchase value</label>
                  <div className="customer-input-area">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="total-purchase-value"
                      id="total-purchase-value"
                      value={userData.total_order_amount}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Registration Date</label>
                  <div className="customer-input-area">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="registration-date"
                      id="registration-date"
                      value={dayjs(customer.date_joined).format("DD/MM/YYYY")}
                      readOnly
                      style={{ paddingLeft: "0.75rem" }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        ))}
    </>
  );
};

export default CustomerDetails;
