/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useRef, useContext, useEffect, useState } from "react";
import CustomSelect from "components/CustomSelect/CustomSelect";
import formValidation from "utlis/formValidation";

import "./UpdateInventory.css";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import axios from "axios";
import { ToastConstant } from "utlis/Toast/toastConstant";
import CustomToast from "utlis/Toast/CustomToast";

const UpdateInventory = (props) => {
  const p=props;
  const formControl = "form-control";
  const initialValues = {};
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues]=useState([]);
  const [uploadedFile, setuploadedFile] = useState();
  const hiddenFileInput = useRef(null);
  // Select DropDown states
  const [selectedStore, setSelectedStore] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [storeCode, setStoreCode] = useState("");

  const [searchSKU, setSearchSKU] = useState("");
  const [searchSKUData, setSearchSKUData] = useState("");
  const [productData, setProductData]=useState("");
  const [showPromotion, setShowPromotion] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);

  // Toast
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  // helper function
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  useEffect(()=>{
    if (uploadedFile) {
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("companyId", p.selectedCompany)
      AuthAxios.post('https://apis.fadoki.com/merchant/bulkupload/inventory', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
        setOpen(true);
        setToastData(
          ToastConstant.DYNAMIC_SUCCESS(
            response.data.message,
            3000,
            "success"
          )
        );
      })
      .catch(error => {
        setOpen(true);
        setToastData(ToastConstant.DYNAMIC_FAIL("Fields empty", 3000, "error"));
      })
    }
  }, [uploadedFile])
  //  Store List api
  useEffect(() => {
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response=await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${p.selectedCompany}/store`)
      }
      else{
        response = await AuthAxios.get("/stores");
      }
      if (response.status === 200) {
        setStoreList(response.data.data);
        // setStockOverviewLoader(false);
      }
    })();
  }, [p.selectedCompany]);

  useEffect(() => {
    if (
      storeList &&
      storeList.length &&
      selectedStore &&
      selectedStore.length
    ) {
      setStoreCode(storeList.find(item => item.name === selectedStore));
    }
  }, [storeList, selectedStore]);

  const handleSubmit = e => {
    e.preventDefault();
    console.log(formValues);
    setFormErrors(formValidation.updateInventory(formValues));
    // AuthAxios.post
  };

  const handleChange = e => {
    const { name, value } = e.target;
    console.log(e.target.name, e.target.value);
    formValues.push({name, value});
    // formValues
    // setProductData(...productData, {name: value});
    // setFormValues({
    //   ...formValues,
    //   [name]: value
    // });
    console.log(formValues, "formValues");
  };

  const handlePromotionToggle = () => {
    setShowPromotion(prevState => !prevState);
    console.log(showPromotion);
  };

  const handleCouponToggle = () => {
    setShowCoupon(prevState => !prevState);
    console.log(showCoupon);
  };

  const handleReset = () => {
    // setFormValues(initialValues);
    setFormErrors({});
  };

  const handleSearch = () => {
    if (searchSKU && searchSKU.length > 0) {
      (async () => {
        const response = await AuthAxios.get(
          `/${storeCode.code}/inventory?q=${searchSKU}&company=${p.selectedCompany}`
        );
        if (response.status === 200) {
          console.log(response);
          setSearchSKUData(response.data);
          setProductData(response.data.data);
        }
      })();
    }
  };
  // useEffect(()=>{
  //   setFormValues(()=>{
  //     const x={}; 
  //     if (Object.keys(prod).length > 0) {
  //       console.log("Hi");
  //       x.storeCode = p.storeEditData?.storeCode;
  //       x.storeName = p.storeEditData?.storeName;
  //       x.phoneNumber = p.storeEditData?.contactNumber;
  //       x.altPhoneNumber = p.storeEditData?.altNumber;
  //       x.pocName = p.storeEditData?.pocName;
  //       x.storeArea = p.storeEditData?.storeInfo?.area;
  //       x.storeCity = p.storeEditData?.storeInfo?.city;
  //       x.storeState = p.storeEditData?.storeInfo?.state;
  //       x.storePostal = p.storeEditData?.storeInfo?.pincode;
  //       x.returnStoreArea = p.storeEditData?.storeInfo?.return_area;
  //       x.returnStoreCity = p.storeEditData?.storeInfo?.return_city;
  //       x.returnStoreState = p.storeEditData?.storeInfo?.return_state;
  //       x.returnStorePostal = p.storeEditData?.storeInfo?.return_pincode;
  //       x.gstin = p.storeEditData?.storeInfo?.gst;
  //       x.storeInformation = p.storeEditData?.storeInfo?.description;
  //       x.company=p.storeEditData?.storeInfo?.company
  //     }
  //     return x;
  //   })
  // }, [productData])
  console.log(productData);
  console.log(searchSKUData, "searchSKUData");

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .css-b62m3t-container {
              width:100%;
            }
            .form-group {
              width: 100%;
            }
            table {
             width: 100%
            }
            .line {
              width: 100%
            }`
        }}
      />
      <div className="update-inventory-container">
        <div className="update-inventory-header">
          Select store to update inventory
        </div>
        <div className="update-inventory-store-selector">
          <CustomSelect
            list={storeList}
            selected={storeList.find(item => item.name === selectedStore)}
            update={newValue => setSelectedStore(newValue)}
          />
          {selectedStore && selectedStore.length > 0 && (
            <div className="update-inventory-search-box">
              <div className="form-group">
                <div className="update-inventory-search">
                  <img
                    src="./img/searchIcon.svg"
                    alt="search"
                    onClick={() => handleSearch()}
                  />
                  <input
                    type="text"
                    onChange={e => setSearchSKU(e.target.value)}
                    className={formControl}
                    name="sku"
                    id="sku"
                    placeholder="Search SKU"
                    value={searchSKU}
                    required
                    style={{ paddingLeft: "0.75rem", border: "none" }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        
        <div className="product-selection">
            <h1>Bulk Product Selection</h1>
          </div>

          <div className="bulk-file-upload-section">
            <div className="uplaod-img">
              <img src="/img/fileUpload.svg" alt="upload" />
            </div>
            <div className="upload-actions">
              <div className="upload-header">
                <h1>Drag and drop file here or</h1>
                <p>Accepted file formats: Excel, CSV</p>
              </div>
              <div className="upload-buttons">
                 <button type="button" className="btn btn-browse-file" onClick={handleClick}>
                  Browse Files
                </button>
                <input
              type="file"
              id="fileUpload"
              ref={hiddenFileInput}
              onChange={(e)=>{setuploadedFile(e.target.files[0])}}
              style={{ display: "none" }}
              accept=".xlsx,.xls,.csv"
            />
                
                <button type="button" className="btn btn-download-file">
                  Download Sample
                </button>
              </div>
            </div>
          </div>
        {productData!=='' && productData.length > 0 && (
          <>
            {productData.map(data => {
              setFormValues({
                image: data.product.image,
                name: data.product.name,
                sp:data.product.sp,
                mrp:data.product.mrp,
                stock:data.quantity
              });
              return (
              <div className="update-inventory-search-results">
                <div className="update-inventory-search-results-header">
                  Search result for SKU: {searchSKU}
                </div>
                <hr className="line" />

                <form
                  className="update-inventory-form"
                  id="updateInventoryDetails"
                  noValidate
                  autoComplete="off"
                  autofill="off"
                  onSubmit={handleSubmit}
                >
                  <div className="update-inventory-search-results-image-url">
                    <div className="update-inventory-search-result-image">
                      <img src={formValues.image} alt="product" />
                    </div>
                    <div className="update-inventory-search-result-image-url">
                      Image URL(Max size of image 1MB)
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="image"
                          placeholder="Enter product image URL"
                          onChange={e => handleChange(e)}
                          // value={
                          //   formValues.inventoryURL ||
                          //   "https://cdn.shopify.com/s/files/1/0602/3158/1920/products/mml001_1_984x.progress"
                          // }
                          value={formValues.image}
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                        />
                        {formErrors.inventoryURL &&
                          formErrors.inventoryURL.length > 0 && (
                            <p className="update-inventory-form-validation-error">
                              {formErrors.inventoryURL}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="update-inventory-form-section">
                    <div className="form-group">
                      <label>SKU</label>
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="sku"
                          placeholder="Enter SKU"
                          // onChange={e => handleChange(e)}
                          value={formValues.sku}
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                          readOnly
                        />
                        {/* {formErrors.sku && formErrors.sku.length > 0 && (
                          <p className="update-inventory-form-validation-error">
                            {formErrors.sku}
                          </p>
                        )} */}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Date of Creation</label>
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="createdOn"
                          placeholder="Enter date of creation"
                          // onChange={e => handleChange(e)}
                          value="10:45AM - 09 Sep 2022"
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                          readOnly
                        />
                        {/* {formErrors.createdOn &&
                          formErrors.createdOn.length > 0 && (
                            <p className="update-inventory-form-validation-error">
                              {formErrors.createdOn}
                            </p>
                          )} */}
                      </div>
                    </div>
                  </div>

                  <div className="update-inventory-form-section">
                    <div className="form-group">
                      <label>Product Name</label>
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="productName"
                          placeholder="Enter product name"
                          onChange={e => handleChange(e)}
                          // value={formValues.productName}
                          value={formValues.name}
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                        />
                        {formErrors.productName &&
                          formErrors.productName.length > 0 && (
                            <p className="update-inventory-form-validation-error">
                              {formErrors.productName}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>MRP</label>
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="mrp"
                          placeholder="Enter mrp"
                          onChange={e => handleChange(e)}
                          // value={formValues.mrp}
                          value={data.product.mrp}
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                        />
                        {formErrors.mrp && formErrors.mrp.length > 0 && (
                          <p className="update-inventory-form-validation-error">
                            {formErrors.mrp}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="update-inventory-form-section">
                    <div className="form-group">
                      <label>Selling Price</label>
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="sellingPrice"
                          placeholder="Enter selling price"
                          onChange={e => handleChange(e)}
                          // value={formValues.sellingPrice}
                          value={data.product.sp}
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                        />
                        {formErrors.sellingPrice &&
                          formErrors.sellingPrice.length > 0 && (
                            <p className="update-inventory-form-validation-error">
                              {formErrors.sellingPrice}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Current Stock</label>
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="currentStock"
                          placeholder="Number of stocks available"
                          onChange={e => handleChange(e)}
                          // value={formValues.currentStock}
                          value={data.quantity}
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                        />
                        {formErrors.currentStock &&
                          formErrors.currentStock.length > 0 && (
                            <p className="update-inventory-form-validation-error">
                              {formErrors.currentStock}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="update-inventory-promotion-code">
                    <div className="update-inventory-promotion-code-header">
                      Promotion
                      {showPromotion ? (
                        <img
                          src="./img/toggleOn.svg"
                          alt="TOGGLE_ON"
                          onClick={() => handlePromotionToggle()}
                        />
                      ) : (
                        <img
                          src="./img/toggleOff.svg"
                          alt="TOGGLE_OFF"
                          onClick={() => handlePromotionToggle()}
                        />
                      )}
                      {showPromotion ? "Yes" : "No"}
                    </div>
                    {showPromotion && (
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="promotion"
                          onChange={e => handleChange(e)}
                          value={
                            formValues.promotion ||
                            "Flat 20% off | Valid till 15 Sep’22"
                          }
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                          readOnly
                        />
                        {formErrors.promotion &&
                          formErrors.promotion.length > 0 && (
                            <p className="update-inventory-form-validation-error">
                              {formErrors.promotion}
                            </p>
                          )}
                      </div>
                    )}
                  </div>

                  <div className="update-inventory-coupon-code">
                    <div className="update-inventory-coupon-code-header">
                      Coupon
                      {showCoupon ? (
                        <img
                          src="./img/toggleOn.svg"
                          alt="TOGGLE_ON"
                          onClick={() => handleCouponToggle()}
                        />
                      ) : (
                        <img
                          src="./img/toggleOff.svg"
                          alt="TOGGLE_OFF"
                          onClick={() => handleCouponToggle()}
                        />
                      )}
                      {showCoupon ? "Yes" : "No"}
                    </div>
                    {showCoupon && (
                      <div className="update-inventory-input-area">
                        <input
                          type="text"
                          className={formControl}
                          name="coupon"
                          onChange={e => handleChange(e)}
                          value={
                            formValues.coupon ||
                            "SALE2022 | Valid till 15 Sep’22"
                          }
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                          readOnly
                        />
                        {formErrors.coupon && formErrors.coupon.length > 0 && (
                          <p className="update-inventory-form-validation-error">
                            {formErrors.coupon}
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="new-brand-registration-button">
                    <button type="submit" className="btn btn-register-brand">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-reset"
                      onClick={handleReset}
                    >
                      De-Activate
                    </button>
                  </div>
                </form>
              </div>
            )})}
          </>
        )}
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default UpdateInventory;
