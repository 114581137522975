/* eslint-disable react/jsx-boolean-value */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */

import CustomTable from "components/CustomTable/CustomTable";
import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";

import "./UploadHistory.css";

const UploadHistroy = (props) => {
  const [data, setData]=useState('');
  const p=props;
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  const UploadHistroyCol = [
    { field: "dou", header: "Date/Time of Upload" },
    { field: "fileName", header: "File Name" },
    { field: "batchId", header: "Batch Id" },
    { field: "uploadStatus", header: "Upload Status" },
    { field: "comments", header: "Comments" },
    { field: "button", header: "Action" }
  ];

  useEffect(() => {
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response=await AuthAxios.get(`/bulkupload/inventory?company_id=${p.selectedCompany}`)
      }
      else{
        response = await AuthAxios.get(`/bulkupload/inventory?company_id=${authTokens.data.company.id}`);
      }
      if (response.status === 200) {
        console.log()
        setData(response.data.data);
      }
    })();
  }, [p.selectedCompany]);

  const [history, setHistory]=useState([]);

  useEffect(()=>{
    if(data.length>0){
    setHistory(
      data.map((item)=>({
        dou: item.timestamp.slice(0, item.timestamp.indexOf('T')) + " / " + item.timestamp.slice(item.timestamp.indexOf('T')+1, item.timestamp.indexOf('.')),
        fileName: item.uploaded_file,
        batchId: item.id,
        uploadStatus: item.failure>0 ? "Error" : "Success",
        comments: "",
        button: item.failure>0 ? "Download Error file" : "Download File"
      }))
    )
    }
  }, [data])

  useEffect(()=>{console.log(history)}, [history])


  const download = async (info)=>{
    if(info.uploadStatus==='Error'){
      await AuthAxios.get(`/bulkupload/history?id=${info.batchId}&type=error`)
      .then((response)=>{
        console.log(response.data.url);
        window.open(response.data.url);
      })
    } else {
      await AuthAxios.get(`/bulkupload/history?id=${info.batchId}&type=original`)
      .then((response)=>{
        console.log(response.data.url);
        window.open(response.data.url);
      })
    }
    
    console.log(info.batchId);
  }

  // const UploadHistroyRow = [
  //   {
  //     dou: "01:50PM 9 Sep 2022",
  //     fileName: "All apparels 12sep2022.csv",
  //     batchId: "4965205",
  //     uploadStatus: "Error",
  //     comments: "Download Excel sheet and reupload after fixing the file",
  //     button: "Download"
  //   },
  //   {
  //     dou: "01:50PM 9 Sep 2022",
  //     fileName: "All apparels 12sep2022.csv",
  //     batchId: "4965206",
  //     uploadStatus: "Upload Complete",
  //     comments: "All details are correct, you can see this items on app",
  //     button: "Download"
  //   },
  //   {
  //     dou: "01:50PM 9 Sep 2022",
  //     fileName: "All apparels 12sep2022.csv",
  //     batchId: "4965207",
  //     uploadStatus: "In Review",
  //     comments: "Team is reviewing",
  //     button: "Download"
  //   }
  // ];

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="upload-file-container">
        <div className="pending-order-table-section">
          <CustomTable
            data={history}
            columns={UploadHistroyCol}
            hover={false}
            striped={false}
            customClassOne="upload-file-download-button"
            buttonTypeOne="Download"
            // customAction={setRefundModal}
            customData={info=>download(info)}
          />
        </div>
      </div>
    </>
  );
};

export default UploadHistroy;
