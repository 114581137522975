/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React from "react";
import Modal from "react-bootstrap/Modal";

import "./RefundModal.css";

const RefundModal = ({ refundModal, setRefundModal, refundInfo }) => {
  const closeModalAction = () => {
    setRefundModal(false);
  };

  console.log(refundInfo, "refundInfo");

  const formControl = "form-control";

  const handleClick = () => {
    closeModalAction();
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .form-group {
            width:100%;
        }`
        }}
      />
      <Modal
        show={refundModal}
        onHide={() => closeModalAction()}
        className="refund-modal modal-open-up"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <div className="refund-modal-header">
            <div className="refund-modal-header-information">
              <div className="refund-modal-header-order-id">Refund Details</div>
              <div className="refund-modal-header-amount-info">
                Order Id: {refundInfo.orderId}
              </div>
            </div>
            <div
              className="refund-modal-header-close"
              onClick={() => closeModalAction()}
            >
              Close
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="refund-body">
            <div className="refund-information-section">
              <div className="form-group">
                <label>Refund Amount</label>
                <div className="customer-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="refundAmt"
                    id="refundAmt"
                    value={refundInfo.refundAmt}
                    style={{ paddingLeft: "0.75rem" }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Time and Date</label>
                <div className="customer-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="timeDate"
                    id="timeDate"
                    value={refundInfo.date}
                    style={{ paddingLeft: "0.75rem" }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Reference Id</label>
                <div className="customer-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="refId"
                    id="refId"
                    value={refundInfo.refId}
                    style={{ paddingLeft: "0.75rem" }}
                  />
                </div>
              </div>
              <div className="refund-button">
                <button
                  type="button"
                  className="btn-refund-save"
                  onClick={() => handleClick()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RefundModal;
