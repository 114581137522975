/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import formValidation from "utlis/formValidation";
import CustomToast from "utlis/Toast/CustomToast";
import { ToastConstant } from "utlis/Toast/toastConstant";

import "./ResetPassword.css";

const ResetPassword = () => {
  const formControl = "form-control";
  const initialValues = { newPassword: "", confirmPassword: "" };
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    console.log(formValues, "formValues");
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(formValidation.resetPasswordFormValidate(formValues));
    setIsSubmit(true);
    setOpen(true);
    setOpen(true);
    setToastData(ToastConstant.DYNAMIC_FAIL("Test Message", 3000, "error"));
  };

  useEffect(() => {
    console.log(formErrors, "formErrors");
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .form-group {
                width:100%;
                margin-top: 1rem;
            }`
        }}
      />
      <div className="reset-password-container">
        <div className="reset-password-box">
          <div className="reset-password-branding-logo">
            <div className="brand-logo">
              <img src="./img/fadoki-logo.svg" alt="" />
            </div>
          </div>
          <div className="reset-password-form">
            <div className="reset-password-content">
              <div className="reset-password-header">Reset Password</div>

              <form
                className="reset-password-form-structure"
                id="forgotPasswordDetail"
                noValidate
                autoComplete="off"
                autofill="off"
                onSubmit={handleSubmit}
              >
                <div className="reset-password-form-section">
                  <div className="form-group">
                    <label>New Password</label>
                    <div className="customer-input-area">
                      <input
                        type={newPasswordType}
                        className={formControl}
                        name="newPassword"
                        placeholder="Please enter new password"
                        onChange={e => handleChange(e)}
                        value={formValues.newPassword}
                        style={{ paddingLeft: "0.75rem" }}
                        autoComplete="new-password"
                      />

                      {newPasswordType === "text" ? (
                        <span
                          className="sign-in-eye-position material-icons"
                          onClick={() => setNewPasswordType("password")}
                        >
                          visibility
                        </span>
                      ) : (
                        <span
                          className="sign-in-eye-position material-icons"
                          onClick={() => setNewPasswordType("text")}
                        >
                          visibility_off
                        </span>
                      )}
                    </div>
                    {formErrors.newPassword &&
                      formErrors.newPassword.length > 0 && (
                        <p className="form-validation-error">
                          {formErrors.newPassword}
                        </p>
                      )}
                  </div>

                  <div className="form-group">
                    <label>Re-enter Password</label>
                    <div className="customer-input-area">
                      <input
                        type={confirmPasswordType}
                        className={formControl}
                        name="confirmPassword"
                        placeholder="Please re-enter the password"
                        onChange={e => handleChange(e)}
                        value={formValues.confirmPassword}
                        style={{ paddingLeft: "0.75rem" }}
                        autoComplete="new-password"
                      />

                      {confirmPasswordType === "text" ? (
                        <span
                          className="sign-in-eye-position material-icons"
                          onClick={() => setConfirmPasswordType("password")}
                        >
                          visibility
                        </span>
                      ) : (
                        <span
                          className="sign-in-eye-position material-icons"
                          onClick={() => setConfirmPasswordType("text")}
                        >
                          visibility_off
                        </span>
                      )}
                    </div>

                    {formErrors.confirmPassword &&
                      formErrors.confirmPassword.length > 0 && (
                        <p className="form-validation-error">
                          {formErrors.confirmPassword}
                        </p>
                      )}
                  </div>

                  {/* Password match check */}
                  {/* {formValues.newPassword &&
                    formValues.rePassword &&
                    formValues.newPassword !== formValues.rePassword && (
                      <p className="form-validation-error">
                        Password-missmatch
                      </p>
                    )} */}
                  <div className="reset-password-button">
                    <button type="submit" className="btn btn-booking-confirm">
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default ResetPassword;
