/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";

import "./Logistics.css";

const Logistics = (props) => {
  const p=props;
  const formControl = "form-control";
  const [enteredSearchOrderId, setEnteredSearchOrderId] = useState("");
  const [apiData, setApiData] = useState("");
  const [searchedOrderId, setSearchedOrderedId] = useState([]);

  const ExistingBrandCol = [
    { field: "orderId", header: "Order Id" },
    { field: "storeName", header: "Store Name" },
    { field: "deliveryAddress", header: "Delivery Address" },
    { field: "estDistance", header: "Est. Distance" },
    { field: "expecteddPickup", header: "Expected Pickup" },
    { field: "expectedDelivery", header: "Expected Delivery" },
    { field: "estCharge", header: "Est. Charge" },
    { field: "actualDeliveryTime", header: "Actual Delivery Time" }
  ];

  const ExistingBrandRow = [
    {
      orderId: "123456",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123457",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123458",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123459",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123460",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123461",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123462",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123463",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123464",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123465",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123466",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123467",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123468",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    },
    {
      orderId: "123469",
      storeName: "Colorbar , HSR",
      deliveryAddress:
        "1014, Tower 6, Sai Poorna Premier Kudlu, Bengaluru-560068",
      estDistance: "2km",
      expecteddPickup: "01:50PM 9 Sep 2022",
      expectedDelivery: "01:50PM 9 Sep 2022",
      estCharge: "₹50",
      actualDeliveryTime: "01:50PM 9 Sep 2022"
    }
  ];

  useEffect(() => {
    setApiData(ExistingBrandRow);
  }, []);

  const handleChange = searchedItem => {
    setEnteredSearchOrderId(searchedItem);
    if (enteredSearchOrderId !== "") {
      const filteredData = ExistingBrandRow.filter(item => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(enteredSearchOrderId.toLowerCase());
      });
      setSearchedOrderedId(filteredData);
    } else {
      setSearchedOrderedId(apiData);
    }
  };
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
            margin-bottom: 0;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="pending-order-container">
        <div className="pending-order-header">
          <div className="pending-order-search-box">
            <div className="form-group">
              <div className="pending-order-search">
                <input
                  type="text"
                  onChange={e => handleChange(e.target.value)}
                  className={formControl}
                  name="orderId"
                  id="orderId"
                  placeholder="Search order id"
                  value={enteredSearchOrderId}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="pending-order-filter">
            <div className="pending-order-header-filter">
              <div className="pending-order-filters-list">
                <div className="pending-order-filter-tab">
                  <span className="pending-order-individual-filter">
                    Sort By &#9660;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pending-order-table-section">
          <CustomTable
            data={
              Object.keys(searchedOrderId).length > 0
                ? searchedOrderId
                : ExistingBrandRow
            }
            columns={ExistingBrandCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default Logistics;
