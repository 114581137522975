/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React from "react";

import Select from "react-select";
import CustomTable from "components/CustomTable/CustomTable";

import "./ReturnReports.css";

const ReturnReports = () => {
  const storeDropdown = [
    { value: "allStore", label: "All Store" },
    { value: "store1", label: "Store 1" },
    { value: "store2", label: "Store 2" }
  ];

  const salesReportSession = [
    { value: "todaySale", label: "Today's Sale" },
    { value: "7daySale", label: "Week Sale" },
    { value: "1monthSale", label: "Monthly Sale" }
  ];

  const SalesReportCol = [
    { field: "orderId", header: "Order Id" },
    { field: "customerId", header: "Customer Id" },
    { field: "customerName", header: "Customer Name" },
    { field: "attribute1", header: "Attribute 1" },
    { field: "attribute2", header: "Attribute 2" },
    { field: "attribute3", header: "Attribute 3" },
    { field: "deliveredBy", header: "Delivered by" },
    { field: "fullfillment", header: "Fullfillment" },
    { field: "refundTotal", header: "Refund Total" }
  ];

  const SalesReportRow = [
    {
      orderId: "1234567",
      customerId: "8475",
      customerName: "Nitin Kumar",
      attribute1: "Lorem Ipsum",
      attribute2: "Lorem Ipsum",
      attribute3: "Lorem Ipsum",
      deliveredBy: "Dunzo",
      fullfillment: "100%",
      refundTotal: "₹500"
    },
    {
      orderId: "1234568",
      customerId: "8472",
      customerName: "Bipin Singh",
      attribute1: "Lorem Ipsum",
      attribute2: "Lorem Ipsum",
      attribute3: "Lorem Ipsum",
      deliveredBy: "ShadowFax",
      fullfillment: "100%",
      refundTotal: "₹500"
    },
    {
      orderId: "1234568",
      customerId: "8472",
      customerName: "Bipin Singh",
      attribute1: "Lorem Ipsum",
      attribute2: "Lorem Ipsum",
      attribute3: "Lorem Ipsum",
      deliveredBy: "ShadowFax",
      fullfillment: "100%",
      refundTotal: "₹500"
    },
    {
      orderId: "1234568",
      customerId: "8472",
      customerName: "Bipin Singh",
      attribute1: "Lorem Ipsum",
      attribute2: "Lorem Ipsum",
      attribute3: "Lorem Ipsum",
      deliveredBy: "ShadowFax",
      fullfillment: "100%",
      refundTotal: "₹500"
    },
    {
      orderId: "1234568",
      customerId: "8472",
      customerName: "Bipin Singh",
      attribute1: "Lorem Ipsum",
      attribute2: "Lorem Ipsum",
      attribute3: "Lorem Ipsum",
      deliveredBy: "ShadowFax",
      fullfillment: "100%",
      refundTotal: "₹500"
    }
  ];

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .css-b62m3t-container {
            width: 100%;
          }
          table {
            width: 100%;
          }
          `
        }}
      />
      <div className="sales-return-report-container">
        <div className="sales-return-report-dropdown">
          <div className="sales-return-report-store-dropdown">
            <Select options={storeDropdown} />
          </div>
          <div className="sales-return-report-session-dropdown">
            <Select options={salesReportSession} />
          </div>
          <div className="sales-return-report-figure-section">
            <div className="sales-return-report-figure">Total Sales: &#8377;9999</div>
            <div className="sales-return-report-download-button">Download</div>
          </div>
        </div>
        <div className="sales-return-report-table-section">
          <CustomTable
            data={SalesReportRow}
            columns={SalesReportCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default ReturnReports;
