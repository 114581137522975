/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState, useContext } from "react";
import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";

import "./UploadFile.css";

const UploadFile = (props) => {
  const p=props;
  // On success of uploading the file, place the file name on the list
  const uploadedFiles = [
    { id: "001", name: "Apparel catalog 834756.csv" },
    { id: "001", name: "Apparel catalog 834756.csv" }
  ];
  const { authTokens } = useContext(AuthContext);

  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  const [file, setFile] = useState();

  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    setFile(event.target.files[0]);
  };

  // const handleReset = () => {
  //   setFile(null);
  //   document.getElementById("fileUpload").value = "";
  // };

  const handleUploadFile = () => {
    console.log(file);
    if (file) {
      // HIT API

      // Run this code at the end of the successful file upload
      // document.getElementById("fileUpload").value = "";
      const formData = new FormData();
      formData.append("file", file);
      formData.append("companyId", p.selectedCompany)
      AuthAxios.post('bulkupload/product', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then(response => {
      })
      .catch(error => {
      })
      console.log(file.name);
    }
  };

  useEffect(() => {
    // UseEffect to upload the file
    if (file) {
      handleUploadFile();
    }
  }, [file]);

  return (
    <>
      <div className="upload-file-container">
        <div className="upload-file-section">
          <img src="/img/fileUpload.svg" alt="" />
          <span className="file-upload-header">Drag and drop file here or</span>
          <span className="accepted-format">
            Accepted file formats: Excel, CSV
          </span>
          <div className="file-upload-buttons">
            <button
              type="button"
              className="btn btn-browse-file"
              onClick={handleClick}
            >
              Browse Files
            </button>
            <input
              type="file"
              id="fileUpload"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".xlsx,.xls,.csv"
            />
            <button
              type="button"
              className="btn btn-download-sample"
              //   onClick={() => handleReset()}
            >
              Download
            </button>
          </div>
        </div>

        {/* <div className="uploaded-files-listing boom">
          <div className="uploaded-files-listing-header">Uploaded Files</div>
          {uploadedFiles.map(file => (
            <div className="uploaded-file-list">
              <div className="uploaded-file-image">
                <img src="/img/file.svg" alt="file" />
              </div>
              <div className="uploaded-file-name">{file.name}</div>
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};

export default UploadFile;
