/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Icon
import Icon from "@mui/material/Icon";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../Authentication/AuthContext/AuthContext";

// Custom Component
import MissingItemsTable from "./MissingItemsTable";
import CustomerOrderDetails from "./CustomerOrderDetails";

// CSS
import "./SingleOrderDetail.css";

const SingleOrderDetail = () => {
  const navigate=useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [selectedOrderDetailHeader, setSelectedOrderDetailHeader] =
    useState(true);
  const [selectedMissingOrderHeader, setSelectedMissingOrderHeader] =
    useState(false);

  const params = useParams();

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  useEffect(() => {
    if (params.orderId) {
      // run the customer details api fetch and store the info pass the state down to customer details comp
      (async () => {
        const response = await AuthAxios.get(
          `/order?orderId=${params.orderId}`
        );
        if (response.status === 200) {
          setOrderData(response.data);
        }
      })();
    }
  }, [params.orderId]);

  // console.log(orderData.orders[0].items[0]);
  // console.log(orderData.orders[0]);

  // Based on this status the check marks will come
  // const orderStatus = ["PENDING", "PACKED", "SHIPPED", "DELIVERED"];

  // orderData is obj
  // console.log(typeof orderData);

  if (
    orderData &&
    orderData.orders &&
    orderData.orders.length > 0 &&
    orderData.orders[0].store
  ) {
    // console.log(orderData?.orders[0]);
    // console.log(orderData.orders[0].store.poc);
  }

  const [orderStatus, setOrderStatus] = useState({
    pending: false,
    packed: false,
    shipped: false,
    delivered: false
  });

  useEffect(() => {
    if (
      orderData &&
      orderData.orders &&
      orderData.orders.length > 0 &&
      orderData.orders[0].status &&
      orderData.orders[0].status.length > 0
    ) {
      if (orderData.orders[0].status === "PENDING") {
        setOrderStatus({
          pending: true,
          packed: false,
          shipped: false,
          delivered: false
        });
      } else if (orderData.orders[0].status === "PACKED") {
        setOrderStatus({
          pending: true,
          packed: true,
          shipped: false,
          delivered: false
        });
      } else if (orderData.orders[0].status === "SHIPPED") {
        setOrderStatus({
          pending: true,
          packed: true,
          shipped: true,
          delivered: false
        });
      } else if (orderData.orders[0].status === "DELIVERED") {
        setOrderStatus({
          pending: true,
          packed: true,
          shipped: true,
          delivered: true
        });
      }
    }
  }, [orderData]);

  return (
    <>
      <DashboardLayout>
        <div className="single-order-details-container ">
          <div className="order-table-header">
            <div className="back-button"
            onClick={()=> navigate(`/dashboard`)}>
              <Icon fontSize="small" color="inherit">
                arrow_back_ios
              </Icon>
            </div>
            Order id: {params.orderId}
          </div>
          <hr className="line" />

          <div className="order-status">
            <div className="order-status-tab">
              {orderStatus.pending ? (
                <img src="/img/tick.svg" alt="tick" />
              ) : (
                <img src="/img/uncheck.svg" alt="tick" />
              )}
              <span className="shop"> Placed</span>
              {orderStatus.pending && `Order Placed`} <br />
            </div>

            <div className="order-status-tab">
              {orderStatus.packed ? (
                <img src="/img/tick.svg" alt="tick" />
              ) : (
                <img src="/img/uncheck.svg" alt="tick" />
              )}
              <span className="shop"> Pack</span>
              {orderStatus.packed && `Order Packed`} <br />
            </div>

            <div className="order-status-tab">
              {orderStatus.shipped ? (
                <img src="/img/tick.svg" alt="tick" />
              ) : (
                <img src="/img/uncheck.svg" alt="tick" />
              )}
              <span className="shop"> Ship</span>
              {orderStatus.shipped && `Order Shipped`} <br />
            </div>

            <div className="order-status-tab">
              {orderStatus.delivered ? (
                <img src="/img/tick.svg" alt="tick" />
              ) : (
                <img src="/img/uncheck.svg" alt="tick" />
              )}
              <span className="shop">Delivered</span>
              {orderStatus.delivered && `On the way`} <br />
              {orderStatus.delivered && (
                <span className="eta-delivery">&#x2022; ETA 20 min</span>
              )}
            </div>
          </div>

          <div className="order-details">
            <div className="store-detail">
              <div className="store-information ">
                <div className="shop-name">
                  Shop:{" "}
                  <span className="shop">
                    {" "}
                    {orderData &&
                      orderData.orders &&
                      orderData.orders.length > 0 &&
                      orderData.orders[0].store &&
                      orderData.orders[0].store.name}
                  </span>
                </div>
                {orderData &&
                  orderData.orders &&
                  orderData.orders.length > 0 &&
                  orderData.orders[0].store && (
                    <div className="shop-address">
                      {orderData.orders[0].store.area},{" "}
                      {orderData.orders[0].store.city},{" "}
                      {orderData.orders[0].store.state},{" "}
                      {orderData.orders[0].store.pincode}
                    </div>
                  )}
              </div>
            </div>

            <div className="customer-delivery-detail">
              <div className="store-information delivery-partner">
                <div className="shop-name">
                  Delivery by:{" "}
                  <span className="shop">
                    {" "}
                    {orderData &&
                      orderData.orders &&
                      orderData.orders.length > 0 &&
                      orderData.orders[0].delivery &&
                      orderData.orders[0].delivery.partner}{" "}
                  </span>
                </div>
                {orderData &&
                  orderData.orders &&
                  orderData.orders.length > 0 &&
                  orderData.orders[0].delivery &&
                  orderData.orders[0].delivery.partnerName &&
                  orderData.orders[0].delivery.partnerName.length > 0 &&
                  orderData.orders[0].delivery.partnerNameNumber &&
                  orderData.orders[0].delivery.partnerNameNumber.length > 0 && (
                    <div className="shop-address">
                      Delivery Boy: John Doe <br />
                      Mobile No: +91 8888 888 88
                    </div>
                  )}
              </div>
              <div className="store-information customer-address">
                <div className="shop-name">
                  <span className="shop">
                    {" "}
                    {orderData &&
                      orderData.orders &&
                      orderData.orders.length > 0 &&
                      orderData.orders[0].user &&
                      orderData.orders[0].user.length > 0 &&
                      orderData.orders[0].user}
                  </span>
                </div>
                {orderData &&
                  orderData.orders &&
                  orderData.orders.length > 0 &&
                  orderData.orders[0].area &&
                  orderData.orders[0].area.length > 0 &&
                  orderData.orders[0].city &&
                  orderData.orders[0].city.length > 0 &&
                  orderData.orders[0].state &&
                  orderData.orders[0].state.length > 0 &&
                  orderData.orders[0].pincode &&
                  orderData.orders[0].pincode.length > 0 && (
                    <div className="shop-address">
                      {orderData.orders[0].area}, {orderData.orders[0].city},{" "}
                      {orderData.orders[0].state}, {orderData.orders[0].pincode}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>

        {/* Second Tab */}

        {orderData && orderData.orders && orderData.orders.length > 0 && (
          <CustomerOrderDetails orderData={orderData.orders[0].items} />
        )}

        {/* <div className="single-order-details-container ">
          <div className="single-order-header">
            <div
              className={`${
                selectedOrderDetailHeader ? "selected-tab" : ""
              } single-order-detail-table-header`}
              onClick={() => {
                setSelectedOrderDetailHeader(true);
                setSelectedMissingOrderHeader(false);
              }}
            >
              Order Details
            </div>
            <div
              className={`single-order-detail-table-header ${
                selectedMissingOrderHeader ? "selected-tab" : ""
              }`}
              onClick={() => {
                setSelectedMissingOrderHeader(true);
                setSelectedOrderDetailHeader(false);
              }}
            >
              Missing Items
            </div>
          </div>
          <hr className="line" />
          {selectedOrderDetailHeader && !selectedMissingOrderHeader ? (
            <CustomerOrderDetails />
          ) : (
            <MissingItemsTable />
          )}
        </div> */}
      </DashboardLayout>
    </>
  );
};

export default SingleOrderDetail;
