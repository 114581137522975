/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import RefundModal from "./RefundModal";

import "./RefundTransaction.css";

const RefundTransaction = (props) => {
  const p=props;
  const formControl = "form-control";
  const [enteredSearchOrderId, setEnteredSearchOrderId] = useState("");

  const [refundModal, setRefundModal] = useState(false);
  const [refundInfo, setRefundInfo] = useState([]);

  const handleChange = searchedItem => {
    setEnteredSearchOrderId(searchedItem);
    // filter api here
  };

  const refundTransactionCol = [
    { field: "orderId", header: "Order Id" },
    { field: "store", header: "Store" },
    { field: "phNumber", header: "Mobile Number" },
    { field: "orderStatus", header: "Order Status" },
    { field: "date", header: "Date" },
    { field: "refundAmt", header: "Refund Amount" },
    { field: "paymentMode", header: "Payment Mode" },
    { field: "refId", header: "Reference Id" },
    { field: "refundedOn", header: "Refunded On" },
    { field: "button", header: "Action" }
  ];

  const refundTransactionRow = [
    {
      orderId: "1234565",
      store: "Colorbar, HSR",
      phNumber: "9999 9999 99",
      orderStatus: "Returned",
      date: "01:50PM 9 Sep’22",
      refundAmt: "₹500",
      paymentMode: "UPI",
      refId: "3495839",
      refundedOn: "NA",
      button: "Refund"
    },
    {
      orderId: "1234566",
      store: "Colorbar, HAL",
      phNumber: "8888 8888 88",
      orderStatus: "Returned",
      date: "01:50PM 9 Sep’22",
      refundAmt: "₹500",
      paymentMode: "Paytm",
      refId: "3495850",
      refundedOn: "01:50PM 9 Sep’22",
      button: "Completed"
    }
  ];

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      {refundModal && refundInfo && Object.keys(refundInfo).length > 0 && (
        <RefundModal
          refundModal={refundModal}
          setRefundModal={setRefundModal}
          refundInfo={refundInfo}
        />
      )}
      <div className="refund-transaction-container">
        <div className="refund-transaction-header">
          <div className="refund-transaction-search-box">
            <div className="form-group" style={{ marginBottom: "0" }}>
              <div className="refund-transaction-search">
                <input
                  type="text"
                  onChange={e => handleChange(e.target.value)}
                  className={formControl}
                  name="orderId"
                  id="orderId"
                  placeholder="Search order id"
                  value={enteredSearchOrderId}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="refund-transaction-filter">
            <div className="refund-transaction-header-filter">
              <div className="refund-transaction-filters-list">
                <div className="refund-transaction-filter-tab">
                  <span className="refund-transaction-individual-filter">
                    Sort By &#9660;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="refund-transaction-table-section">
          <CustomTable
            data={refundTransactionRow}
            columns={refundTransactionCol}
            hover={false}
            striped={false}
            customClassOne="refund-transaction-refund-active"
            buttonTypeOne="Refund"
            customClassTwo="refund-transaction-refund-closed"
            buttonTypeTwo="Completed"
            customAction={setRefundModal}
            customData={setRefundInfo}
          />
        </div>
      </div>
    </>
  );
};

export default RefundTransaction;
