/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
/* eslint-disable prettier/prettier */
export const ToastConstant = {
  BAD_TOKEN: {
    time: 5000,
    severity: "error",
    message: "Invalid Token!"
  },
  LOGIN_SUCCESS: {
    time: 3000,
    severity: "success",
    message: "Logged In Successfully!"
  },
  LINK_SEND_SUCCESS: {
    time: 3000,
    severity: "success",
    message: "Reset link sent to your email"
  },
  TOKEN_EXPIRED: {
    time: 8000,
    severity: "error",
    message: "Token Expired!"
  },
  INVALID_CREDS: {
    time: 8000,
    severity: "error",
    message: "Incorrect email or password, please check and try again"
  },
  PASSWORD_CHANGE_SUCCESS: {
    time: 5000,
    severity: "success",
    message: "Password Changed Successfully!"
  },
  ALREAY_EXIST: {
    time: 8000,
    severity: "error",
    message: "Account Already Exists!"
  },
  LOGOUT_MSG: {
    time: 3000,
    severity: "success",
    message: "Logout Successfully!"
  },
  RESCHDEULE_BEFORE_24_HRS: {
    time: 5000,
    severity: "error",
    message:
      "Appointments cannot be rescheduled when less than 24 hours are remaining for the session."
  },
  SESSION_START_BEFORE_15_MIN: {
    time: 5000,
    severity: "error",
    message: "Link will be activated 15 minutes prior."
  },
  SESSION_START_BEFORE_15_MIN_PHONE: {
    time: 5000,
    severity: "error",
    message: "Your therapist will contact you on Phone Number."
  },
  NOT_FOUND: {
    time: 2000,
    severity: "error",
    message: "DATA NOT FOUND!"
  },
  INVALID_ID: {
    time: 8000,
    severity: "error",
    message: "Invalid Id!"
  },
  SLOT_UNAVAILABLE: {
    time: 6000,
    severity: "error",
    message: "Slot Not Available!"
  },
  AUTO_SAVE: {
    time: 3000,
    severity: "success",
    message: "Saved!"
  },
  AUTO_SAVE_ERROR: {
    time: 3000,
    severity: "error",
    message: "Please write something"
  },
  FEEDBACK_SUCCESS: {
    time: 5000,
    severity: "success",
    message: "Feedback Submitted Successfully!"
  },
  FEEDBACK_ALREADY_SUBMITED: {
    time: 5000,
    severity: "error",
    message: "Feedback Already Submitted!"
  },
  FEEDBACK_FAIL: {
    time: 5000,
    severity: "error",
    message: "Feedback Not Submitted!"
  },
  FORBIDDEN: {
    time: 5000,
    severity: "error",
    message: "FORBIDDEN!"
  },
  DEV_ERROR: {
    time: 5000,
    severity: "error",
    message: "DEV Error!"
  },
  INVALID_ACCESS_CODE: {
    time: 5000,
    severity: "error",
    message: "INVALID_ACCESS_CODE"
  },
  INVALID_OTP: {
    time: 5000,
    severity: "error",
    message: "INVALID_OTP"
  },
  OTP_ALREADY_VERIFIED: {
    time: 5000,
    severity: "error",
    message: "USER_ALREADY_VERIFIED"
  },
  OTP_SEND: {
    time: 3000,
    severity: "success",
    message: "OTP SEND SUCCESSFULLY"
  },
  INVALID_ENTRY: {
    time: 5000,
    severity: "error",
    message: "INVALID_ENTRY"
  },
  RESCHDEULE_LIMIT_EXCEED: {
    time: 5000,
    severity: "error",
    message: "You have exhausted your Session Reschedule Limit."
  },
  ACCESS_TOKEN_EXPIRED: {
    time: 5000,
    severity: "error",
    message: "Access token expired!"
  },
  ALREADY_VERIFIED: {
    time: 5000,
    severity: "error",
    message: "User already verified"
  },
  B2B_NOT_ALLOWED: {
    time: 5000,
    severity: "error",
    message: "B2B not allowed!"
  },
  BAD_ENUM: {
    time: 5000,
    severity: "error",
    message: "Bad enum!"
  },
  BOAT_EXPIRED: {
    time: 2000,
    severity: "error",
    message: "Boat not available!"
  },
  COUPON_EXPIRED: {
    time: 5000,
    severity: "error",
    message: "Coupon Expired!"
  },
  COUPON_NOT_ALLOWED: {
    time: 5000,
    severity: "error",
    message: "Coupon not allowed"
  },
  COUPON_NOT_FOUND: {
    time: 5000,
    severity: "error",
    message: "Coupon not found"
  },
  COUPON_USED: {
    time: 5000,
    severity: "error",
    message: "Coupon Used!"
  },
  CHECKOUT_ERROR: {
    time: 5000,
    severity: "error",
    message: "Checkout Error!"
  },
  DOMAIN_NOT_ALLOWED: {
    time: 5000,
    severity: "error",
    message: "Domain not allowed, contact your HR!"
  },
  DYNAMIC_ERROR: message => {
    return { time: 5000, severity: "error", message: message };
  },
  DYNAMIC_SUCCESS: (message, time, severity) => {
    return { time: time, severity: severity, message: message };
  },
  DYNAMIC_FAIL: (message, time, severity) => {
    return { time: time, severity: severity, message: message };
  },
  EXIST_ALREADY: {
    time: 5000,
    severity: "error",
    message: "Already Exist"
  },
  FEEDBACK_ALREADY_SUBMITTED: {
    time: 5000,
    severity: "error",
    message: "Feedback already submitted!"
  },
  INVALID_AUTH: {
    time: 5000,
    severity: "error",
    message: "Invalid Auth!"
  },
  INVALID_SIGNATURE: {
    time: 5000,
    severity: "error",
    message: "Invalid Signature!"
  },
  NOT_ALLOWED: {
    time: 5000,
    severity: "error",
    message: "Not possible!"
  },
  OTP_EXPIRED: {
    time: 5000,
    severity: "error",
    message: "OTP Expired!"
  },
  OTP_INVALID: {
    time: 5000,
    severity: "error",
    message: "Invalid OTP!"
  },
  SCHEDULE_ALREADY_CLOSED: {
    time: 5000,
    severity: "error",
    message: "Schedule already closed!"
  },
  SESSION_BOOKING_LIMIT: {
    time: 5000,
    severity: "error",
    message: "Too many sessions for booking!"
  },
  SESSION_CANCEL: {
    time: 5000,
    severity: "error",
    message: "Session cancellation error!"
  },
  SESSION_CANCEL_NOT_ALLOWED: {
    time: 5000,
    severity: "error",
    message: "Session cancellation not allowed!"
  },
  SESSION_RESCHEDULE_ERROR: {
    time: 5000,
    severity: "error",
    message: "Session reschedule error!"
  },
  SESSION_RESCHEDULE_LIMIT_REACHED: {
    time: 5000,
    severity: "error",
    message: "Session reschedule limit reached!"
  },
  LOW_WALLET_BALANCE: {
    time: 5000,
    severity: "error",
    message: "Low wallet balance!"
  },
  SESSION_CANCEL_V1: {
    time: 5000,
    severity: "error",
    message: "Session cancellation error. Contact Help & Support!"
  },
  NIL: {
    time: 5000,
    severity: "error",
    message: "NIL"
  },
  DATA_MISSING: {
    time: 5000,
    severity: "error",
    message: "Data Missing?"
  },
  VALIDATION_FAIL: {
    time: 5000,
    severity: "error",
    message: "Validation Fail!"
  },
  USER_BLOCKED: {
    time: 5000,
    severity: "error",
    message: "Account access has been blocked. Contact help & support."
  },
  USER_TEMPORARY_BLOCKED: {
    time: 5000,
    severity: "error",
    message: "Account access has been temporarily blocked For 15 minutes !"
  },
  WRONG_TYPE: {
    time: 5000,
    severity: "error",
    message: "WRONG_TYPE"
  },
  GIFT_TO_SELF_NOT_ALLOWED: {
    time: 5000,
    severity: "error",
    message: "Gift To Self Not Allowed!"
  },
  EMAIL_NOT_FOUND: {
    time: 5000,
    severity: "error",
    message: "This email is not registered, please sign up"
  },
  COUPON_MOBILE_ONLY: {
    time: 5000,
    severity: "error",
    message: "Invalid coupon, this coupon can only be applied on mobile app"
  },
  INVALID_CONTACT: {
    time: 5000,
    severity: "error",
    message: "Please enter a valid contact number"
  },
  EXIST_ALREADY_BOTH: {
    time: 8000,
    severity: "error",
    message: "This email and number is already registered"
  },
  EXIST_ALREADY_CONTACT: {
    time: 8000,
    severity: "error",
    message: "This number is already registered"
  },
  EXIST_ALREADY_EMAIL: {
    time: 8000,
    severity: "error",
    message: "This email is already registered"
  },
  FEATURE_NOT_ALLOWED: {
    time: 8000,
    severity: "error",
    message: "This feature is locked for your account"
  },
  FILE_UPLOAD_ERROR: {
    time: 8000,
    severity: "error",
    message: "File size cannot be greater than 5 MB"
  },
  INVALID_FILE_UPLOAD_ERROR: {
    time: 8000,
    severity: "error",
    message: "Only jpeg, jpg or png images allowed"
  },
  REFER_US_SUCCESS: {
    time: 8000,
    severity: "success",
    message: "Thank you for referring us"
  },
  GIFT_CODE_REDEEM_ERROR: {
    time: 8000,
    severity: "error",
    message: "Please enter the gift code"
  },
  SELECT_PLAN_MANDATORY: {
    time: 5000,
    severity: "error",
    message: "Insufficient balance, Please select a plan"
  }
};

export const razorPayErrorToast = error => {
  return {
    time: 5000,
    severity: "error",
    message: error.code
  };
};
