/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useContext, useState } from "react";
import AuthContext from "../Authentication/AuthContext/AuthContext";
import ExistingBrand from "./ExistingBrand/ExistingBrand";
import NewBrand from "./NewBrand/NewBrand";

import "./BrandRegistration.css";

const BrandRegistration = () => {
  const [createBrand, setCreateBrand] = useState(true);
  const [existingBrand, setExistingBrand] = useState(false);
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="brand-registration-container">
          <div className="brand-registration-header">
            <div
              className={`brand-registration-tab ${
                createBrand ? "selected-tab selected-tab-line" : ""
              } `}
              onClick={() => {
                setCreateBrand(true);
                setExistingBrand(false);
              }}
            >
              Register a Company
            </div>
            <div
              className={`brand-registration-tab ${
                existingBrand ? "selected-tab selected-tab-line" : ""
              }`}
              onClick={() => {
                setExistingBrand(true);
                setCreateBrand(false);
              }}
            >
              Registered Company
            </div>
          </div>
          <hr className="line" />
          {createBrand && !existingBrand ? <NewBrand /> : <ExistingBrand />}
        </div>
      </DashboardLayout>
    </>
  );
};

export default BrandRegistration;
