/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// @mui material components
import Grid from "@mui/material/Grid";
import DetailsTable from "../DetailsTable/DetailsTable";
import NewOrder from "./NewOrder/NewOrder";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import AuthContext from "../Authentication/AuthContext/AuthContext";
import axios from "axios";

const Dashboard = (props) => {
  const p=props;
  console.log(p.selectedCompany);
  const [dashboardStats, setDashboardStats] = useState([]);
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  useEffect(() => {
    (async () => {
      let response="";
      if(authTokens.data.role===4){
        response = await AuthAxios.get(`/home?company_id=${p.selectedCompany}`);
      }
      else{
        response = await AuthAxios.get(`/home`);
      }
      if (response.status === 200) {
        setDashboardStats(response.data);
        console.log(response.data);
      }
    })();
  }, [p.selectedCompany]);

  const information = [
    {
      id: "01",
      icon: "receipt_long",
      title: "Order Received",
      count: dashboardStats?.received,
      text: "Update Now"
    },
    {
      id: "01",
      icon: "shopping_bag",
      title: "Order Fulfilled",
      count: dashboardStats?.completed,
      text: "Update Now"
    },
    {
      id: "01",
      icon: "paid",
      title: "Total Sale",
      count: dashboardStats?.sale,
      text: "Update Now"
    },
    {
      id: "01",
      icon: "fingerprint",
      title: "Total Return",
      count: dashboardStats?.return_order,
      text: "Update Now"
    },
    {
      id: "01",
      icon: "keyboard_return",
      title: "Total Amount Return",
      count: dashboardStats?.return_order_count,
      text: "Update Now"
    },
    {
      id: "01",
      icon: "store",
      title: "Net Sale",
      count: dashboardStats?.sale,
      text: "Update Now"
    }
  ];
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <MDBox py={3}>
          {/* boxes */}
          <Grid container spacing={3}>
            {information.map(info => (
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="secondary"
                    icon={info.icon}
                    title={info.title}
                    count={info.count}
                    text={info.text}
                  />
                </MDBox>
              </Grid>
            ))}
          </Grid>
          <DetailsTable />
          <NewOrder selectedCompany={p.selectedCompany}/>
        </MDBox>
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
