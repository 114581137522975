/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext } from "react";
import AuthContext from "../Authentication/AuthContext/AuthContext";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import "./Support.css";

const Support = () => {
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="support-container">
          <div className="support-image">
            <img src="/img/sidebar/contactUs.svg" alt="" />
          </div>
          <div className="support-information">
            <div className="support-header">Contact Us</div>
            <div className="support-details">
              <img src="/img/sidebar/viaEmail.svg" alt="" />
              <span className="support-text">brandsupport@fadoki.com</span>
              <img src="/img/sidebar/viaPhone.svg" alt="" />
              <span className="support-text">+91-9739 9864 68</span>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Support;
