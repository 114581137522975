/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState, useRef } from "react";
import { ToastConstant } from "utlis/Toast/toastConstant";
import Icon from "@mui/material/Icon";
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import CustomTable from "components/CustomTable/CustomTable";
import CustomSelectValue from "components/CustomSelect/CustomSelectValue";
import CustomToast from "utlis/Toast/CustomToast";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import * as XLSX from 'xlsx';

const BXGY = ({
  promoTypeSelected,
  setPromoTypeSelected,
  promoTypeSelectedId,
  setPromoTypeSelectedId,
  storeCode,
  selectedCompany
}) => {
  const formControl = "form-control";
  const initialValues = {};
  const [formValues, setFormValues] = useState(initialValues);
  const [categories, setCategories]=useState([]);
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [csv1, setCsv1]=useState([]);
  const [csv2, setCsv2]=useState([]);
  const hiddenFileInput1 = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const [selectedCategories1, setSelectedCategories1]=useState(new Set());
  const [selectedCategories2, setSelectedCategories2]=useState(new Set());
  const [options, setOptions]=useState([]);
  const [showOtherStore, setShowOtherStore] = useState(false);
  const [StoreRow, setStoreRow]=useState([]);
  // toast state
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  // Date
  const [startTime, setStartTime] = useState(dayjs(new Date()));
  const [endTime, setEndTime] = useState(dayjs(new Date()));

  //   Search SKU for group1 state
  const [searchSKUGx, setSearchSKUGx] = useState("");
  const [skuGxResult, setSkuGxResult] = useState([]);
  const [skuGxResultRow, setSkuGxResultRow] = useState([]);
  const [skux, setSkux] = useState([]);

  //   Search SKU for group2 state
  const [searchSKUGy, setSearchSKUGy] = useState("");
  const [skuGyResult, setSkuGyResult] = useState([]);
  const [skuGyResultRow, setSkuGyResultRow] = useState([]);
  const [skuy, setSkuy] = useState([]);

  // Discount type state and options
  const [selectedDiscountType, setSelectedDiscountType] = useState("");

  const discountTypeOptions = [
    { name: "%-Percentage Off", id: "PERCENTOFF" },
    { name: "₹-Money Off", id: "MONEYOFF" },
    { name: "₹-Flat Price", id: "FLATPRICE" }
  ];

  const StoreCol=[
    { field: 'check', header: 'Select' },
    { field: 'storeCode', header: 'Store Code' },
    { field: 'storeName', header: 'Store Name' },
  ];

  //   API helper function
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  const handleChangeStartDate = newValue => {
    setStartTime(newValue);
  };
  const handleChangeEndDate = newValue => {
    setEndTime(newValue);
  };

  const backToPromotionCard = () => {
    setPromoTypeSelected([]);
    setPromoTypeSelectedId("");
  };

  useEffect(async ()=>{
    const response=await axios.get('https://apis.fadoki.com/category/all',{})
    setCategories(response.data);
    console.log(response.data);
  },[]);

  useEffect(()=>{
    const arr=[];
    console.log(categories);
    categories.forEach((item)=>{
      arr.push({name:item.name, value:item.id});
      item.category.forEach((i)=>{
        arr.push({name:i.name, value:i.id});
        i.sub_category.forEach((j)=>{
          arr.push({name:j.name, value:j.id});
        })
      })
    });
    console.log(arr);
    setOptions(
      arr.map((item)=>({
        label:item.name,
        value:item.value,
      }))
    )
    console.log(options);
  }, [categories]);
  
  useEffect(()=>{
    // console.log(csv1);
    // console.log(csv2);
  }, [csv1]);
  useEffect(()=>{
    // console.log(csv1);
    // console.log(csv2);
    // console.log(csv1);
  }, [csv2]);

  const handleClick1 = event => {
    hiddenFileInput1.current.click();
    // handleUploadFile1();
  };
  const handleClick2 = event => {
    hiddenFileInput2.current.click();
    // setFile1(file1)
    // handleUploadFile2();
  };

  const handleUploadFile1 = () => {
    console.log('I handle file1 uploads');
    // console.log(file);
    const reader=new FileReader();
    if (file1) {
      if(file1.name.slice(-4)==='xlsx' || file1.name.slice(-4)==='.xls'){
        reader.onload = (e) => {
          // console.log(e.target.result);
          const data=XLSX.read(e.target.result, {type:'binary'});
          const sheet=data.SheetNames[0];
          // console.log(data.Sheets[sheet]);
          const json=XLSX.utils.sheet_to_json(data.Sheets[sheet], {header: 1}).slice(1);
          // console.log(data);
          setCsv1(
            json.map((item)=>({
              sku: item[0]
            }))
          )
        }
        reader.readAsBinaryString(file1);
        // console.log(file1.name.slice(-4));
      }else{
        // console.log(file1.name.slice(-4));
        reader.onload = (e)=>{
          const text=e.target.result;
          const rows = text.slice(text.indexOf('\n')+1).split('\n');
          setCsv1(
            rows.map((item)=>({
              sku: item.slice(0, item.indexOf(','))
            }))
          )
        }
        reader.readAsText(file1);
      }
      console.log(csv1);
    }
  };

  const handleUploadFile2 = () => {
    console.log('I handle file2 uploads');
    // console.log(file);
    const reader=new FileReader();
    if (file2) {
      if(file2.name.slice(-4)==='xlsx' || file2.name.slice(-4)==='.xls'){
        reader.onload = (e) => {
          // console.log(e.target.result);
          const data=XLSX.read(e.target.result, {type:'binary'});
          const sheet=data.SheetNames[0];
          // console.log(data.Sheets[sheet]);
          const json=XLSX.utils.sheet_to_json(data.Sheets[sheet], {header: 1}).slice(1);
          // console.log(data);
          setCsv2(
            json.map((item)=>({
              sku: item[0]
            }))
          )
        }
        reader.readAsBinaryString(file2);
        // console.log(file2.name.slice(-4));
      }else{
        // console.log(file2.name.slice(-4));
        reader.onload = (e)=>{
          const text=e.target.result;
          const rows = text.slice(text.indexOf('\n')+1).split('\n');
          setCsv2(
            rows.map((item)=>({
              sku: item.slice(0, item.indexOf(','))
            }))
          )
        }
        reader.readAsText(file2);
      }
      console.log(csv2);
    }
  };

  useEffect(() => {
    // UseEffect to upload the file
    if (file1) {
      handleUploadFile1();
    }
  }, [file1]);

  useEffect(() => {
    console.log('file2 is - ', file2);
    // UseEffect to upload the file
    if (file2) {
      handleUploadFile2();
    }
  }, [file2]);

  const handleSelect1 = e => {
    setSelectedCategories1(e);
    console.log(selectedCategories1, "categories");
  };

  const handleSelect2 = e => {
    setSelectedCategories2(e);
    console.log(selectedCategories2, "categories");
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    console.log(formValues, "formValues");
  };

  const searchSKUGxHandler = () => {
    if (searchSKUGx && searchSKUGx.length > 0) {
      //   console.log(searchSKU);
      (async () => {
        const response = await AuthAxios.get(
          `/${storeCode.code}/inventory/${searchSKUGx}`
        );
        if (response.status === 200) {
          setSkuGxResult(response.data);
          setSkux(response.data[0].product.sku);
        }
      })();
    }
  };

  const searchSKUGyHandler = () => {
    if (searchSKUGy && searchSKUGy.length > 0) {
      //   console.log(searchSKU);
      (async () => {
        const response = await AuthAxios.get(
          `/${storeCode.code}/inventory/${searchSKUGy}`
        );
        if (response.status === 200) {
          setSkuGyResult(response.data);
          setSkuy(response.data[0].product.sku);
        }
      })();
    }
  };

  useEffect(() => {
    if (skuGxResult && skuGxResult[0] && skuGxResult[0].product.sku) {
      setSkux([...skux, skuGxResult[0]?.product?.sku]);
    }
  }, [skuGxResult]);

  useEffect(() => {
    if (skuGyResult && skuGyResult[0] && skuGyResult[0].product.sku) {
      setSkuy([...skuy, skuGyResult[0]?.product?.sku]);
    }
  }, [skuGyResult]);

  const productSelectionCol = [
    { field: "sku", header: "SKU" },
    { field: "productDetails", header: "Product Details" },
    { field: "type", header: "Type" },
    { field: "category", header: "Category" },
    { field: "subCategory", header: "Sub Category" },
    { field: "price", header: "Price" }
  ];

  useEffect(() => {
    if (skuGxResult && skuGxResult.length > 0) {
      setSkuGxResultRow([
        {
          sku: skuGxResult[0]?.product.sku,
          productDetails: skuGxResult[0]?.product.name,
          type: skuGxResult[0]?.product.name,
          category: skuGxResult[0]?.product.name,
          subCategory: skuGxResult[0]?.product.name,
          price: skuGxResult[0]?.product.sp
        }
      ]);
    }
  }, [skuGxResult]);

  useEffect(() => {
    if (skuGyResult && skuGyResult.length > 0) {
      setSkuGyResultRow([
        {
          sku: skuGyResult[0]?.product.sku,
          productDetails: skuGyResult[0]?.product.name,
          type: skuGyResult[0]?.product.name,
          category: skuGyResult[0]?.product.name,
          subCategory: skuGyResult[0]?.product.name,
          price: skuGyResult[0]?.product.sp
        }
      ]);
    }
  }, [skuGyResult]);

  const [storeList, setStoreList]=useState([]);
  const [selectedStores, setSelectedStores]=useState([]);
  useEffect(() => {
    if(showOtherStore===false){
      setSelectedStores([]);
    }
    else{
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response = await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${selectedCompany}/store`)
      }
      else{
        response = await AuthAxios.get("/stores");
      }
      if (response.status === 200) {
        setStoreList(response.data.data);
        console.log(response.data.data);
        // setStockOverviewLoader(false);
      }
    })();}
  }, [showOtherStore]);

  useEffect(()=>{
  if(storeList){
    console.log(storeList)
    setStoreRow(
      storeList.map((item)=>({
        storeCode: item.code,
        storeName: item.name,
        check: <input type='checkbox' />
    })))
  }
  },[storeList])


  useEffect(()=>{
    console.log(selectedStores);
  }, [selectedStores])

  const handleSubmit = e => {
    e.preventDefault();
    console.log("here", selectedDiscountType);
    if(selectedStores.includes(storeCode?.code)===false){
      console.log(storeCode?.code);
      selectedStores.push(storeCode?.code);
    }
    const arr1=[];
    if(selectedCategories1.length>0){selectedCategories1.map((item)=>{
      arr1.push(item.label)
      return true;
    })}
    const arr2=[];
    if(selectedCategories2.length>0){selectedCategories2.map((item)=>{
      arr2.push(item.label)
      return true;
    })}
    AuthAxios.post("/offer", {
      company_id: selectedCompany !== undefined ? selectedCompany : authTokens.data.company.id,
      store: selectedStores,
      type: "BXGY",
      y_type: selectedDiscountType,
      name: formValues.promoName,
      code: formValues.promoCode,
      start_date: startTime,
      end_date: endTime,
      qty_x: formValues.minQtyGx,
      qty_y: formValues.minQtyGy,
      sku_x: csv1.length>0 ? csv1 : skux,
      sku_y: csv2.length>0 ? csv2 : skuy,
      value: formValues.discountValue,
      tags_x: arr1,
      tags_y: arr2
    })
      .then(response => {
        if (response.status === 200) {
          setOpen(true);
          setOpen(true);
          setToastData(
            ToastConstant.DYNAMIC_SUCCESS(
              response.data.message,
              3000,
              "success"
            )
          );
        }
      })
      .catch(error => {
        setOpen(true);
        setOpen(true);
        setToastData(ToastConstant.DYNAMIC_FAIL(error.response.data.message, 3000, "error"));
      });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .form-group {
                width:30%;
                margin-bottom: 0;
            }
            .css-1s2u09g-control, .css-1pahdxg-control {
              min-height: 0;
              max-height: 40px;
            }
            .css-319lph-ValueContainer {
              padding: 0 8px;
            }
            .css-6j8wv5-Input {
              margin: 0;
            }
            .css-b62m3t-container{
            width: 100%;
          }
           `
        }}
      />
      <div className="create-promotion-step-container">
        <div className="create-promotion-step-header">
          <div className="back-button" onClick={() => backToPromotionCard()}>
            <Icon
              fontSize="small"
              color="inherit"
              style={{ cursor: "pointer" }}
            >
              arrow_back_ios
            </Icon>
          </div>
          Create Promotion: {promoTypeSelected}
        </div>
        <form
          className="create-promotion-form"
          id="createPromotion"
          noValidate
          autoComplete="off"
          autofill="off"
          onSubmit={handleSubmit}
        >
          <div className="create-promotion-section">
            <div className="form-group">
              <label>Store to create promotion</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  // onChange={handleChange}
                  className={`${formControl} form-read-only`}
                  name="storeName"
                  id="store-name"
                  value={storeCode?.name}
                  readOnly
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Promotion Id(Auto Generate)</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  // onChange={handleChange}
                  className={`${formControl} form-read-only`}
                  name="promoId"
                  id="promoId"
                  value=""
                  readOnly
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
          </div>

          <div className="create-promotion-section">
            <div className="form-group">
              <label>Promotion Name</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoName"
                  id="promoName"
                  value={formValues.promoName}
                  placeholder="Enter Details"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Promo Code</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoCode"
                  id="promoCode"
                  value={formValues.promoCode}
                  placeholder="If Any"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
          </div>

          <div className="create-promotion-section">
            <div className="form-group">
              <label>Promotion Start Date & Time</label>
              <div className="customer-input-area">
                {/* <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoStartDate"
                  id="promoStartDate"
                  value={formValues.promoStartDate}
                  style={{ paddingLeft: "0.75rem" }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label=" "
                    value={startTime}
                    onChange={handleChangeStartDate}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="form-group">
              <label>Promotion End Date & Time</label>
              <div className="customer-input-area">
                {/* <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoEndDate"
                  id="promoEndDate"
                  value={formValues.promoEndDate}
                  style={{ paddingLeft: "0.75rem" }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label=" "
                    value={endTime}
                    onChange={handleChangeEndDate}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <hr className="line" style={{ width: "100%" }} />

          <div className="product-selection">
            <h1>Product Selection Group 1</h1>
          </div>

          <div className="create-promotion-add-item-search-box">
            <div className="form-group">
              <label>Add Items</label>
              <div className="search-container">
                <input
                  type="text"
                  onChange={e => setSearchSKUGx(e.target.value)}
                  className={formControl}
                  name="name"
                  id="name"
                  placeholder="Search SKU"
                  value={searchSKUGx}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
                <img
                  src="./img/searchIcon.svg"
                  alt="search"
                  onClick={() => searchSKUGxHandler()}
                />
              </div>
            </div>

            {skuGxResultRow && skuGxResultRow.length > 0 && (
              <CustomTable
                data={skuGxResultRow}
                columns={productSelectionCol}
                hover={false}
                striped={false}
              />
            )}

            <div className="form-group">
              <label>Minimum Quantity</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="minQtyGx"
                  id="minQtyGx"
                  value={formValues.minQtyGx}
                  placeholder="Enter minimum quantity"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
          </div>

          <div className="bulk-file-upload-section">
            <div className="uplaod-img">
              <img src="/img/fileUpload.svg" alt="upload" />
            </div>
            <div className="upload-actions">
              <div className="upload-header">
                <h1>Drag and drop file here or</h1>
                <p>Accepted file formats: Excel, CSV</p>
              </div>
              <div className="upload-buttons">
                <button type="button" className="btn btn-browse-file" onClick={handleClick1}>
                  Browse Files
                </button>
                <input
              type="file"
              id="fileUpload1"
              ref={hiddenFileInput1}
              onChange={(e)=>{setFile1(e.target.files[0])}}
              style={{ display: "none" }}
              accept=".xlsx,.xls,.csv"
            />
                <button type="button" className="btn btn-download-file">
                  Download Sample
                </button>
              </div>
            </div>
          </div>

          <div className="product-selection">
            <h1>Or Select Categories</h1>
          </div>
          <div className="create-promotion-add-category">
          <CustomMultiSelect
              multi='true'
              value={selectedCategories1}
              options={options}
              handleChange={handleSelect1}
            />
            <span className="create-promotion-add-more-categories">
              +Add more categories
            </span>
          </div>

          <hr className="line" style={{ width: "100%" }} />

          <div className="product-selection">
            <h1>Product Selection Group 2</h1>
          </div>
          <div className="create-promotion-add-item-search-box">
            <div className="form-group">
              <label>Add Items</label>
              <div className="search-container">
                <input
                  type="text"
                  onChange={e => setSearchSKUGy(e.target.value)}
                  className={formControl}
                  name="name"
                  id="name"
                  placeholder="Search SKU"
                  value={searchSKUGy}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
                <img
                  src="./img/searchIcon.svg"
                  alt="search"
                  onClick={() => searchSKUGyHandler()}
                />
              </div>
            </div>
            {console.log(skuGyResult, "skuGyResult")}
            {skuGyResultRow && skuGyResultRow.length > 0 && (
              <CustomTable
                data={skuGyResultRow}
                columns={productSelectionCol}
                hover={false}
                striped={false}
              />
            )}
          </div>
          <div className="bulk-file-upload-section">
            <div className="uplaod-img">
              <img src="/img/fileUpload.svg" alt="upload" />
            </div>
            <div className="upload-actions">
              <div className="upload-header">
                <h1>Drag and drop file here or</h1>
                <p>Accepted file formats: Excel, CSV</p>
              </div>
              <div className="upload-buttons">
                <button type="button" className="btn btn-browse-file" onClick={handleClick2}>
                  Browse Files
                </button>
                <input
              type="file"
              id="fileUpload2"
              ref={hiddenFileInput2}
              onChange={(e)=>{setFile2(e.target.files[0])}}
              style={{ display: "none" }}
              accept=".xlsx,.xls,.csv"
            />
                <button type="button" className="btn btn-download-file">
                  Download Sample
                </button>
              </div>
            </div>
          </div>

          <div className="product-selection">
            <h1>Or Select Categories</h1>
          </div>
          <div className="create-promotion-add-category">
          <CustomMultiSelect
              multi='true'
              value={selectedCategories2}
              options={options}
              handleChange={handleSelect2}
            />
            <span className="create-promotion-add-more-categories">
              +Add more categories
            </span>
          </div>

          <div className="create-promotion-section">
            <div className="form-group">
              <label>Minimum Quantity</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="minQtyGy"
                  id="minQtyGy"
                  value={formValues.minQtyGy}
                  placeholder="Enter minimum quantity"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Discount Type</label>
              <div className="customer-input-area">
                <CustomSelectValue
                  list={discountTypeOptions}
                  selected={discountTypeOptions.find(
                    item => item.name === selectedDiscountType
                  )}
                  update={newValue => setSelectedDiscountType(newValue)}
                />
              </div>
            </div>
          </div>
          <div className="create-promotion-section">
            <div className="form-group">
              <label>Discount Value</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="discountValue"
                  id="discountValue"
                  value={formValues.discountValue}
                  placeholder="Enter discount value"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
          </div>

          <hr className="line" style={{ width: "100%" }} />

          <div
            className="other-store-promotion"
            onClick={() => setShowOtherStore(prevState => !prevState)}
          >
            {showOtherStore ?
              (<img src="/img/minus.svg" alt="" />):
              (<img src="/img/plus.svg" alt="" />)
              }
            <h1>Add promotion to other stores</h1>
          </div>

          {showOtherStore && (
            <>
              <div className="create-promotion-add-item-search-box">
                <div className="form-group">
                  <label>Search Stores</label>
                  <div className="search-container">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="name"
                      id="name"
                      placeholder="Search Stores"
                      // value={values.name}
                      required
                      style={{ paddingLeft: "0.75rem", border: "none" }}
                    />
                    <img src="./img/searchIcon.svg" alt="search" />
                  </div>
                </div>
              </div>
              <CustomTable
                data={StoreRow}
                columns={StoreCol}
                customCheck = {(row, e) => {
                  console.log(e);
                    if(e===true){
                      let arr=[];
                      arr=selectedStores;
                      arr.push(row.storeCode);
                      setSelectedStores(arr);
                    } else {
                      selectedStores.pop(row.storeCode);
                    }
                    console.log(selectedStores)
                  }
                }
                hover={false}
                striped={false}
              />
            </>
          )}

          <div className="create-promotion-save-button">
            <button type="submit" className="btn btn-create-promotion-save">
              Save
            </button>
            <button
              type="button"
              className="btn btn-create-promotion-cancel"
              onClick={() => backToPromotionCard()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default BXGY;
