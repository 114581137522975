/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";

import "./BrandCredential.css";
import LoginCredModal from "../LoginCredentialModal/LoginCredModal";
import AuthContext from "../../AuthContext/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EditStaff from "../StaffCreation/EditStaff/EditStaff";

const BrandCredential = (props) => {
  const p=props;  
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [createLogin, setCreateLogin] = useState(true);

  // const BrandCredentialCol = [
  //   { field: "registrationName", header: "Registration Name" },
  //   { field: "brandName", header: "Brand Name" },
  //   { field: "gstin", header: "GSTIN" },
  //   { field: "email", header: "Email" },
  //   { field: "button", header: "button" }
  // ];

  // const BrandCredentialRow = [
  //   {
  //     registrationName: "ColorBar Fashion",
  //     brandName: "ColorBar",
  //     gstin: "IN8374560328654243",
  //     email: "colorbar@fashion.com",
  //     button: "Create Login"
  //   },
  //   {
  //     registrationName: "ColorBar Fashion 2",
  //     brandName: "ColorBar 2",
  //     gstin: "IN8374560328654244",
  //     email: "colorbar2@fashion.com",
  //     button: "Reset Password"
  //   }
  // ];

  // const openModal = () => {};

  // New CHanges
  const formControl = "form-control";
  const [showEditScreen, setShowEditScreen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [induvidualUserData, setIndividualUserData] = useState([]);

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  useEffect(() => {
    (async () => {
      let response="";
      if(p.selectedCompany===undefined){
        response = await AuthAxios.get(`/users?company_id=${authTokens.data.company.id}`);
      }
      else{
        response = await AuthAxios.get(`/users?company_id=${p.selectedCompany}`);
      }
      
      if (response.status === 200) {
        setUserList(response.data.data);
      }
    })();
  }, [p.selectedCompany, showEditScreen]);

  const staffCol = [
    { field: "staffName", header: "Staff Name" },
    { field: "emailId", header: "Email" },
    { field: "contantNumber", header: "Contact" },
    { field: "store", header: "Store" },
    { field: "role", header: "Role" },
    { field: "status", header: "Status" },
    { field: "button", header: "button" }
  ];

  const [users, setUsers] = useState([]);

  useEffect(() => {
      setUsers(
        userList.map(user => ({
          staffName: user.name,
          emailId: user.email,
          contantNumber: user.mobile,
          store: user.stores[0]?.name,
          role: user.role,
          status: user.is_active === true ? "Active" : "Inactive",
          storeInfo: user.stores[0],
          button: "Edit"
        }))
      );
  }, [userList]);

  const editAction = user => {
    setIndividualUserData(user);
    setShowEditScreen(true);
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      {showEditScreen ? (
        <EditStaff
          setShowEditScreen={setShowEditScreen}
          induvidualUserData={induvidualUserData}
          selectedCompany={p.selectedCompany}
        />
      ) : (
        <div className="brand-credential-container">
          <div className="brand-credential-search-box">
            <div className="form-group">
              <div className="brand-credential-search">
                <input
                  type="text"
                  // onChange={handleChange}
                  className={formControl}
                  name="promoName"
                  id="promoName"
                  placeholder="Search Staff"
                  // value={values.promoName}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
              </div>
            </div>
          </div>

          <div className="brand-credential-table-section">
            <CustomTable
              data={users}
              columns={staffCol}
              customClassOne="brand-credential-create-login"
              buttonTypeOne="Edit"
              customData={user => editAction(user)}
              hover={false}
              striped={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BrandCredential;
