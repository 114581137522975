/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useRef, useState } from "react";

import "./BulkStaffCreation.css";

const BulkStaffCreation = () => {
  // File upload section
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    setFile(event.target.files[0]);
  };

  const handleReset = () => {
    setFile(null);
    document.getElementById("fileUpload").value = "";
  };

  const handleUploadFile = () => {
    console.log(file);
    if (file) {
      // HIT API

      // Run this code at the end of the successful file upload
      // document.getElementById("fileUpload").value = "";

      console.log(file.name);
    }
  };

  return (
    <>
      <div className="bulk-staff-creation-header">Upload staff sheet</div>
      <div className="bulk-staff-upload-section">
        <img src="/img/fileUpload.svg" alt="" />
        <div className="bulk-staff-upload-instruction">
          <div className="bulk-staff-upload-instruction-tag">
            <span className="bulk-staff-upload-file-upload-header">
              {file && file.name
                ? `${file.name}`
                : "Drag and drop file here or"}
            </span>
            <span className="bulk-staff-upload-accepted-format">
              Accepted file formats: Excel, CSV
            </span>
          </div>

          <div className="bulk-staff-upload-file-upload-buttons">
            <button
              type="button"
              className="btn btn-staff-upload-browse-file"
              onClick={handleClick}
            >
              Browse Files
            </button>
            <input
              type="file"
              id="fileUpload"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
              accept=".xlsx,.xls,.csv"
            />

            <button
              type="button"
              className="btn btn-bulk-staff-download-sample"
              //   onClick={() => handleReset()}
            >
              <a href="/somefile.txt" download>
                Download Sample
              </a>
            </button>
          </div>
        </div>
      </div>
      {file && (
        <div className="create-store-buttons">
          <button
            type="button"
            className="btn btn-create-store"
            onClick={() => handleUploadFile()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-create-store-reset"
            onClick={() => handleReset()}
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default BulkStaffCreation;
