/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomSelect from "components/CustomSelect/CustomSelect";
import React, { useContext, useEffect, useState } from "react";

import "./CreatePromotion.css";
import CreatePromotionSteps from "./CreatePromotionSteps";
import PromoCard from "./PromoCard";
import BOGO from "./PromotionType/BOGO/BOGO";
import BXGY from "./PromotionType/BXGY/BXGY";
import ComboOffer from "./PromotionType/ComboOffer/ComboOffer";
import FlatPrice from "./PromotionType/FlatPrice/FlatPrice";
import MoneyOff from "./PromotionType/MoneyOff/MoneyOff";
import PercentOff from "./PromotionType/PercentOff/PercentOff";

const CreatePromotion = (props) => {
  // Store list
  const p=props;
  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [stores, setStores]=useState([]);
  const [promoTypeSelectedId, setPromoTypeSelectedId] = useState("");
  const [promoTypeSelected, setPromoTypeSelected] = useState("");

  // helper function
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  useEffect(()=>{
    setSelectedStore('');
  }, [p.selectedCompany]);
  //  Store List api
  useEffect(() => {
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response = await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${p.selectedCompany}/store`)
      }
      else{
        response = await AuthAxios.get("/stores");
      }
      if (response.status === 200) {
        setStoreList(response.data.data);
        // setStockOverviewLoader(false);
      }
    })();
  }, [p.selectedCompany]);
  useEffect(()=>{
    console.log(storeList);
    setStores(
      storeList
    )
  }, [p.selectedCompany, storeList]);
  // selected Store information
  useEffect(() => {
    if (
      storeList &&
      storeList.length &&
      selectedStore &&
      selectedStore.length
    ) {
      setStoreCode(storeList.find(item => item.name === selectedStore));
    }
  }, [storeList, selectedStore]);

  // console.log(storeCode, "storeCode");

  // const [promotionOffer, setPromotionOffer] = useState("");
  // //  Offer Lists api
  // useEffect(() => {
  //   (async () => {
  //     const response = await AuthAxios.get("/offer");
  //     if (response.status === 200) {
  //       console.log(response);
  //       setPromotionOffer(response.data);
  //     }
  //   })();
  // }, []);

  const promotionType = [
    {
      id: "0a2",
      heading: "Money Off",
      subHeading:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet",
      cta: "Create"
    },
    {
      id: "0f2",
      heading: "Percent Off",
      subHeading:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet",
      cta: "Create"
    },
    {
      id: "03s",
      heading: "Flat Price",
      subHeading:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet",
      cta: "Create"
    },
    {
      id: "04g",
      heading: "Buy X Get Y",
      subHeading:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet",
      cta: "Create"
    },
    // {
    //   id: "05h",
    //   heading: "Buy One Get One",
    //   subHeading:
    //     "Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet",
    //   cta: "Create"
    // },
    // {
    //   id: "06s",
    //   heading: "Combo Offer",
    //   subHeading:
    //     "Lorem ipsum dolor sit amet, consectetuer adipiscing Lorem ipsum dolor sit amet",
    //   cta: "Create"
    // }
  ];

  useEffect(() => {
    setPromoTypeSelected(
      promotionType
        .filter(promo => promo.id === promoTypeSelectedId)
        .map(prom => prom.heading)
        .toString()
    );
  }, [promoTypeSelectedId]);

  const showPromo = () => {
    switch (promoTypeSelected) {
      case "Money Off":
        return (
          <MoneyOff
            promoTypeSelected={promoTypeSelected}
            setPromoTypeSelected={setPromoTypeSelected}
            promoTypeSelectedId={promoTypeSelectedId}
            setPromoTypeSelectedId={setPromoTypeSelectedId}
            storeCode={storeCode}
            selectedCompany={p.selectedCompany}
          />
        );

      case "Percent Off":
        return (
          <PercentOff
            promoTypeSelected={promoTypeSelected}
            setPromoTypeSelected={setPromoTypeSelected}
            promoTypeSelectedId={promoTypeSelectedId}
            setPromoTypeSelectedId={setPromoTypeSelectedId}
            storeCode={storeCode}
            selectedCompany={p.selectedCompany}
          />
        );

      case "Flat Price":
        return (
          <FlatPrice
            promoTypeSelected={promoTypeSelected}
            setPromoTypeSelected={setPromoTypeSelected}
            promoTypeSelectedId={promoTypeSelectedId}
            setPromoTypeSelectedId={setPromoTypeSelectedId}
            storeCode={storeCode}
            selectedCompany={p.selectedCompany}
          />
        );

      // case "Buy One Get One":
      //   return (
      //     <BOGO
      //       promoTypeSelected={promoTypeSelected}
      //       setPromoTypeSelected={setPromoTypeSelected}
      //       promoTypeSelectedId={promoTypeSelectedId}
      //       setPromoTypeSelectedId={setPromoTypeSelectedId}
      //       storeCode={storeCode}
      //       selectedCompany={p.selectedCompany}
      //     />
      //   );

      case "Buy X Get Y":
        return (
          <BXGY
            promoTypeSelected={promoTypeSelected}
            setPromoTypeSelected={setPromoTypeSelected}
            promoTypeSelectedId={promoTypeSelectedId}
            setPromoTypeSelectedId={setPromoTypeSelectedId}
            storeCode={storeCode}
            selectedCompany={p.selectedCompany}
          />
        );

      case "Combo Offer":
        return (
          <ComboOffer
            promoTypeSelected={promoTypeSelected}
            setPromoTypeSelected={setPromoTypeSelected}
            promoTypeSelectedId={promoTypeSelectedId}
            setPromoTypeSelectedId={setPromoTypeSelectedId}
            storeCode={storeCode}
            selectedCompany={p.selectedCompany}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .css-b62m3t-container{
            width: 100%;
          }
          `
        }}
      />

      <>
        {promoTypeSelected && promoTypeSelected.length > 0 ? (
          <>{showPromo()}</>
        ) : (
          <>
            <div className="select-store">Select store to create promotion</div>
            <div className="select-store-dropdown">
              <CustomSelect
                list={stores}
                selected={stores.find(item => item.name === selectedStore)}
                update={newValue => setSelectedStore(newValue)}
              />
            </div>
            {selectedStore && selectedStore.length > 0 && (
              <div className="promotion-type">
                {promotionType.map(promotionInformation => (
                  <PromoCard
                    promotionInformation={promotionInformation}
                    setPromoTypeSelectedId={setPromoTypeSelectedId}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </>

      {/* {!promoTypeSelectedId && !promoTypeSelectedId.length ? (
        <>
          <div className="select-store">Select store to create promotion</div>
          <div className="select-store-dropdown">
            <CustomSelect
              list={storeList}
              selected={storeList.find(item => item.name === selectedStore)}
              update={newValue => setSelectedStore(newValue)}
            />
          </div>
          {selectedStore && selectedStore.length > 0 && (
            <div className="promotion-type">
              {promotionType.map(promotionInformation => (
                <PromoCard
                  promotionInformation={promotionInformation}
                  setPromoTypeSelectedId={setPromoTypeSelectedId}
                />
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          {promoTypeSelected.map(selectedType => (
            <CreatePromotionSteps
              selectedType={selectedType}
              setPromoTypeSelected={setPromoTypeSelected}
              setPromoTypeSelectedId={setPromoTypeSelectedId}
              storeCode={storeCode}
            />
          ))}
        </>
      )} */}
    </>
  );
};

export default CreatePromotion;
