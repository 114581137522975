/* eslint-disable prettier/prettier */
const signUpFormValidate = values => {
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!values.username) {
    errors.username = "Username is required!";
  }
  if (!values.email) {
    errors.email = "Email is required!";
  } else if (!regex.test(values.email)) {
    errors.email = "This is not a valid email format!";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 4) {
    errors.password = "Password must be more than 4 characters";
  } else if (values.password.length > 10) {
    errors.password = "Password cannot exceed more than 10 characters";
  }
  return errors;
};

const forgotPasswordFormValidate = values => {
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!values.email) {
    errors.email = "Email is required!";
  } else if (!regex.test(values.email)) {
    errors.email = "This is not a valid email format!";
  }
  return errors;
};

const resetPasswordFormValidate = values => {
  const errors = {};
  if (!values.newPassword) {
    errors.newPassword = "New Password is required";
  } else if (values.newPassword.length < 4) {
    errors.newPassword = "Password must be more than 4 characters";
  } else if (values.newPassword.length > 10) {
    errors.newPassword = "Password cannot exceed more than 10 characters";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Confirm Password is required";
  } else if (values.confirmPassword.length < 4) {
    errors.confirmPassword = "Password must be more than 4 characters";
  } else if (values.confirmPassword.length > 10) {
    errors.confirmPassword = "Password cannot exceed more than 10 characters";
  }
  return errors;
};

const registerNewBrand = values => {
  const errors = {};
  if (!values.registeredName) {
    errors.registeredName = "Registered name is required";
  }
  if (!values.brandName) {
    errors.brandName = "Brand name is required";
  }
  if (!values.gstin) {
    errors.gstin = "GSTIN is required";
  }
  if (!values.cin) {
    errors.cin = "CIN is required";
  }
  if (!values.companyAddress) {
    errors.companyAddress = "Company Address is required";
  }
  // if (!values.pocName) {
  //   errors.pocName = "POC name is required";
  // }
  // if (!values.pocDesignation) {
  //   errors.pocDesignation = "POC designation is required";
  // }
  if (!values.pocNumber) {
    errors.pocNumber = "POC number is required";
  }
  // if (!values.pocEmail) {
  //   errors.pocEmail = "POC email-id is required";
  // }
  // if (!values.accountNumber) {
  //   errors.accountNumber = "Account number is required";
  // }
  // if (!values.confirmAccountNumber) {
  //   errors.confirmAccountNumber = "Confirm account number";
  // }
  // if (values.accountNumber !== values.confirmAccountNumber) {
  //   errors.confirmAccountNumber = "Account number not same";
  // }
  // if (!values.accountHolderName) {
  //   errors.accountHolderName = "Account holder name is required";
  // }
  // if (!values.ifscCode) {
  //   errors.ifscCode = "IFSC code is required";
  // }
  // if (!values.bankName) {
  //   errors.bankName = "Bank name is required";
  // }
  return errors;
};

const createStoreFormValidation = values => {
  const errors = {};
  if (!values.storeCode) {
    errors.storeCode = "Store code is required";
  }

  if (!values.storeName) {
    errors.storeName = "Store name is required";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone number is required";
  }

  if (!values.altPhoneNumber) {
    errors.altPhoneNumber = "Alt. phone number is required";
  }

  if (!values.pocName) {
    errors.pocName = "POC name is required";
  }

  if (!values.pocDesignation) {
    errors.pocDesignation = "POC designation is required";
  }

  if (!values.storeArea) {
    errors.storeArea = "Store Address is required";
  }

  if (!values.storeCity) {
    errors.storeCity = "Store Address is required";
  }

  if (!values.storeState) {
    errors.storeState = "Store Address is required";
  }

  if (!values.storePostal) {
    errors.storePostal = "Store Address is required";
  }

  if (!values.returnStoreArea) {
    errors.returnStoreArea = "Return Address is required";
  }

  if (!values.returnStoreCity) {
    errors.returnStoreCity = "Return Address is required";
  }

  if (!values.returnStoreState) {
    errors.returnStoreState = "Return Address is required";
  }

  if (!values.returnStorePostal) {
    errors.returnStorePostal = "Return Address is required";
  }

  if (!values.gstin) {
    errors.gstin = "GSTIN is required";
  }
  return errors;
};

const updateInventory = values => {
  const errors = {};
  if (!values.inventoryURL) {
    errors.inventoryURL = "image url is required";
  }
  return errors;
};

const createStaffAccount = values => {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = "Full name is required";
  }
  if (!values.emailId) {
    errors.emailId = "Email id is required";
  }
  if (!values.contactNumber) {
    errors.contactNumber = "Contact number is required";
  }
  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};

export default {
  signUpFormValidate,
  forgotPasswordFormValidate,
  resetPasswordFormValidate,
  registerNewBrand,
  updateInventory,
  createStoreFormValidation,
  createStaffAccount
};
