import React, { useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import axios from "axios";
// prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import { useMaterialUIController, setLayout } from "context";
import CustomSelectValue from "components/CustomSelect/CustomSelectValue";

function OuterContainer(props) {
  const a = props;
  const selectedCompany = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const [company, setCompany] = useState([]);
  const apiURL = "https://apis.fadoki.com/fadoki";
  const [userRole, setUserRole] = useState("");
  const { authTokens } = useContext(AuthContext);
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`,
    },
  });
  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);
  useEffect(() => {
    setUserRole(JSON.parse(localStorage.getItem("authTokens")).data.role);
  }, [localStorage.getItem("authTokens")]);
  useEffect(() => {
    (async () => {
      const response = await AuthAxios.get("/company");
      setCompany(response.data.data);
    })();
  }, []);
  console.log(company);
  console.log(userRole);
  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <div>
        <CustomSelectValue
          // value={company}
          list={company}
          selected={company.find((item) => item.id === selectedCompany)}
          update={(newValue) => {
            a.setSelectedCompany(newValue);
          }}
        />
      </div>
      {a.children}
    </MDBox>
  );
}

export default OuterContainer;
