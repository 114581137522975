/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React from "react";
import "./CustomTable.css";

const CustomTable = ({
  data = null,
  columns = null,
  hover = true,
  striped = true,
  customClassOne = null,
  customClassTwo = null,
  buttonTypeOne = null,
  buttonTypeTwo = null,
  customAction = null,
  customData = null,
  customCheck = null
}) => {
  const getCaps = (head, field) => {
    if (head) return head.toUpperCase();
    return field.toUpperCase();
  };

  const handleModalContent = (e, data) => {
    customData(data);
    customAction(prevState => !prevState);
  };

  return (
    <div className="table-structure">
      <table>
        <thead>
          <tr>
            {columns &&
              columns
                .filter(head => head.header !== "button")
                .map(head => <th>{getCaps(head.header, head.field)}</th>)}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map(row => (
              <tr
                className={`${hover && "hover"} ${striped && "striped"}`}
                // onClick={e => handleModalContent(e, row)}
              >
                {columns.map(col =>
                  col.field === "button" ? (
                    <div className="button-space">
                      <button
                        className={`${
                          row[col.field].includes(buttonTypeOne)
                            ? customClassOne
                            : row[col.field].includes(buttonTypeTwo)
                            ? customClassTwo
                            : customClassTwo
                        }`}
                        onClick={e => handleModalContent(e, row)}
                      >
                        {row[col.field]}
                      </button>
                    </div>
                  ) : (
                    col.field === "customButton" ? (<td role="presentation"// eslint-disable-line jsx-a11y/no-static-element-interactions
                      onClick={e => handleModalContent(e, row)}>{row[col.field]}</td>):
                    ( 
                      col.field === "check" ? 
                      (<td role="presentation" onClick={e=>{customCheck(row, e.target.checked)}}> {row[col.field]}</td>):
                    (<td>{row[col.field]}</td>)
                  )
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {data ? null : <p>No Data</p>}
    </div>
  );
};

export default CustomTable;
