/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomTable from "components/CustomTable/CustomTable";

import "./ViewPromotion.css";
import CustomSelect from "components/CustomSelect/CustomSelect";

const ViewPromotion = (props) => {
  const p=props;
  const formControl = "form-control";

  const [offer, setOffer] = useState([]);
  const [offerRow, setOfferRow] = useState([]);

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  console.log(offer, "offer");

  useEffect(() => {
    setOfferRow(
      offer.map(item => ({
        promoId: item.offerId,
        // storeName: item.contactNumber,
        // promoName: item.alternate_contact_number,
        startDate: dayjs(item.start_date).format("DD/MM/YYYY"),
        endDate: dayjs(item.end_date).format("DD/MM/YYYY"),
        // promoCode: item.poc,
        promoType: item.type,
        discountValue: item.value,
        MinQty: item.qty_x
        // status: item.poc
      }))
    );
  }, [offer]);

  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [stores, setStores]=useState([]);
  const [storeCode, setStoreCode] = useState("");

  useEffect(() => {
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response = await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${p.selectedCompany}/store`)
      }
      else{
        response = await AuthAxios.get("/stores");
      }
      if (response.status === 200) {
        setStoreList(response.data.data);
        // setStockOverviewLoader(false);
      }
    })();
  }, [p.selectedCompany]);
  useEffect(()=>{
    console.log(storeList);
    setStores(
      storeList
    )
  }, [p.selectedCompany, storeList]);

  useEffect(() => {
    if (
      storeList &&
      storeList.length &&
      selectedStore &&
      selectedStore.length
    ) {
      setStoreCode(storeList.find(item => item.name === selectedStore).code);
    }
  }, [storeList, selectedStore]);

  useEffect(() => {
    (async () => {
      console.log(storeCode)
      let response='';
      if(authTokens.data.role===4){
        response = await AuthAxios.get(`/offer?company_id=${p.selectedCompany}&store=${storeCode}`);
      } else {
        response = await AuthAxios.get(`/offer?company_id=${authTokens.data.company.id}&store=${storeCode}`);
      }
      
      if (response.status === 200) {
        setOffer(response.data.data);
      }
    })();
  }, [storeCode, p.selectedCompany]);

  const PromoTableCol = [
    { field: "promoId", header: "Promo Id" },
    // { field: "storeName", header: "Store Name" },
    // { field: "promoName", header: "Promo Name" },
    { field: "startDate", header: "Start Date" },
    { field: "endDate", header: "End Date" },
    // { field: "promoCode", header: "Promo Code" },
    { field: "promoType", header: "Promo Type" },
    { field: "discountValue", header: "Discount Value" },
    { field: "MinQty", header: "Min. Qty" }
    // { field: "status", header: "Status" }
  ];

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="view-promotion-container">
        <div className="view-promotion-header">
          <div className="view-promotion-search-box">
            <div className="form-group">
              <div className="view-promotion-search">
                <input
                  type="text"
                  // onChange={handleChange}
                  className={formControl}
                  name="promoName"
                  id="promoName"
                  placeholder="Search Promo Name"
                  // value={values.promoName}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="view-promotion-filters-list">
            <span className="all-store"> 
              <CustomSelect
                list={stores}
                selected={stores.find(item => item.name === selectedStore)}
                update={newValue => setSelectedStore(newValue)}
              />
            </span>
            <div className="all-store-filter">
              <span className="all-store">Promotion Type &#9660;</span>
            </div>
            <div className="all-store-filter">
              <span className="all-store">Status &#9660;</span>
            </div>
            <div className="all-store-filter">
              <span className="all-store">Sort By &#9660;</span>
            </div>
          </div>
        </div>
        <div className="view-promotion-table-section">
          <CustomTable
            data={offerRow}
            columns={PromoTableCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default ViewPromotion;
