/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomToast from "utlis/Toast/CustomToast";
import { ToastConstant } from "utlis/Toast/toastConstant";
import AuthContext from "../Authentication/AuthContext/AuthContext";
import "./DetailsTable.css";

const DetailsTable = () => {
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  const formControl = "form-control";
  const navigate = useNavigate();

  // toast
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  // Search Customer
  const [searchCustomer, setSearchCustomer] = useState();

  // Track Order
  const [searchOrder, setSearchOrder] = useState();

  const searchCustomerHandler = async () => {
    if (searchCustomer && searchCustomer.length > 0) {
      // api hit here
      // if success
      const response = await AuthAxios.get(`https://apis.fadoki.com/merchant/customer?mobile=${searchCustomer}`);
      console.log(response);
      if(response.data.customer.length>0){
        navigate(`/dashboard/customer-search/${searchCustomer}`);
      } else {
        setOpen(true);
        setToastData(
          ToastConstant.DYNAMIC_ERROR(
                `Customer not found`,
                3000,
                "error"
              )
            );
      }
    }
  };

  const searchOrderHandler = async () => {
    if (searchOrder && searchOrder.length > 0) {
      // api hit here
      // if success
      const response = await AuthAxios.get(`https://apis.fadoki.com/merchant/order?orderId=${searchOrder}`);
      console.log(response);
      if(response.data.orders.length>0){
        navigate(`/dashboard/track-order/${searchOrder}`);
      } else {
        setOpen(true);
        setToastData(
          ToastConstant.DYNAMIC_ERROR(
                `Order ID not found`,
                3000,
                "error"
              )
            );
      }
    }
  };

  return (
    <>
      <div className="details-table">
        <div className="details-table-header">Get Details</div>
        <hr className="line" />
        <form
          className="details-table-form"
          id="detailsTable"
          noValidate
          autoComplete="off"
          autofill="off"
        >
          <div className="details-table-search-customer">
            <div className="form-group">
              <label>Search Customer</label>
              <div className="search-container">
                <input
                  type="text"
                  onChange={e => setSearchCustomer(e.target.value)}
                  className={formControl}
                  name="name"
                  id="name"
                  value={searchCustomer}
                  required
                  style={{ paddingLeft: "0.75rem" }}
                />
                <img
                  src="./img/searchIcon.svg"
                  alt="search"
                  onClick={() => searchCustomerHandler()}
                />
              </div>
            </div>
          </div>
          <div className="details-table-search-customer">
            <div className="form-group">
              <label>Search Order</label>
              <div className="search-container">
                <input
                  type="text"
                  onChange={e => setSearchOrder(e.target.value)}
                  className={formControl}
                  name="name"
                  id="name"
                  value={searchOrder}
                  required
                  style={{ paddingLeft: "0.75rem" }}
                />
                <img
                  src="./img/searchIcon.svg"
                  alt="search"
                  onClick={() => searchOrderHandler()}
                />
              </div>
            </div>
          </div>
          {/* <div className="details-table-search-customer">
            <div className="form-group">
              <label>Track Order</label>
              <div className="search-container">
                <input
                  type="text"
                  // onChange={handleChange}
                  className={formControl}
                  name="name"
                  id="name"
                  // value={values.name}
                  required
                  style={{ paddingLeft: "0.75rem" }}
                />
                <img src="./img/searchIcon.svg" alt="search" />
              </div>
            </div>
          </div> */}
        </form>
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default DetailsTable;
