/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import { useMaterialUIController } from "context";
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import brandImage from "assets/images/logos/Fadoki.png";
import Sidenav from "examples/Sidenav";
import Dashboard from "components/customCoponents/Dashboard/Dashboard";
import SingleOrderDetail from "components/customCoponents/CustomerOrderDetail/SingleOrderDetail";
import StoreList from "components/customCoponents/StoreManagement/StoreList/StoreList";
import Promotion from "components/customCoponents/Promotion/Promotion";
import BrandRegistration from "components/customCoponents/BrandRegistration/BrandRegistration";
import BrandLoginCredential from "components/customCoponents/Authentication/LoginCreation/BrandLoginCredential";
import Reports from "components/customCoponents/Reports/Reports";
import Support from "components/customCoponents/Support/Support";
import InventoryManagement from "components/customCoponents/InventoryManagement/InventoryManagement";
import OrderManagement from "components/customCoponents/OrderManagement/OrderManagement";
import CatalogManagement from "components/customCoponents/Catalog Management/CatalogManagement";
import CustomerDetailsInfo from "components/customCoponents/CustomerDetails/CustomerDetailsInfo";
import LogOut from "./LogOut";
import OuterContainer from "examples/LayoutContainers/OuterContainer";

const PrivateRoutes = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [selectedCompany, setSelectedCompany]=useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    setUserRole(JSON.parse(localStorage.getItem("authTokens")).data.role);
  }, [localStorage.getItem("authTokens")]);

  const pathArrayPrivateSelective = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <img src="/img/sidebar/storeManagement.svg" alt="" />,
      route: "/dashboard",
      component: <Dashboard />
    },
    {
      type: "collapse",
      name: "Store Management",
      key: "store-management",
      icon: <img src="/img/sidebar/storeManagement.svg" alt="" />,
      route: "/store-management",
      component: <StoreList />
    },
    {
      type: "collapse",
      name: "Order Management",
      key: "order-management",
      icon: <img src="/img/sidebar/orderManagement.svg" alt="" />,
      route: "/order-management",
      component: <OrderManagement />
    },
    {
      type: "collapse",
      name: "Promotion",
      key: "promotion",
      icon: <img src="/img/sidebar/promotion.svg" alt="" />,
      route: "/promotion",
      component: <Promotion />
    },
    {
      type: "collapse",
      name: "Catalog Management",
      key: "catalog-management",
      icon: <img src="/img/sidebar/catalogManagement.svg" alt="" />,
      route: "/catalog-management",
      component: <CatalogManagement />
    },
    {
      type: "collapse",
      name: "Inventory Management",
      key: "inventory-management",
      icon: <img src="/img/sidebar/inventoryManagement.svg" alt="" />,
      route: "inventory-management",
      component: <InventoryManagement />
    },
    {
      type: "collapse",
      name: "Staff Management",
      key: "staff-management",
      icon: <img src="/img/sidebar/loginStaffCreation.svg" alt="" />,
      route: "staff-management",
      component: <BrandLoginCredential />
    },
    {
      type: "collapse",
      name: "Reports",
      key: "reports",
      icon: <img src="/img/sidebar/reports.svg" alt="" />,
      route: "reports",
      component: <Reports />
    },
    {
      type: "collapse",
      name: "Support",
      key: "support",
      icon: <img src="/img/sidebar/support.svg" alt="" />,
      route: "support",
      component: <Support />
    },
    {
      type: "collapse",
      name: "Logout",
      key: "logout",
      icon: <img src="/img/sidebar/support.svg" alt="" />,
      route: "logout",
      component: <LogOut />
    }
  ];

  const pathArrayPrivate = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <img src="/img/sidebar/storeManagement.svg" alt="" />,
      route: "/dashboard",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><Dashboard selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Store Management",
      key: "store-management",
      icon: <img src="/img/sidebar/storeManagement.svg" alt="" />,
      route: "/store-management",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><StoreList selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Order Management",
      key: "order-management",
      icon: <img src="/img/sidebar/orderManagement.svg" alt="" />,
      route: "/order-management",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><OrderManagement selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Promotion",
      key: "promotion",
      icon: <img src="/img/sidebar/promotion.svg" alt="" />,
      route: "/promotion",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><Promotion selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Catalog Management",
      key: "catalog-management",
      icon: <img src="/img/sidebar/catalogManagement.svg" alt="" />,
      route: "/catalog-management",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><CatalogManagement selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Inventory Management",
      key: "inventory-management",
      icon: <img src="/img/sidebar/inventoryManagement.svg" alt="" />,
      route: "inventory-management",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><InventoryManagement selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Company Registration",
      key: "company-registration",
      icon: <img src="/img/sidebar/brandRegistration.svg" alt="" />,
      route: "company-registration",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><BrandRegistration selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Staff Management",
      key: "staff-management",
      icon: <img src="/img/sidebar/loginStaffCreation.svg" alt="" />,
      route: "staff-management",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><BrandLoginCredential selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Reports",
      key: "reports",
      icon: <img src="/img/sidebar/reports.svg" alt="" />,
      route: "reports",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><Reports selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Support",
      key: "support",
      icon: <img src="/img/sidebar/support.svg" alt="" />,
      route: "support",
      component: <><OuterContainer setSelectedCompany={setSelectedCompany}><Support selectedCompany={selectedCompany}/></OuterContainer></>
    },
    {
      type: "collapse",
      name: "Logout",
      key: "logout",
      icon: <img src="/img/sidebar/support.svg" alt="" />,
      route: "logout",
      component: <LogOut />
    }
  ];

  const pathArrayPrivateRole2 = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      icon: <img src="/img/sidebar/storeManagement.svg" alt="" />,
      route: "/dashboard",
      component: <Dashboard />
    },
    {
      type: "collapse",
      name: "Order Management",
      key: "order-management",
      icon: <img src="/img/sidebar/orderManagement.svg" alt="" />,
      route: "/order-management",
      component: <OrderManagement />
    },
    {
      type: "collapse",
      name: "Promotion",
      key: "promotion",
      icon: <img src="/img/sidebar/promotion.svg" alt="" />,
      route: "/promotion",
      component: <Promotion />
    },
    {
      type: "collapse",
      name: "Catalog Management",
      key: "catalog-management",
      icon: <img src="/img/sidebar/catalogManagement.svg" alt="" />,
      route: "/catalog-management",
      component: <CatalogManagement />
    },
    {
      type: "collapse",
      name: "Inventory Management",
      key: "inventory-management",
      icon: <img src="/img/sidebar/inventoryManagement.svg" alt="" />,
      route: "inventory-management",
      component: <InventoryManagement />
    },
    {
      type: "collapse",
      name: "Reports",
      key: "reports",
      icon: <img src="/img/sidebar/reports.svg" alt="" />,
      route: "reports",
      component: <Reports />
    },
    {
      type: "collapse",
      name: "Support",
      key: "support",
      icon: <img src="/img/sidebar/support.svg" alt="" />,
      route: "support",
      component: <Support />
    },
    {
      type: "collapse",
      name: "Logout",
      key: "logout",
      icon: <img src="/img/sidebar/support.svg" alt="" />,
      route: "logout",
      component: <LogOut />
    }
  ];

  const getRoutes = allRoutes =>
    allRoutes.map(route => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={brandImage}
        brandName="Fadoki"
        routes={userRole === 4 ? pathArrayPrivate : (userRole===2 ? pathArrayPrivateRole2 : pathArrayPrivateSelective)}
        // onMouseEnter={handleOnMouseEnter}
        // onMouseLeave={handleOnMouseLeave}
      />
      <Routes>
        {getRoutes(
          userRole === 4 ? pathArrayPrivate : pathArrayPrivateSelective
        )}
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard/customer-search/:customerId"
          element={<CustomerDetailsInfo />}
        />
        <Route
          path="/dashboard/track-order/:orderId"
          element={<SingleOrderDetail />}
        />
      </Routes>
    </>
  );
};

export default PrivateRoutes;
