/* eslint-disable consistent-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import AuthContext from "../Authentication/AuthContext/AuthContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PendingOrder from "./PendingOrder/PendingOrder";
import DeliveredOrder from "./DeliveredOrder/DeliveredOrder";
import Logistics from "./Logistics/Logistics";
import "./OrderManagement.css";
import RefundTransaction from "./RefundTransaction/RefundTransaction";

const OrderManagement = () => {
  const [isQuickFilter, setIsQuickFilter] = useState(false);
  const [selectedType, setSelectedType] = useState(
    sessionStorage.getItem("orderManagementFilter") || "pendingOrder"
  );
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);

  useEffect(() => {
    if (sessionStorage.getItem("orderManagementFilter") === null) {
      sessionStorage.setItem("orderManagementFilter", "pendingOrder");
    }
  }, []);

  const orderManagementHeader = [
    {
      id: 1,
      name: "Pending Orders",
      type: "pendingOrder"
    },
    {
      id: 2,
      name: "Delivered Orders",
      type: "deliveredOrder"
    },
    {
      id: 3,
      name: "Assigned Logistics",
      type: "assignedLogistics"
    },
    {
      id: 4,
      name: "Refund Transaction",
      type: "refundTransaction"
    }
  ];

  const handleHeaderFilterClick = isQuickFilter
    ? orderManagementHeader
    : [
        ...new Set([
          orderManagementHeader[0],
          _.find(orderManagementHeader, item => item.type === selectedType),
          ...orderManagementHeader
        ])
      ];

  const updateHeaderList = (e, isQuickFilter) => {
    if (e === selectedType) {
      return;
    }
    sessionStorage.setItem("orderManagementFilter", e);
    setSelectedType(e);
    setIsQuickFilter(isQuickFilter);
  };

  const showTab = () => {
    switch (sessionStorage.getItem("orderManagementFilter")) {
      case "pendingOrder":
        return <PendingOrder />;
      case "deliveredOrder":
        return <DeliveredOrder />;
      case "assignedLogistics":
        return <Logistics />;
      case "refundTransaction":
        return <RefundTransaction />;
      default:
        break;
    }
  };

  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="order-management-container">
          <div className="order-management-header">
            <div className="order-management-header-tabs">
              {handleHeaderFilterClick.map((item) => (
                <div
                  key={item.id}
                  value={item?.type}
                  onClick={() => {
                    updateHeaderList(item?.type, true);
                  }}
                  className="order-management-tab-info"
                >
                  <h1
                    className={`${
                      selectedType === item?.type ? "selected-tab-line" : ""
                    }`}
                  >
                    {item?.name}
                  </h1>
                </div>
              ))}
            </div>
            <div className="order-management-header-filter">
              <div className="order-management-filters-list">
                <div className="order-management-filter">
                  <span className="order-management-individual-filter">
                    Today &#9660;
                  </span>
                </div>
                <div className="order-management-filter">
                  <span className="order-management-individual-filter">
                    All Stores &#9660;
                  </span>
                </div>
                <div className="order-management-filter">
                  <span className="order-management-individual-filter">
                    All City &#9660;
                  </span>
                </div>
              </div>
            </div>
          </div>
          <hr className="line" />
          {showTab()}
        </div>
      </DashboardLayout>
    </>
  );
};

export default OrderManagement;
