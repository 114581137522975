/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React from "react";

import "./PromoCard.css";

const PromoCard = ({ promotionInformation, setPromoTypeSelectedId }) => {
  const handlePromo = promo => {
    setPromoTypeSelectedId(promo.id);
  };

  return (
    <>
      <div
        className="promo-card"
        onClick={() => handlePromo(promotionInformation)}
      >
        <div className="promo-heading">{promotionInformation.heading}</div>
        <div className="promo-sub-heading">
          {promotionInformation.subHeading}
        </div>
        <div className="cta-button">{promotionInformation.cta}</div>
      </div>
    </>
  );
};

export default PromoCard;
