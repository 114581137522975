/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";

const PurchaseTable = ({ userData }) => {
  const [tableR, setTableR] = useState([]);

  const tableCol = [
    { header: "Order Id", field: "orderId" },
    { header: "Store Name", field: "storeName" },
    { header: "SKU", field: "sku" },
    { header: "Product Details", field: "productDetails" },
    // { header: "Size", field: "size" },
    // { header: "Color", field: "color" },
    { header: "Price", field: "price" },
    { header: "Qty", field: "qty" },
    { header: "Status", field: "status" }
  ];

  const tableRow = [
    {
      orderId: 1234565,
      store: "Colorbar, HSR",
      sku: 8475,
      productDetails: "Sinful Matte Lipcolor +3 more items",
      attribute1: "XL",
      attribute2: "Yellow",
      attribute3: "a",
      price: 1000,
      qty: 1,
      qtyFulfilled: 1,
      status: "Delivered"
    }
  ];

  useEffect(() => {
    console.log(userData)
      if (Object.keys(userData).length>0) {
        console.log('here2')
        setTableR(
          Object.values(userData.orders).map(item => ({
            orderId: item.order_id,
            storeName: item.store.name,
            sku: 0,
            productDetails: 'a',
            // size: item.size,
            // color: item.color,
            price: item.amount,
            qty: '0',
            status: item.status
          }))
        );
      }
  }, [userData]);

  useEffect(()=>{console.log(tableR)}, [tableR])

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="customer-details-container" style={{ marginTop: "2rem" }}>
        <div className="details-table-header">Purchases</div>
        <div className="existing-brand-table-section">
          <CustomTable
            data={tableR}
            columns={tableCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default PurchaseTable;
