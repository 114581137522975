/* eslint-disable no-constant-condition */
/* eslint-disable no-cond-assign */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomTable from "components/CustomTable/CustomTable";
import React, { useContext, useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';

import "./ExistingBrand.css";
import EditBrand from "../EditBrand/EditBrand";

const ExistingBrand = (props) => {
  const p=props
  const formControl = "form-control";
  const [existingBrands, setExistingBrands] = useState([]);
  const [existingBrandsRow, setExistingBrandsRow] = useState([]);
  const [brandEditData, setBrandEditData] = useState([]);
  const [showEditScreen, setShowEditScreen] = useState(false);
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/fadoki";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  const ExistingBrandCol = [
    { field: "is_active", header: 'Is Active' },
    { field: "registrationName", header: "Registered Name" },
    { field: "brandName", header: "Brand Name" },
    { field: "gstin", header: "GSTIN" },
    { field: "segment", header: "Segment" },
    { field: "pocName", header: "POC Name" },
    { field: "pocNumber", header: "POC Number" },
    { field: "pocEmail", header: "POC Email" },
    { field: "accountNumber", header: "Account Number" },
    { field: "accountHolder", header: "Account Holder" },
    { field: "ifsc", header: "IFSC" },
    { field:'customButton', header:'Edit' }
  ];

  useEffect(() => {
    (async () => {
      const response = await AuthAxios.get("/company");
      if ((response.status = 200)) {
        setExistingBrands(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    setExistingBrandsRow(
      Object.values(existingBrands).map(brand => ({
        is_active : brand.is_active ? 'YES' : 'NO',
        registrationName: brand.registered_name,
        brandName: brand.name,
        gstin: brand.gstin,
        segment: brand.segment,
        pocName: brand.poc,
        pocNumber: brand.contact_number,
        pocEmail: brand.poc_email,
        accountNumber: brand.account_number,
        accountHolder: brand.account_holder_name,
        ifsc: brand.ifsc,
        company: brand.id,
        address: brand.address,
        cin: brand.cin,
        customButton: <EditIcon fontsize="medium" />
      }))
    );
  }, [existingBrands]);
  const editAction = store => {
    // console.log(store);
    setBrandEditData(store);
    setShowEditScreen(true);
    // console.log(storeEditData);
  };
  console.log(existingBrands, "existingBrandss");

  useEffect(()=>{
    if(!showEditScreen){
      (async () => {
        const response = await AuthAxios.get("/company");
        if ((response.status = 200)) {
          setExistingBrands(response.data.data);
        }
      })();
    }
  }, [showEditScreen])

  return (
    <>
    {showEditScreen ? 
    (
    // <div />
    <EditBrand 
      setShowEditScreen={setShowEditScreen} 
      brandEditData={brandEditData} 
      // selectedCompany={p.selectedCompany}
    />
    ):
      (<>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="existing-brand-container">
        <div className="existing-brand-search-box">
          <div className="form-group">
            <div className="existing-brand-search">
              <input
                type="text"
                // onChange={handleChange}
                className={formControl}
                name="brandName"
                id="brandName"
                placeholder="Search Brand Name"
                // value={values.brandName}
                required
                style={{ paddingLeft: "0.75rem", border: "none" }}
              />
            </div>
          </div>
        </div>

        <div className="existing-brand-table-section">
          <CustomTable
            data={existingBrandsRow}
            columns={ExistingBrandCol}
            customData={brand=>editAction(brand)}
            hover={false}
            striped={false}
          />
        </div>
      </div>
      </>
    )}
    </>
  );
};

export default ExistingBrand;
