/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomTable from "components/CustomTable/CustomTable";
import React, { useContext, useEffect, useState } from "react";

import "./PendingOrder.css";

const PendingOrder = (props) => {
  const p=props;
  const formControl = "form-control";
  const [enteredSearchOrderId, setEnteredSearchOrderId] = useState("");
  const [searchedOrderId, setSearchedOrderedId] = useState([]);

  //
  const [pendingOrderData, setPendingOrderData] = useState([]);
  const [pendingOrderRow, setPendingOrderRow] = useState([]);

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  useEffect(() => {
    (async () => {
      const response = await AuthAxios.get("/orders?type=PENDING");
      if (response.status === 200) {
        setPendingOrderData(response.data.orders);
      }
    })();
  }, []);

  const ExistingBrandCol = [
    { field: "oredrId", header: "Order Id" },
    { field: "sku", header: "SKU" },
    { field: "productDetails", header: "Product Details" },
    { field: "size", header: "Size" },
    { field: "color", header: "Color" },
    { field: "price", header: "Price" },
    { field: "quantity", header: "Qty" },
    // { field: "refundAmount", header: "Refund Amount" },
    { field: "status", header: "Status" }
  ];

  useEffect(() => {
    console.log(pendingOrderData);
    if (pendingOrderData[0]) {
      setPendingOrderRow(
        Object.values(pendingOrderData[0]?.items).filter((item)=>{
          if(item.company===p.selectedCompany){
            return true;
          }
          return false;})
          .map(item => ({
          oredrId: pendingOrderData[0]?.order_id,
          sku: item.sku,
          productDetails: item.name,
          size: item.sizes,
          color: item.colors,
          price: item.sp,
          quantity: item.quantity,
          // refundAmount: item.type,
          status: pendingOrderData[0]?.status
        }))
      );
    }
  }, [pendingOrderData[0]]);

  const handleChange = searchedItem => {
    setEnteredSearchOrderId(searchedItem);
    // filter api here
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          .form-group {
            width: 100%;
            margin-bottom: 0;
          }
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="pending-order-container">
        <div className="pending-order-header">
          <div className="pending-order-search-box">
            <div className="form-group">
              <div className="pending-order-search">
                <input
                  type="text"
                  onChange={e => handleChange(e.target.value)}
                  className={formControl}
                  name="orderId"
                  id="orderId"
                  placeholder="Search order id"
                  value={enteredSearchOrderId}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
              </div>
            </div>
          </div>
          <div className="pending-order-filter">
            <div className="pending-order-header-filter">
              <div className="pending-order-filters-list">
                <div className="pending-order-filter-tab">
                  <span className="pending-order-individual-filter">
                    Sort By &#9660;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pending-order-table-section">
          <CustomTable
            data={pendingOrderRow}
            columns={ExistingBrandCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default PendingOrder;
