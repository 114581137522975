/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React from "react";
import ReactSelect from "react-select";

const CustomSelect = ({ list, selected, update }) => (
  <ReactSelect
    value={selected}
    options={list}
    isSearchable={false}
    formatOptionLabel={list => (
      <div>
        <span>{list.name}</span>
      </div>
    )}
    onChange={value => update(value.name)}
  />
);

export default CustomSelect;
