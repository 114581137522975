/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import React, { useContext, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import AuthContext from "../Authentication/AuthContext/AuthContext";
import UpdateInventory from "./UpdateInventory/UpdateInventory";
import StockOverview from "./StockOverview/StockOverview";
import UploadHistroy from "./UploadHistory/UploadHistory";
import "./InventoryManagement.css";

const InventoryManagement = (props) => {
  const p=props;
  console.log(p.selectedCompany);
  const [updateInventorySection, setUpdateInventorySection] = useState(true);
  const [uploadHistory, setUploadHistory] = useState(false);
  const [stockOverview, setStockOverview] = useState(false);
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="inventory-management-container">
          <div className="inventory-management-header">
            <div
              className={`inventory-management-tab ${
                updateInventorySection ? "selected-tab selected-tab-line" : ""
              } `}
              onClick={() => {
                setUpdateInventorySection(true);
                setUploadHistory(false);
                setStockOverview(false);
              }}
            >
              Update Inventory
            </div>
            <div
              className={`inventory-management-tab ${
                uploadHistory ? "selected-tab selected-tab-line" : ""
              } `}
              onClick={() => {
                setUploadHistory(true)
                setUpdateInventorySection(false);
                setStockOverview(false);
              }}
            >
              Upload History
            </div>
            <div
              className={`inventory-management-tab ${
                stockOverview ? "selected-tab selected-tab-line" : ""
              }`}
              onClick={() => {
                setStockOverview(true);
                setUploadHistory(false);
                setUpdateInventorySection(false);
              }}
            >
              Stock Overview
            </div>
          </div>
          <hr className="line" />
          {updateInventorySection ===true ? (
            <UpdateInventory selectedCompany={p.selectedCompany}/>
          ) : (
          uploadHistory===true? 
          (
            <UploadHistroy selectedCompany={p.selectedCompany} />
          ):(
          <StockOverview selectedCompany={p.selectedCompany}/>)  
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default InventoryManagement;
