/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React from "react";
import Select from "react-select";

const CustomMultiSelect = props => {

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 300,
    }),
    menu: () => ({ boxShadow: 'inset 0 2px 0 rgba(0, 0, 0, 0.1)' }),
  };

  const options = props.multi
    ? [{ label: "Select All", value: "all" }, ...props.options]
    : props.options;
  console.log(options);
  return (
    <div className={`react-select-wrapper ${props.multi ? "multi" : ""}`}>
      <Select
        // closeMenuOnSelect={false}
        className="basic-multi-select"
        classNamePrefix="select"
        name="example"
        options={options}
        isMulti
        styles={selectStyles}
        // value={options.value}
        onChange={selected => {
          props.multi &&
          selected.length &&
          selected.find(option => option.value === "all")
            ? props.handleChange(options.slice(1))
            : !props.multi
            ? props.handleChange((selected && selected.value) || null)
            : props.handleChange(selected);
        }}
      />
    </div>
  );
};

export default CustomMultiSelect;
