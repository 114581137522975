/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import CustomerDetails from "./CustomerDetails";
import PurchaseTable from "../PurchaseTable/PurchaseTable";
import AuthContext from "../Authentication/AuthContext/AuthContext";

const CustomerDetailsInfo = () => {
  const [userData, setUserData] = useState([]);
  const params = useParams();

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  useEffect(() => {
    if (params.customerId) {
      (async () => {
        const response = await AuthAxios.get(
          `/customer?mobile=${params.customerId}`
        );
        if (response.status === 200) {
          setUserData(response.data);
          console.log(response.data);
        }
      })();
    }
  }, [params.customerId]);

  return (
    <>
      <DashboardLayout>
        <CustomerDetails userData={userData} />
        <PurchaseTable userData={userData} />
      </DashboardLayout>
    </>
  );
};

export default CustomerDetailsInfo;
