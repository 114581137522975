/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import AuthContext from "../Authentication/AuthContext/AuthContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import UploadHistroy from "./UploadHistory/UploadHistroy";
import UploadFile from "./UploadFile/UploadFile";
import ItemDetails from "./ItemDetails/ItemDetails";

import "./CatalogManagement.css";

const CatalogManagement = (props) => {
  const p=props;
  console.log(p.selectedCompany);
  const [isQuickFilter, setIsQuickFilter] = useState(false);
  const [selectedType, setSelectedType] = useState(
    sessionStorage.getItem("catalogManagementFilter") || "uploadFile"
  );
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);
  useEffect(() => {
    if (sessionStorage.getItem("catalogManagementFilter") === null) {
      sessionStorage.setItem("catalogManagementFilter", "uploadFile");
    }
  }, []);

  const orderManagementHeader = [
    {
      id: 1,
      name: "Upload File",
      type: "uploadFile"
    },
    {
      id: 2,
      name: "Upload History",
      type: "uploadHistory"
    },
    {
      id: 3,
      name: "Item Details",
      type: "itemDetails"
    }
  ];

  const handleVentFilterClick = isQuickFilter
    ? orderManagementHeader
    : [
        ...new Set([
          orderManagementHeader[0],
          _.find(orderManagementHeader, item => item.type === selectedType),
          ...orderManagementHeader
        ])
      ];

  const updateVentList = (e, isQuickFilter) => {
    if (e === selectedType) {
      return;
    }
    sessionStorage.setItem("catalogManagementFilter", e);
    setSelectedType(e);
    setIsQuickFilter(isQuickFilter);
  };
  const showTab = () => {
    switch (sessionStorage.getItem("catalogManagementFilter")) {
      case "uploadFile":
        return <UploadFile selectedCompany={p.selectedCompany}/>;
      case "uploadHistory":
        return <UploadHistroy selectedCompany={p.selectedCompany}/>;
      case "itemDetails":
        return <ItemDetails selectedCompany={p.selectedCompany}/>;
      default:
        break;
    }
  };
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        <div className="catalog-management-container">
          <div className="catalog-management-header">
            <div className="catalog-management-header-tabs">
              {handleVentFilterClick.map((item, idx) => (
                <div
                  key={idx}
                  value={item?.type}
                  onClick={() => {
                    updateVentList(item?.type, true);
                  }}
                  className="catalog-management-tab-info"
                >
                  <h1
                    className={`${
                      selectedType === item?.type ? "selected-tab-line" : ""
                    }`}
                  >
                    {item?.name}
                  </h1>
                </div>
              ))}
            </div>
          </div>
          <hr className="line" />
          {showTab()}
        </div>
      </DashboardLayout>
    </>
  );
};

export default CatalogManagement;
