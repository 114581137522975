/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useState } from "react";
import AuthContext from "../AuthContext/AuthContext";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import BrandCredential from "./BrandCredential/BrandCredential";
import StaffCredential from "./StaffCredential/StaffCredential";

import "./BrandLoginCredential.css";
import StaffCreation from "./StaffCreation/StaffCreation";

const BrandLoginCredential = (props) => {
  const p=props;
  console.log(p.selectedCompany)
  const [brandCredential, setBrandCredential] = useState(true);
  const [staffCredential, setStaffCredential] = useState(false);
  const { authTokens } = useContext(AuthContext);
  console.log(authTokens.data.role);
  return (
    <>
      <DashboardLayout role={authTokens.data.role}>
        {/* <DashboardNavbar /> */}
        <div className="login-credential-container">
          <div className="login-credential-header">
            <div
              className={`login-credential-tab ${
                brandCredential ? "selected-tab selected-tab-line" : ""
              } `}
              onClick={() => {
                setBrandCredential(true);
                setStaffCredential(false);
              }}
            >
              Staff
            </div>
            <div
              className={`login-credential-tab ${
                staffCredential ? "selected-tab selected-tab-line" : ""
              }`}
              onClick={() => {
                setStaffCredential(true);
                setBrandCredential(false);
              }}
            >
              Create Staff
            </div>
          </div>
          <hr className="line" />
          {brandCredential && !staffCredential ? (
            <BrandCredential selectedCompany={p.selectedCompany}/>
          ) : (
            <StaffCreation selectedCompany={p.selectedCompany}/>
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default BrandLoginCredential;
