/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomTable from "components/CustomTable/CustomTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useRef, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomToast from "utlis/Toast/CustomToast";
import { ToastConstant } from "utlis/Toast/toastConstant";
import Icon from "@mui/material/Icon";
 
// Date picker imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import * as XLSX from 'xlsx';

const FlatPrice = ({
  promoTypeSelected,
  setPromoTypeSelected,
  promoTypeSelectedId,
  setPromoTypeSelectedId,
  storeCode,
  selectedCompany
}) => {
  const formControl = "form-control";
  const initialValues = {};
  const [formValues, setFormValues] = useState(initialValues);
  const [categories, setCategories]=useState([]);
  const [file, setFile] = useState();
  const [csv, setCsv]=useState([]);
  const hiddenFileInput = useRef(null);
  const [selectedCategories, setSelectedCategories]=useState(new Set());
  const [options, setOptions]=useState([]);
  const [showOtherStore, setShowOtherStore] = useState(false);
  const [StoreRow, setStoreRow]=useState([]);
  const StoreCol=[
    { field: 'check', header: 'Select' },
    { field: 'storeCode', header: 'Store Code' },
    { field: 'storeName', header: 'Store Name' },
  ];
  // toast state
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  // Date
  const [startTime, setStartTime] = useState(dayjs(new Date()));
  const [endTime, setEndTime] = useState(dayjs(new Date()));

  const handleChangeStartDate = newValue => {
    setStartTime(newValue);
  };
  const handleChangeEndDate = newValue => {
    setEndTime(newValue);
  };

  //   API helper function
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  useEffect(async ()=>{
    const response=await axios.get('https://apis.fadoki.com/category/all')
    setCategories(response.data);
    console.log(response.data);
  },[]);
  // setCategories(['a','b','c']);
  const backToPromotionCard = () => {
    setPromoTypeSelected([]);
    setPromoTypeSelectedId("");
  };
  // https://apis.fadoki.com/category/all

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  useEffect(()=>{
    console.log(csv);
  }, [csv]);

  const handleUploadFile = () => {
    // console.log(file);
    const reader=new FileReader();
    if (file) {
      if(file.name.slice(-4)==='xlsx' || file.name.slice(-4)==='.xls'){
        reader.onload = (e) => {
          // console.log(e.target.result);
          const data=XLSX.read(e.target.result, {type:'binary'});
          const sheet=data.SheetNames[0];
          // console.log(data.Sheets[sheet]);
          const json=XLSX.utils.sheet_to_json(data.Sheets[sheet], {header: 1}).slice(1);
          // console.log(data);
          setCsv(
            json.map((item)=>({
              sku: item[0]
            }))
          )
        }
        reader.readAsBinaryString(file);
        // console.log(file.name.slice(-4));
      }else{
        // console.log(file.name.slice(-4));
        reader.onload = (e)=>{
          const text=e.target.result;
          const rows = text.slice(text.indexOf('\n')+1).split('\n');
          setCsv(
            rows.map((item)=>({
              sku: item.slice(0, item.indexOf(','))
            }))
          )
        }
        reader.readAsText(file);
      }
      console.log(csv);
    }
  };

  useEffect(() => {
    // UseEffect to upload the file
    if (file) {
      handleUploadFile();
    }
  }, [file]);

  useEffect(()=>{
    const arr=[];
    console.log(categories);
    categories.forEach((item)=>{
      arr.push({name:item.name, value:item.id});
      item.category.forEach((i)=>{
        arr.push({name:i.name, value:i.id});
        i.sub_category.forEach((j)=>{
          arr.push({name:j.name, value:j.id});
        })
      })
    });
    console.log(arr);
    setOptions(
      arr.map((item)=>({
        label:item.name,
        value:item.value,
      }))
    )
    console.log(options);
  }, [categories]);
  
  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    console.log(selectedCategories, "formValues");
  };

  const handleSelect = e => {
    setSelectedCategories(e);
    console.log(selectedCategories, "categories");
  };

  //   Search SKU state
  const [searchSKU, setSearchSKU] = useState("");
  const [skuResult, setSkuResult] = useState([]);
  const [skuResultRow, setSkuResultRow] = useState([]);

  const [skux, setSkux] = useState([]);

  const searchSKUHandler = () => {
    if (searchSKU && searchSKU.length > 0) {
      //   console.log(searchSKU);
      (async () => {
        const response = await AuthAxios.get(
          `/${storeCode.code}/inventory/${searchSKU}`
        );
        if (response.status === 200) {
          if (response.data.length > 0) {
            setSkuResult(response.data);
            setSkux(response.data[0].product.sku);
          } else {
            setOpen(true);
            setOpen(true);
            setToastData(
              ToastConstant.DYNAMIC_ERROR(
                `SKU ${searchSKU} Found`,
                3000,
                "error"
              )
            );
          }
          setSearchSKU("");
        }
      })();
    }
  };

  useEffect(() => {
    if (skuResult && skuResult[0] && skuResult[0].product.sku) {
      setSkux([...skux, skuResult[0]?.product?.sku]);
    }
  }, [skuResult]);

  const productSelectionCol = [
    { field: "sku", header: "SKU" },
    { field: "productDetails", header: "Product Details" },
    { field: "type", header: "Type" },
    { field: "category", header: "Category" },
    { field: "subCategory", header: "Sub Category" },
    { field: "price", header: "Price" }
  ];

  useEffect(() => {
    if (skuResult && skuResult.length > 0) {
      setSkuResultRow([
        ...skuResultRow,
        {
          sku: skuResult[0]?.product.sku,
          productDetails: skuResult[0]?.product.name,
          type: skuResult[0]?.product.name,
          category: skuResult[0]?.product.name,
          subCategory: skuResult[0]?.product.name,
          price: skuResult[0]?.product.sp
        }
      ]);
    }
  }, [skuResult]);

  // Store Rows data
  const [storeList, setStoreList]=useState([]);
  const [selectedStores, setSelectedStores]=useState([]);
  useEffect(() => {
    if(showOtherStore===false){
      setSelectedStores([]);
    }
    else{
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response = await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${selectedCompany}/store`)
      }
      else{
        response = await AuthAxios.get("/stores");
      }
      if (response.status === 200) {
        setStoreList(response.data.data);
        console.log(response.data.data);
        // setStockOverviewLoader(false);
      }
    })();}
  }, [showOtherStore]);

  useEffect(()=>{
  if(storeList){
    console.log(storeList)
    setStoreRow(
      storeList.map((item)=>({
        storeCode: item.code,
        storeName: item.name,
        check: <input type='checkbox' />
    })))
  }
  },[storeList])


  useEffect(()=>{
    console.log(selectedStores);
  }, [selectedStores])

  useEffect(()=>{console.log(skux)}, [skux]);

  const handleSubmit = e => {
    e.preventDefault();
    if(selectedStores.includes(storeCode?.code)===false){
      console.log(storeCode?.code);
      selectedStores.push(storeCode?.code);
    }
    const arr=[];
    if(selectedCategories.length>0){selectedCategories.map((item)=>{
      arr.push(item.label)
      return true;
    })}
    console.log(arr);
    console.log("here", formValues);
    AuthAxios.post("/offer", {
      company_id: selectedCompany !== undefined ? selectedCompany : authTokens.data.company.id,
      store: selectedStores,
      type: "FLATPRICE",
      name: formValues.promoName,
      start_date: startTime,
      end_date: endTime,
      qty_x: formValues.minQty,
      qty_y: 0,
      sku_x: csv.length>0 ? csv:skux,
      sku_y: [],
      value: formValues.flatPrice,
      tags_x: arr,
      tags_y: []
    })
      .then(response => {
        if (response.status === 200) {
          setOpen(true);
          setOpen(true);
          setToastData(
            ToastConstant.DYNAMIC_SUCCESS(
              response.data.message,
              3000,
              "success"
            )
          );
        }
      })
      .catch((error) => {
        setOpen(true);
        setOpen(true);
        setToastData(ToastConstant.DYNAMIC_FAIL(error.response.data.message, 3000, "error"));
      });
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
    .form-group {
        width:30%;
        margin-bottom: 0;
    }
    .css-1s2u09g-control, .css-1pahdxg-control {
      min-height: 0;
      max-height: 40px;
    }
    .css-319lph-ValueContainer {
      padding: 0 8px;
    }
    .css-6j8wv5-Input {
      margin: 0;
    }
    .css-b62m3t-container{
    width: 100%;
  }
   `
        }}
      />
      <div className="create-promotion-step-container">
        <div className="create-promotion-step-header">
          <div className="back-button" onClick={() => backToPromotionCard()}>
            <Icon
              fontSize="small"
              color="inherit"
              style={{ cursor: "pointer" }}
            >
              arrow_back_ios
            </Icon>
          </div>
          Create Promotion: {promoTypeSelected}
        </div>

        <form
          className="create-promotion-form"
          id="createPromotion"
          noValidate
          autoComplete="off"
          autofill="off"
          onSubmit={handleSubmit}
        >
          <div className="create-promotion-section">
            <div className="form-group">
              <label>Store to create promotion</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  // onChange={handleChange}
                  className={`${formControl} form-read-only`}
                  name="storeName"
                  id="store-name"
                  value={storeCode?.name}
                  readOnly
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Promotion Id(Auto Generate)</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  // onChange={handleChange}
                  className={`${formControl} form-read-only`}
                  name="promoId"
                  id="promoId"
                  value=""
                  readOnly
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
          </div>

          <div className="create-promotion-section">
            <div className="form-group">
              <label>Promotion Name</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoName"
                  id="promoName"
                  value={formValues.promoName}
                  placeholder="Enter Details"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Promo Code</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoCode"
                  id="promoCode"
                  value={formValues.promoCode}
                  placeholder="If Any"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
          </div>

          <div className="create-promotion-section">
            <div className="form-group">
              <label>Promotion Start Date & Time</label>
              <div className="customer-input-area">
                {/* <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoStartDate"
                  id="promoStartDate"
                  value={formValues.promoStartDate}
                  style={{ paddingLeft: "0.75rem" }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label=" "
                    value={startTime}
                    onChange={handleChangeStartDate}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className="form-group">
              <label>Promotion End Date & Time</label>
              <div className="customer-input-area">
                {/* <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="promoEndDate"
                  id="promoEndDate"
                  value={formValues.promoEndDate}
                  style={{ paddingLeft: "0.75rem" }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label=" "
                    value={endTime}
                    onChange={handleChangeEndDate}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <div className="create-promotion-section">
            <div className="form-group">
              <label>Flat Price</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="flatPrice"
                  id="moneyOff"
                  value={formValues.flatPrice}
                  placeholder="Enter amount"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Minimum Quantity</label>
              <div className="customer-input-area">
                <input
                  type="text"
                  onChange={e => handleChange(e)}
                  className={formControl}
                  name="minQty"
                  id="minQty"
                  value={formValues.minQty}
                  placeholder="Enter minimum quantity"
                  style={{ paddingLeft: "0.75rem" }}
                />
              </div>
            </div>
          </div>

          <hr className="line" style={{ width: "100%" }} />

          <div className="product-selection">
            <h1>Product Selection</h1>
          </div>

          <div className="create-promotion-add-item-search-box">
            <div className="form-group">
              <label>Add Items</label>
              <div className="search-container">
                <input
                  type="text"
                  onChange={e => setSearchSKU(e.target.value)}
                  className={formControl}
                  name="searchSKU"
                  id="searchSKU"
                  placeholder="Search SKU"
                  value={searchSKU}
                  required
                  style={{ paddingLeft: "0.75rem", border: "none" }}
                />
                <img
                  src="./img/searchIcon.svg"
                  alt="search"
                  onClick={() => searchSKUHandler()}
                />
              </div>
            </div>

            {/* {skuResultRow && skuResultRow.length > 0 && ( */}
              <CustomTable
                data={skuResultRow}
                columns={productSelectionCol}
                hover={false}
                striped={false}
              />
            {/* )} */}
          </div>

          <div className="product-selection">
            <h1>Or Bulk Product Selection</h1>
          </div>

          <div className="bulk-file-upload-section">
            <div className="uplaod-img">
              <img src="/img/fileUpload.svg" alt="upload" />
            </div>
            <div className="upload-actions">
              <div className="upload-header">
                <h1>Drag and drop file here or</h1>
                <p>Accepted file formats: Excel, CSV</p>
              </div>
              <div className="upload-buttons">
                 <button type="button" className="btn btn-browse-file" onClick={handleClick}>
                  Browse Files
                </button>
                <input
              type="file"
              id="fileUpload"
              ref={hiddenFileInput}
              onChange={(e)=>{setFile(e.target.files[0])}}
              style={{ display: "none" }}
              accept=".xlsx,.xls,.csv"
            />
                
                <button type="button" className="btn btn-download-file">
                  Download Sample
                </button>
              </div>
            </div>
          </div>

          <div className="product-selection">
            <h1>Or Select Categories</h1>
          </div>
          <div className="create-promotion-add-category">
            {/* Select area */}
            <CustomMultiSelect
              multi='true'
              value={selectedCategories}
              options={options}
              handleChange={handleSelect}
            />
            {/* End Select Area */}
            <span className="create-promotion-add-more-categories">
              +Add more categories
            </span>
          </div>

          <hr className="line" style={{ width: "100%" }} />

          <div
            className="other-store-promotion"
            onClick={() => setShowOtherStore(prevState => !prevState)}
          >
            {showOtherStore ?
              (<img src="/img/minus.svg" alt="" />):
              (<img src="/img/plus.svg" alt="" />)
          }
            <h1>Add promotion to other stores</h1>
          </div>

          {showOtherStore && (
            <>
              <div className="create-promotion-add-item-search-box">
                <div className="form-group">
                  <label>Search Stores</label>
                  <div className="search-container">
                    <input
                      type="text"
                      // onChange={handleChange}
                      className={formControl}
                      name="name"
                      id="name"
                      placeholder="Search Stores"
                      // value={values.name}
                      required
                      style={{ paddingLeft: "0.75rem", border: "none" }}
                    />
                    <img src="./img/searchIcon.svg" alt="search" />
                  </div>
                </div>
              </div>
              <CustomTable
                data={StoreRow}
                columns={StoreCol}
                customCheck = {(row, e) => {
                  console.log(e);
                    if(e===true){
                      let arr=[];
                      arr=selectedStores;
                      arr.push(row.storeCode);
                      setSelectedStores(arr);
                    } else {
                      selectedStores.pop(row.storeCode);
                    }
                    console.log(selectedStores)
                  }
                }
                hover={false}
                striped={false}
              />
            </>
          )}

          <div className="create-promotion-save-button">
            <button type="submit" className="btn btn-create-promotion-save">
              Save
            </button>
            <button
              type="button"
              className="btn btn-create-promotion-cancel"
              onClick={() => backToPromotionCard()}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default FlatPrice;
