/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomTable from "components/CustomTable/CustomTable";
import React, { useContext, useEffect, useState } from "react";
import AcceptRejectModal from "../AcceptRejectModal/AcceptRejectModal";

import "./NewOrder.css";

const NewOrder = (props) => {
  const p=props;
  const [showAcceptRejectModal, setShowAcceptRejectModal] = useState(true);
  const [pendingOrder, setPendingOrder] = useState("");
  const [orderTableData, setOrderTableData] = useState([]);

  // selected button order information
  const [orderInformation, setOrderInformation] = useState("");
  const [orderData, setOrderData] = useState("");

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  useEffect(() => {
    if (orderInformation && Object.keys(orderInformation).length > 0) {
      // const orderInfo = pendingOrder.orders[0].items;
      // setOrderData(
      //   orderInfo.find(item => item.order_id === orderInformation.orderId)
      // );
    }
  }, [orderInformation]);

  // console.log(orderData, "orderData????????");

  useEffect(() => {
    (async () => {
      let response="";
      if(authTokens.data.role===4){
        response = await AuthAxios.get(`orders?type=PENDING&company_id=${p.selectedCompany}`);
      }
      else{
        response = await AuthAxios.get("orders?type=PENDING");
      }
      
      if (response.status === 200) {
        setPendingOrder(response.data);
      }
    })();
  }, [p.selectedCompany]);

  const NewOrderCol = [
    { field: "orderId", header: "Order Id" },
    { field: "customerName", header: "Customer Name" },
    { field: "mobileNumber", header: "Mobile Number" },
    { field: "storeName", header: "Store Name" },
    { field: "deliveryAddress", header: "Delivery Address" },
    { field: "totalItems", header: "Total Items" },
    { field: "orderTotal", header: "Oder Total" },
    { field: "button", header: "button" }
  ];

  useEffect(() => {
    if (
      pendingOrder &&
      pendingOrder.orders &&
      pendingOrder.orders[0] &&
      pendingOrder.orders[0].items
    ) {
      console.log(pendingOrder);
      setOrderTableData(
        Object.values(pendingOrder.orders).map(item => ({
          orderId: item.uuid,
          customerName: item.user,
          mobileNumber: item.user,
          storeName: item.store.name,
          deliveryAddress: `${item.flat}, ${item.area}, ${item.city}, ${item.state}, ${item.pincode}`,
          totalItems: item.items.length,
          orderTotal: item.amount,
          itemDetails: item.items,
          button: "Accept/Reject"
        }))
      );
    }
  }, [pendingOrder]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          table {
            width: 100%
          }
          `
        }}
      />
      {showAcceptRejectModal &&
        orderInformation &&
        Object.keys(orderInformation).length > 0 && (
          <AcceptRejectModal
            showAcceptRejectModal={showAcceptRejectModal}
            setShowAcceptRejectModal={setShowAcceptRejectModal}
            orderInformation={orderInformation}
          />
        )}
      <div className="new-order-container">
        <div className="new-order-header">New Order</div>
        <hr className="line" />
        <div className="brand-credential-table-section">
          <CustomTable
            data={orderTableData}
            columns={NewOrderCol}
            customClassOne="brand-credential-create-login"
            buttonTypeOne="Accept/Reject"
            customAction={setShowAcceptRejectModal}
            customData={setOrderInformation}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default NewOrder;
