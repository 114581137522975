/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomToast = (props) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.setOpen(false);
    };

    useEffect(()=>{
        props.setOpen(props.open)
    },[props.open])

    return (
        <Snackbar open={props.open} autoHideDuration={props.time} onClose={handleClose}>
            <Alert onClose={handleClose} severity={props.severity}>
                {props.message}
        </Alert>
        </Snackbar>
    )
}

CustomToast.propTypes = {
    time : PropTypes.number.isRequired,
    severity : PropTypes.string.isRequired,
    open : PropTypes.bool.isRequired,
    message : PropTypes.string.isRequired,
    setOpen : PropTypes.func.isRequired
}

export default CustomToast
