/* eslint-disable spaced-comment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";

const CustomerOrderDetails = ({ orderData }) => {
  const [orderDetail, setOrderDetail] = useState([]);

  const tableCol = [
    { header: "Order Id", field: "orderId" },
    { header: "SKU", field: "sku" },
    { header: "Product Details", field: "productDetails" },
    { header: "Size", field: "size" },
    { header: "Color", field: "color" },
    { header: "Price", field: "price" },
    { header: "Qty", field: "qty" }
  ];

  useEffect(() => {
    if (orderData && orderData.length) {
      setOrderDetail(
        orderData.map(item => ({
          orderId: item.orderId,
          sku: item.sku,
          productDetails: item.name,
          size: item.size,
          color: item.color,
          price: item.sp,
          qty: item.quantity
        }))
      );
    }
  }, [orderData]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="customer-details-container" style={{ marginTop: "2rem" }}>
        <div className="details-table-header">Order Details</div>
        <div className="existing-brand-table-section">
          <CustomTable
            data={orderDetail}
            columns={tableCol}
            hover={false}
            striped={false}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerOrderDetails;
