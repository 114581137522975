/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomSelect from "components/CustomSelect/CustomSelect";
import CustomTable from "components/CustomTable/CustomTable";

import "./StockOverview.css";

const StockOverview = (props) => {
  const p=props;
  console.log(p.selectedCompany)
  const formControl = "form-control";
  const [selectedStore, setSelectedStore] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [storeCode, setStoreCode] = useState("");
  const [stockOverviewLoader, setStockOverviewLoader] = useState(false);
  // const [stockOverviewLoader, setStockOverviewLoader] = useState(true);
  const [searchedItem, setSearchedItem] = useState("");

  // Inventory table states
  const [inventoryData, setInventoryData] = useState([]);
  const [inventoryDataTable, setInventoryDataTable] = useState([]);

  // helper function
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  // Get store list
  useEffect(() => {
    (async () => {
      let response=''
      if(authTokens.data.role===4){
        response=await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${p.selectedCompany}/store`)
      }
      else{
        response = await AuthAxios.get("/stores");
      }
      if (response.status === 200) {
        setStoreList(response.data.data);
        // setStockOverviewLoader(false);
      }
    })();
  }, [p.selectedCompany]);
  useEffect(()=>{
    setSelectedStore('');
  }, [p.selectedCompany]);
  useEffect(() => {
    if (
      storeList &&
      storeList.length &&
      selectedStore &&
      selectedStore.length
    ) {
      setStoreCode(storeList.find(item => item.name === selectedStore));
    }
  }, [storeList, selectedStore]);

  useEffect(() => {
    if (storeCode && storeCode.code && storeCode.code.length) {
      (async () => {
        let response='';
        if(authTokens.data.role===4){
          response = await AuthAxios.get(`/${storeCode.code}/inventory?company=${p.selectedCompany}`);
        } else {
          response = await AuthAxios.get(`/${storeCode.code}/inventory?company=${authTokens.data.company.id}`);
        }
        
        if (response.status === 200) {
          setInventoryData(response.data.data);
        }
      })();
    }
  }, [storeCode]);

  // Table Headers
  const StockOverviewCol = [
    { field: "sku", header: "SKU" },
    // { field: "doc", header: "Creation Date" },
    { field: "productName", header: "Product Name" },
    { field: "stock", header: "Stock" },
    // { field: "brand", header: "Brand" },
    { field: "mrp", header: "MRP" },
    { field: "sp", header: "Selling Price" }
  ];

  // Table Rows
  useEffect(() => {
    console.log(inventoryData);
    setInventoryDataTable(
      inventoryData.map(item => ({
        sku: item.product.sku,
        // doc: dateOfCreation info
        productName: item.product.name,
        stock: item.show_out_of_stock ? 0 : item.quantity,
        // brand: item.product.brand,
        mrp: item.product.mrp,
        sp: item.product.sp
      }))
    );
  }, [inventoryData]);

  const handleSearch = () => {
    if (searchedItem && searchedItem.length > 0) {
      (async () => {
        const response = await AuthAxios.get(
          `/${storeCode.code}/inventory/${searchedItem}`
        );
        if (response.status === 200) {
          console.log(response.data);
          setInventoryDataTable(
            response.data.map(item => ({
              sku: item.product.sku,
              // doc: dateOfCreation info
              productName: item.product.name,
              stock: item.show_out_of_stock ? 0 : item.quantity,
              // brand: item.product.brand,
              mrp: item.product.mrp,
              sp: item.product.sp
            }))
          );
        }
      })();
    }
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .css-b62m3t-container {
              width:100%;
            }
            .form-group {
              width: 100%;
            }
            table {
             width: 100%
            }`
        }}
      />
      {stockOverviewLoader ? (
        <h1>Loading</h1>
      ) : (
        <div className="stock-overview-container">
          <div className="stock-overview-header">
            Select store to see inventory
          </div>
          <div className="stock-overview-store-selector">
            <CustomSelect
              list={storeList}
              selected={storeList.find(item => item.name === selectedStore)}
              update={newValue => setSelectedStore(newValue)}
            />
            {selectedStore && selectedStore.length > 0 && (
              <div className="stock-overview-search-box">
                <div className="form-group">
                  <div className="stock-overview-search">
                    <img
                      src="./img/searchIcon.svg"
                      alt="search"
                      onClick={() => handleSearch()}
                    />
                    <input
                      type="text"
                      onChange={e => setSearchedItem(e.target.value)}
                      className={formControl}
                      name="productName"
                      id="productName"
                      placeholder="Search SKU/Product Name"
                      value={searchedItem}
                      required
                      style={{ paddingLeft: "0.75rem", border: "none" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {selectedStore && selectedStore.length > 0 && (
            <>
              {inventoryDataTable && inventoryDataTable.length > 0 ? (
                <>
                  <div className="stock-overview-table-section">
                    <CustomTable
                      // data={StockOverviewRow}
                      data={inventoryDataTable}
                      columns={StockOverviewCol}
                      hover={false}
                      striped={false}
                    />
                  </div>
                  {/* <div className="stock-overview-download-tab">
                    <button type="button" className="download">
                      Download Data
                    </button>
                  </div> */}
                </>
              ) : (
                <>No Data</>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default StockOverview;
