/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

// Screen 2

import React, { useEffect, useState } from "react";
import formValidation from "utlis/formValidation";

import "./ForgotPassword.css";

const ForgotPassword = () => {
  const formControl = "form-control";
  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [resetLinkSent, setResetLinkSent] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    console.log(formValues, "formValues");
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormErrors(formValidation.forgotPasswordFormValidate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    console.log(formErrors, "formErrors");
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .form-group {
            width:100%;
            margin-top: 1rem;
        }`
        }}
      />
      <div className="forgot-password-container">
        <div className="forgot-password-box">
          <div className="forgot-password-branding-logo">
            <div className="brand-logo">
              <img src="./img/fadoki-logo.svg" alt="" />
            </div>
          </div>
          <div className="forgot-password-form">
            {resetLinkSent ? (
              <>
                <div className="forgot-password-content">
                  <div className="forgot-password-header">
                    Reset password link sent
                  </div>
                  <div className="forgot-password-sub-header">
                    Check your registered email Id
                  </div>
                </div>
              </>
            ) : (
              <div className="forgot-password-content">
                <div className="forgot-password-header">Forgot Password</div>
                <div className="forgot-password-sub-header">
                  Enter your registered email for password reset link
                </div>

                <form
                  className="forgot-password-form-structure"
                  id="forgotPasswordDetail"
                  noValidate
                  autoComplete="off"
                  autofill="off"
                  onSubmit={handleSubmit}
                >
                  <div className="forgot-password-form-section">
                    <div className="form-group">
                      <label>Email ID</label>
                      <div className="customer-input-area">
                        <input
                          type="email"
                          className={formControl}
                          name="email"
                          placeholder="Enter registered e-mail id"
                          onChange={e => handleChange(e)}
                          value={formValues.email}
                          style={{ paddingLeft: "0.75rem" }}
                          autoComplete="off"
                        />
                      </div>
                      {formErrors.email && formErrors.email.length > 0 && (
                        <p className="form-validation-error">
                          {formErrors.email}
                        </p>
                      )}
                    </div>
                    <div className="forgot-password-button">
                      <button type="submit" className="btn btn-booking-confirm">
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
