/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import BulkStaffCreation from "./BulfStaff/BulkStaffCreation";
import SingleStaffCreation from "./SingleStaff/SingleStaffCreation";

import "./StaffCreation.css";

const StaffCreation = (props) => {
  const p=props;
  console.log(p.selectedCompany)
  const [showSingleStaff, setShowSingleStaff] = useState(true);
  const [showBulkStaff, setShowBulkStaff] = useState(false);
  return (
    <>
      <div className="staff-credential-container">
        {/* <div className="details-table-header">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowSingleStaff(true);
              setShowBulkStaff(false);
            }}
          >
            <input
              type="radio"
              value={showSingleStaff}
              name="singleStaff"
              checked={showSingleStaff}
            />{" "}
            Single Staff
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowSingleStaff(false);
              setShowBulkStaff(true);
            }}
          >
            <input
              type="radio"
              value={showBulkStaff}
              name="bulkStaff"
              checked={showBulkStaff}
            />{" "}
            Bulk Staff
          </span>
        </div> */}
        {/* <hr className="line" /> */}
        {showSingleStaff && !showBulkStaff ? (
          <SingleStaffCreation selectedCompany={p.selectedCompany}/>
        ) : (
          <BulkStaffCreation selectedCompany={p.selectedCompany}/>
        )}
      </div>
    </>
  );
};

export default StaffCreation;
