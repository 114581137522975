/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable spaced-comment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import CustomMultiSelect from "components/CustomSelect/CustomMultiSelect";
import CustomSelect from "components/CustomSelect/CustomSelect";
import React, { useContext, useEffect, useState } from "react";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import CustomToast from "utlis/Toast/CustomToast";
import { ToastConstant } from "utlis/Toast/toastConstant";
import Icon from "@mui/material/Icon";

const StoreEdit = (props) => {
  const p=props;
  const initialValues = {};
  const formControl = "form-control";
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const [selectedStoreType, setSelectedStoreType] = useState("");
  const [selectedStoreCategory, setSelectedStoreCategory]=useState(new Set());

  // Break time states
  const [breakStatus, setBreakStatus] = useState(false);
  const [breakTime, setBreakTime] = useState(["10:00", "11:00"]);

  // toast state
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  console.log(p.storeEditData.storeCode, "storeEditData");

  useEffect(() => {
    setSelectedStoreType(p.storeEditData.storeType);
    // selectedStoreCategory=
    setFormValues(()=>{
    const x={}; 
    console.log(p.storeEditData)
    if (Object.keys(p.storeEditData).length > 0) {
      console.log("Hi");
      x.storeCode = p.storeEditData?.storeCode;
      x.storeName = p.storeEditData?.storeName;
      x.phoneNumber = p.storeEditData?.contactNumber;
      x.altPhoneNumber = p.storeEditData?.altNumber;
      x.pocName = p.storeEditData?.pocName;
      x.storeArea = p.storeEditData?.storeInfo?.area;
      x.storeCity = p.storeEditData?.storeInfo?.city;
      x.storeState = p.storeEditData?.storeInfo?.state;
      x.storePostal = p.storeEditData?.storeInfo?.pincode;
      x.returnStoreArea = p.storeEditData?.storeInfo?.return_area;
      x.returnStoreCity = p.storeEditData?.storeInfo?.return_city;
      x.returnStoreState = p.storeEditData?.storeInfo?.return_state;
      x.returnStorePostal = p.storeEditData?.storeInfo?.return_pincode;
      x.gstin = p.storeEditData?.storeInfo?.gst;
      x.storeInformation = p.storeEditData?.storeInfo?.description;
      x.company=p.storeEditData?.storeInfo?.company
    }
    return x;
  })
  }, [p.storeEditData]);

  console.log(formValues, "formValues.storeName");

  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    // setFormErrors(formValidation.createStoreFormValidation(formValues));
    console.log(selectedStoreCategory);
    // API HIT
    if(authTokens.data.role!==4){
      AuthAxios.put(`/store/${p.storeEditData.storeCode}`, {
        image: "IMG url here HC",//
        code: formValues.storeCode,//
        name: formValues.storeName,//
        area: formValues.storeArea,//
        city: formValues.storeCity,//
        state: formValues.storeState,//
        pincode: formValues.storePostal,//
        category: selectedStoreCategory,
        // rating : 0,
        contactNumber: formValues.phoneNumber,//
        alternate_contact_number: formValues.altPhoneNumber,//
        poc: formValues.pocName,//
        type:selectedStoreType,//
        return_area: formValues.returnStoreArea,//
        return_city: formValues.returnStoreCity,//
        return_state: formValues.returnStoreState,//
        return_pincode: formValues.returnStorePostal,//
        company: formValues.company,
        // gstin: formValues.gstin
      })
      .then(response => {
        if (response.status === 200) {
          setOpen(true);
          setToastData(
            ToastConstant.DYNAMIC_SUCCESS(
              response.data.message,
              3000,
              "success"
            )
          );
          setTimeout(() => {
            p.setShowEditScreen(prevState => !prevState);
          }, 3000);
        }
      })
      .catch(error => {
        setOpen(true);
        console.log(error.message)
        setToastData(ToastConstant.DYNAMIC_FAIL(error.message, 3000, "error"));
      });
    }
    else{
      AuthAxios.put(`https://apis.fadoki.com/fadoki/company/${formValues.company}/store`, {
        image: "IMG url here HC",//
        code: formValues.storeCode,//
        name: formValues.storeName,//
        area: formValues.storeArea,//
        city: formValues.storeCity,//
        state: formValues.storeState,//
        pincode: formValues.storePostal,//
        // rating : 0,
        contactNumber: formValues.phoneNumber,//
        alternate_contact_number: formValues.altPhoneNumber,//
        poc: formValues.pocName,//
        type:selectedStoreType,//
        category: selectedStoreCategory,
        return_area: formValues.returnStoreArea,//
        return_city: formValues.returnStoreCity,//
        return_state: formValues.returnStoreState,//
        return_pincode: formValues.returnStorePostal,//
        company: formValues.company,
        // gstin: formValues.gstin
      })
      .then(response => {
        if (response.status === 200) {
          setOpen(true);
          setToastData(
            ToastConstant.DYNAMIC_SUCCESS(
              response.data.message,
              3000,
              "success"
            )
          );
          setTimeout(() => {
            p.setShowEditScreen(prevState => !prevState);
          }, 3000);
        }
      })
      .catch(error => {
        setOpen(true);
        console.log(error.message)
        setToastData(ToastConstant.DYNAMIC_FAIL(error.message, 3000, "error"));
      });
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
    // console.log(formValues, "formValues");
  };

  const handleDeactivate = () => {
    AuthAxios.delete(`/stores/${p.storeEditData.storeCode}/active?company_id=${p.storeEditData.company}`)
    .then(response=>{
      setOpen(true);
      setToastData(
        ToastConstant.DYNAMIC_SUCCESS(
          response.data.message,
          3000,
          "success"
        )
      )
    }).catch(error=>{
      setOpen(true);
      setToastData(ToastConstant.DYNAMIC_FAIL(error.message, 3000, "error"));
    })
    setFormValues(initialValues);
    setFormErrors({});
  };

  const storeType = [
    { value: "mall", name: "Mall" },
    { value: "independentCommercialComplex", name: "Independent Commercial Complex" },
    { value: "standalone", name: "Standalone" },
    { value: "warehouse", name: "Warehouse" }
  ];

  const storeCategory = [
    { label: "Accessories", value: "accessories" },
    { label: "Apparel", value: "apparel" },
    { label: "Beauty", value: "beauty" },
    { label: "Footwear", value: "footwear" },
    { label: "Personal Care", value: "personalCare" }
  ];

  const handleMultiSelect = value => {
    console.log(value);
    // selectedStoreCategory=[];
    // selectedStoreCategory.push(value.value);
    setSelectedStoreCategory(value);
    console.log(selectedStoreCategory)
    // selectedStoreCategory.push();
  };

  const handleBreak = () => {
    setBreakStatus(prevState => !prevState);
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .form-group {
                width:30%;
                margin-bottom: 0;
            }
            .css-1s2u09g-control, .css-1pahdxg-control {
              min-height: 0;
              max-height: 40px;
            }
            .css-319lph-ValueContainer {
              padding: 0 8px;
            }
            .css-6j8wv5-Input {
              margin: 0;
            }
            textarea:focus {
                outline: none;
            }
            textarea:focus::placeholder {
                color: transparent;
            }
            .react-timerange-picker__wrapper {
              border: none !important;
            }`
        }}
      />
      <div className="create-store-container">
        <div
          className="details-table-header"
          onClick={() => p.setShowEditScreen(prevState => !prevState)}
        >
          <div className="back-button">
            <Icon fontSize="small" color="inherit">
              arrow_back_ios
            </Icon>
          </div>
          Edit Store
        </div>
        <hr className="line" />

        <div className="create-store-details-section">
          <span className="create-store-store-details">Store Details</span>
          <form
            className="create-store-form-structure"
            id="signInDetails"
            noValidate
            autoComplete="off"
            autofill="off"
            onSubmit={handleSubmit}
          >
            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Store Code</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="storeCode"
                    placeholder="Enter store code"
                    onChange={e => handleChange(e)}
                    value={formValues.storeCode}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                    readOnly
                  />
                  {formErrors.storeCode && formErrors.storeCode.length > 0 && (
                    <p className="create-store-registration-form-validation-error">
                      {formErrors.storeCode}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Store Name</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="storeName"
                    placeholder="Enter store name"
                    onChange={e => handleChange(e)}
                    value={formValues.storeName}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {formErrors.storeName && formErrors.storeName.length > 0 && (
                    <p className="create-store-registration-form-validation-error">
                      {formErrors.storeName}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Phone Number</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="phoneNumber"
                    placeholder="Enter phone number"
                    onChange={e => handleChange(e)}
                    value={formValues.phoneNumber}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {formErrors.phoneNumber &&
                    formErrors.phoneNumber.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.phoneNumber}
                      </p>
                    )}
                </div>
              </div>
              <div className="form-group">
                <label>Alternate Phone Number</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="altPhoneNumber"
                    placeholder="Enter alternate phone number"
                    onChange={e => handleChange(e)}
                    value={formValues.altPhoneNumber}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {formErrors.altPhoneNumber &&
                    formErrors.altPhoneNumber.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.altPhoneNumber}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="create-store-registration-section">
              <div className="form-group">
                <label>POC Name</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="pocName"
                    placeholder="Enter POC Name"
                    onChange={e => handleChange(e)}
                    value={formValues.pocName}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {formErrors.pocName && formErrors.pocName.length > 0 && (
                    <p className="create-store-registration-form-validation-error">
                      {formErrors.pocName}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>POC Designation</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="pocDesignation"
                    placeholder="Enter POC Designation"
                    onChange={e => handleChange(e)}
                    value={formValues.pocDesignation}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {formErrors.pocDesignation &&
                    formErrors.pocDesignation.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.pocDesignation}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Store Area</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="storeArea"
                    placeholder="Enter area"
                    onChange={e => handleChange(e)}
                    value={formValues.storeArea}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.storeArea && formErrors.storeArea.length > 0 && (
                    <p className="create-store-registration-form-validation-error">
                      {formErrors.storeArea}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Store City</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="storeCity"
                    placeholder="Enter city"
                    onChange={e => handleChange(e)}
                    value={formValues.storeCity}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.storeCity && formErrors.storeCity.length > 0 && (
                    <p className="create-store-registration-form-validation-error">
                      {formErrors.storeCity}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Store State</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="storeState"
                    placeholder="Enter state"
                    onChange={e => handleChange(e)}
                    value={formValues.storeState}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.storeState &&
                    formErrors.storeState.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.storeState}
                      </p>
                    )}
                </div>
              </div>
              <div className="form-group">
                <label>Store Pincode</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="storePostal"
                    placeholder="Enter postal code"
                    onChange={e => handleChange(e)}
                    value={formValues.storePostal}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.storePostal &&
                    formErrors.storePostal.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.storePostal}
                      </p>
                    )}
                </div>
              </div>
            </div>

            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Return Store Area</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="returnStoreArea"
                    placeholder="Enter return area"
                    onChange={e => handleChange(e)}
                    value={formValues.returnStoreArea}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.returnStoreArea &&
                    formErrors.returnStoreArea.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.returnStoreArea}
                      </p>
                    )}
                </div>
              </div>
              <div className="form-group">
                <label>Return Store City</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="returnStoreCity"
                    placeholder="Enter return city"
                    onChange={e => handleChange(e)}
                    value={formValues.returnStoreCity}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.returnStoreCity &&
                    formErrors.returnStoreCity.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.returnStoreCity}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Return Store State</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="returnStoreState"
                    placeholder="Enter return store state"
                    onChange={e => handleChange(e)}
                    value={formValues.returnStoreState}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.returnStoreState &&
                    formErrors.returnStoreState.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.returnStoreState}
                      </p>
                    )}
                </div>
              </div>
              <div className="form-group">
                <label>Return Store Pincode</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="returnStorePostal"
                    placeholder="Enter return store postal code"
                    onChange={e => handleChange(e)}
                    value={formValues.returnStorePostal}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {/* <span className="open-map">Open Map</span> */}
                  {formErrors.returnStorePostal &&
                    formErrors.returnStorePostal.length > 0 && (
                      <p className="create-store-registration-form-validation-error">
                        {formErrors.returnStorePostal}
                      </p>
                    )}
                </div>
              </div>
            </div>

            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Store Type</label>
                <CustomSelect
                  list={storeType}
                  selected={storeType.find(
                    item => item.name === selectedStoreType
                  )}
                  update={newValue => setSelectedStoreType(newValue)}
                />
              </div>
              {/* <div className="form-group">
                <label>GSTIN</label>
                <div className="brand-input-area">
                  <input
                    type="text"
                    className={formControl}
                    name="gstin"
                    placeholder="Enter GSTIN number"
                    onChange={e => handleChange(e)}
                    value={formValues.gstin}
                    style={{ paddingLeft: "0.75rem" }}
                    autoComplete="off"
                  />
                  {formErrors.gstin && formErrors.gstin.length > 0 && (
                    <p className="create-store-registration-form-validation-error">
                      {formErrors.gstin}
                    </p>
                  )}
                </div>
              </div> */}
            </div>

            <div className="create-store-registration-section">
              <div className="form-group">
                <label>Store Category</label>
                <CustomMultiSelect
                  multi='true'
                  value={selectedStoreCategory}
                  options={storeCategory}
                  handleChange={handleMultiSelect}
                />
              </div>
            </div>

            <span className="form-line" />
            <div className="create-store-standard-hours-section">
              <div className="create-store-standard-hours-header">
                <h1>Set Standard Hours</h1>
                <p>Configure the standard hours of operation for this store</p>
              </div>

              {/* {weekStatus.map(week => {
                return (
                  <DaysOperation
                    week={week}
                    handleWorkingDay={handleWorkingDay}
                    setStatus={setStatus}
                    status={status}
                    working={working}
                    setWorking={setWorking}
                  />
                );
              })} */}

              <div className="create-store-break-time">
                <span className="break-time-header">
                  Do you have break time?
                </span>
                <div className="break-time-section">
                  <span className="break-toggle" onClick={() => handleBreak()}>
                    EveryDay{" "}
                    {!breakStatus ? (
                      <img src="/img/toggleOff.svg" alt="closed" />
                    ) : (
                      <img src="/img/toggleOn.svg" alt="open" />
                    )}
                  </span>
                  {breakStatus && (
                    <TimeRangePicker
                      onChange={setBreakTime}
                      value={breakTime}
                      clearIcon={null}
                      clockIcon={null}
                      openClockOnFocus={false}
                      amPmAriaLabel="Select AM/PM"
                      locale="en-US"
                      // format="HH:mm"
                    />
                  )}
                </div>
              </div>

              <div className="create-store-store-instruction">
                <span className="store-instruction-header">
                  Store Instruction
                </span>
                <span className="store-instruction-sub-header">
                  This message will be shown in customer app
                </span>
                <textarea
                  className="text-area-cumstom"
                  placeholder=""
                  onChange={e => handleChange(e)}
                  value={formValues.storeInformation}
                  rows="3"
                  cols="10"
                ></textarea>
              </div>

              <div className="create-store-buttons">
                <button type="submit" className="btn btn-create-store">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-create-store-reset"
                  onClick={() => handleDeactivate()}
                >
                  De-Activate
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};

export default StoreEdit;
