/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable spaced-comment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";
import axios from "axios";
import EditIcon from '@mui/icons-material/Edit';
import StoreEdit from "../StoreEdit/StoreEdit";

const StoreTable = (props) => {
  const p=props;
  const [data, setData] = useState([]);
  const [rows, setRows]=useState([]);
  const [tableR, setTableR] = useState([]);
  const [storeEditData, setStoreEditData] = useState([]);
  const [showEditScreen, setShowEditScreen] = useState(false);
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });

  useEffect(() => {
    if(p.selectedCompany===''){
      console.log('this');
      (async () => {
        // console.log('a');
        const response = await AuthAxios.get("/stores");
        setData(response.data.data);
    })();
    } else {
      console.log("a");
      if(authTokens.data.role!==4){
        (async () => {
          // console.log('a')
          const response = await AuthAxios.get(`/stores`);
          setData(response.data.data);
        })();
      } else {
        // console.log('abcdef');
        (async()=>{
          const response=await AuthAxios.get(`https://apis.fadoki.com/fadoki/company/${p.selectedCompany}/store`);
          setData(response.data.data);
        })();
      }
    }
  }, [p.selectedCompany, !showEditScreen]);

  const tableCol = [
    { header: "Is Active", field: "is_active" },
    { header: "Store Code", field: "storeCode" },
    { header: "Store Name", field: "storeName" },
    { header: "Contact Number", field: "contactNumber" },
    { header: "Alternate Number", field: "altNumber" },
    { header: "POC Name", field: "pocName" },
    { header: "Address", field: "address" },
    { header: "Store Type", field: "storeType" },
    { header: "Return Store", field: "returnStore" },
    {header: "Edit", field: "customButton"},
  ];
useEffect(()=>{
  console.log(data);
  if(authTokens.data.role===4){
  setRows(
    data.filter((item)=>{if(item.company!==p.selectedCompany && p.selectedCompany!==''){
      return false;
    }
    return true;
  }).map((item)=>({
        code: item.code,
        name: item.name,
        contactNumber: item.contactNumber,
        alternate_contact_number: item.alternate_contact_number,
        area:item.area,
        city: item.city,
        state:item.state,
        pincode:item.pincode,
        is_active: item.is_active ? 'YES' : 'NO',
        // address: `${item.area}, ${item.city}, ${item.state}, ${item.pincode} `,
        poc: item.poc,
        return_area:item.return_area,
        return_city:item.return_city,
        return_state:item.return_state,
        return_pincode:item.return_pincode,
        // returnStore: `${item.return_area}, ${item.return_city}, ${item.return_state}, ${item.return_pincode} `,
        type: item.type,
        company: item.company,
        // original: item
    }))
  );}
  else{
    // console.log('here');
    // console.log(data);
    setRows(data.map((item)=>({
        code: item.code,
        name: item.name,
        contactNumber: item.contactNumber,
        alternate_contact_number: item.alternate_contact_number,
        area:item.area,
        city: item.city,
        state:item.state,
        pincode:item.pincode,
        is_active: item.is_active ? 'YES' : 'NO',
        // address: `${item.area}, ${item.city}, ${item.state}, ${item.pincode} `,
        poc: item.poc,
        return_area:item.return_area,
        return_city:item.return_city,
        return_state:item.return_state,
        return_pincode:item.return_pincode,
        // returnStore: `${item.return_area}, ${item.return_city}, ${item.return_state}, ${item.return_pincode} `,
        type: item.type,
        company: item.company
        // item: item
    })));
  }
}, [data]);

  useEffect(() => {
    console.log(rows);
    setTableR(
      rows.map((item) =>({
        // button: <EditIcon fontSize="medium"/>,
        customButton: <EditIcon fontSize="medium"/>,
        is_active: item.is_active,
        storeCode: item.code,
        storeName: item.name,
        contactNumber: item.contactNumber,
        altNumber: item.alternate_contact_number,
        address: `${item.area}, ${item.city}, ${item.state}, ${item.pincode} `,
        pocName: item.poc,
        returnStore: `${item.return_area}, ${item.return_city}, ${item.return_state}, ${item.return_pincode} `,
        storeType: item.type,
        company: item.company,
        storeInfo: item
      }
      ))
    );
  }, [rows]);

  const editAction = store => {
    // console.log(store);
    setStoreEditData(store);
    setShowEditScreen(true);
    // console.log(storeEditData);
  };

  return (
    <>
      {showEditScreen ? (
        <StoreEdit
          setShowEditScreen={setShowEditScreen}
          storeEditData={storeEditData}
          selectedCompany={p.selectedCompany}
        />
      ) : ( 
        <>
          <style
            dangerouslySetInnerHTML={{
              __html: `
          table {
            width: 100%
          }
          `
            }}
          />
          <div className="create-store-container">
            <CustomTable
              data={tableR}
              columns={tableCol}
              customData={store => editAction(store)}
              hover={false}
              striped={false}
            />
          </div>
        </>)}
    </>
  );
};

export default StoreTable;
