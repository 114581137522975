/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";

import "./OrderInformation.css";
import CustomSelect from "components/CustomSelect/CustomSelect";

const OrderInformation = ({ orderInformation }) => {
  const orderInformationCol = [
    { field: "sku", header: "SKU" },
    { field: "productDetails", header: "Product Details" },
    { field: "orderedQty", header: "Ordered Qty" },
    { field: "fullfilledQty", header: "Fullfilled Qty" }
  ];
  console.log(orderInformation.itemDetails);
  const [selectedQuantity, setSelectedQuantity] = useState('');
  // const options=[{name: 1, value: 1},{name: 2, value: 2},{name: 3, value: 3},{name: 4, value: 4}]
  const [options, setOptions]=useState([]);
  const [temp, setTemp]=useState(0);
  const [orderInformationRows, setOrderInformationRows] = useState([]);
  useEffect(()=>{
    setOptions(...options, 
      orderInformation.itemDetails.map((item)=>{
        const x=[];
        for(let i=0;i<=Number(item.quantity); i+=1){
          x.push({name: i, value: i});
          console.log(x);
        }
        return x;
      })
    )
    setTemp(1);
  }, [orderInformation])
  useEffect(()=>{
    if(temp!==0){
      console.log(temp);
      console.log(options);

      setOrderInformationRows(
        orderInformation.itemDetails.map((item, index)=>({
          sku: item.sku,
          productDetails: item.description,
          orderedQty: item.quantity,
          fullfilledQty: <CustomSelect
          list={options[index]}
          selected={options[index].find(
            i => i.name === selectedQuantity
          )}
          update={newValue => setSelectedQuantity(newValue)}
        />
        }))
      )
    }
  }, [temp]);
  // const orderInformationRow = [
  //   {
  //     sku: "8475",
  //     productDetails: "Sinful Matte Lipcolor",
  //     orderedQty: 4,
  //     fullfilledQty: <CustomSelect
  //                       list={options}
  //                       selected={options.find(
  //                         item => item.name === selectedQuantity
  //                       )}
  //                       update={newValue => setSelectedQuantity(newValue)}
  //                     />
  //   }
  // ];

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          table {
            width: 100%
          }
          `
        }}
      />
      <div className="brand-credential-table-section">
        <CustomTable
          data={orderInformationRows}
          columns={orderInformationCol}
          hover={false}
          striped={false}
        />
      </div>
    </>
  );
};

export default OrderInformation;
