/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/order */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OrderInformation from "../OrderInformation/OrderInformation";
import axios from "axios";
import AuthContext from "components/customCoponents/Authentication/AuthContext/AuthContext";

import "./AcceptRejectModal.css";

const AcceptRejectModal = ({
  showAcceptRejectModal,
  setShowAcceptRejectModal,
  orderInformation
}) => {
  const { authTokens } = useContext(AuthContext);
  const apiURL = "https://apis.fadoki.com/merchant";
  const AuthAxios = axios.create({
    baseURL: apiURL,
    headers: {
      Authorization: `Bearer ${String(authTokens.access)}`
    }
  });
  const closeModalAction = () => {
    setShowAcceptRejectModal(false);
  };
  const acceptOrder = () => {
    console.log(orderInformation)
    AuthAxios.post(`/order/${orderInformation.orderId}/accept`)
      .then(response => {
        if (response.status === 200) {
          closeModalAction()
        }
      })
      .catch(error => {
        console.log(error);
      })
  }
  const rejectOrder = () => {
    console.log(orderInformation)
    AuthAxios.post(`/order/${orderInformation.orderId}/reject`)
      .then(response => {
        if (response.status === 200) {
          closeModalAction()
        }
      })
      .catch(error => {
        console.log(error);
      })
  }

  return (
    <>
      <Modal
        show={showAcceptRejectModal}
        onHide={() => closeModalAction()}
        className="accept-reject-modal"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <div className="accept-reject-modal-header">
            <div className="accept-reject-modal-header-information">
              <div className="accept-reject-modal-header-order-id">
                Order id: {orderInformation.orderId}
              </div>
              <div className="accept-reject-modal-header-amount-info">
                Total Amount: {orderInformation.orderTotal}
                <span className="accept-reject-modal-header-refund-amount-info">
                  {" "}
                  Refund Amount: {orderInformation.orderTotal}
                </span>
              </div>
            </div>
            <div
              className="accept-reject-modal-header-close"
              onClick={() => closeModalAction()}
            >
              Close
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="accept-reject-body">
            <OrderInformation orderInformation={orderInformation} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="accept-reject-modal-footer">
            <div className="accept-reject-modal-footer-reject-order">
              <button type="button" className="btn btn-reject-order" onClick={()=>{rejectOrder()}}>
                Reject Order
              </button>
            </div>
            <div className="accept-reject-modal-footer-accept-order" onClick={() => acceptOrder()}>
              <button type="button" className="btn btn-accept-order">
                Accept & Pack
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AcceptRejectModal;
