/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/order */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */

import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { ToastConstant } from "utlis/Toast/toastConstant";
import CustomToast from "utlis/Toast/CustomToast";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );

  // toast state
  const [open, setOpen] = useState(false);
  const [toastData, setToastData] = useState({
    time: 0,
    severity: "",
    message: ""
  });

  const loginUser = async formValues => {
    let response = await fetch("https://apis.fadoki.com/merchant/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: formValues.username,
        password: formValues.password
      })
    });
    const data = await response.json();

    if (response.status === 200) {
      setOpen(true);
      setOpen(true);
      setToastData(
        ToastConstant.DYNAMIC_SUCCESS(data.message, 3000, "success")
      );

      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else {
      setOpen(true);
      setOpen(true);
      setToastData(
        ToastConstant.DYNAMIC_FAIL("Invalid Credential", 3000, "error")
      );
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    window.location.reload();
  };

  // const updateRefreshToken = async () => {
  //   let response = await fetch("https://apis.fadoki.com/login/refresh/", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       refresh: authTokens.refresh
  //     })
  //   });
  //   const data = await response.json();
  //   console.log(data, "data");

  //   if (response.status === 200) {
  //     console.log(data.message);
  //     setAuthTokens(data);
  //     setUser(jwt_decode(data.access));
  //     localStorage.setItem("authTokens", JSON.stringify(data));
  //   } else {
  //     logoutUser();
  //   }
  // };

  // useEffect(() => {
  //   let setTimeMin = 1000 * 60 * 4;
  //   let refreshInterval = setInterval(() => {
  //     if (authTokens) {
  //       updateRefreshToken();
  //     }
  //   }, setTimeMin);
  //   return () => clearInterval(refreshInterval);
  // }, [authTokens, loading]);

  const contextData = {
    user: user,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser
  };
  return (
    <>
      <AuthContext.Provider value={contextData}>
        {children}
      </AuthContext.Provider>
      {open && toastData.severity && toastData.time && (
        <CustomToast
          time={toastData.time}
          severity={toastData.severity}
          open={open}
          setOpen={setOpen}
          message={toastData.message}
        />
      )}
    </>
  );
};
